import React, { useState } from "react";
import FullPage from "../../../../components/FullPage";
import BotChat from "../../../../components/BotChat";
import { RitaThumbnailUrl } from "../../../../constants/links";
import { formatPrice, getGreeting } from "../../../../utils/validation";
import { Customer } from "../../../../typings/customer";
import Loader from "../../../../components/Loader";
import PaymentBuilder from "../../../../components/PaymentBuilder";
import SingleEntry from "../../../../components/SingleEntry";

export const ACCOUNT_NO = "1304706410";
export const BANK_NAME = "PROVIDUS BANK PLC";
export const ACCOUNT_NAME = "IFEMIDE ESTATES";

export const ourBankDetails =
  "" +
  "Account Name:   IFEMIDE ESTATES\n" +
  "Account Number: 1304706410\n" +
  "Bank Name:      PROVIDUS BANK PLC\n";

const DEFAULT_AMOUNT = 150000;

const MakeAPayment = ({ customer }: { customer?: Customer }) => {
  const [amount, setAmount] = useState<number>(DEFAULT_AMOUNT);
  const [amountPaid, setAmountPaid] = useState<number>();

  if (!customer) return <Loader />;

  const onPaymentCompleted = (amountPaid: number) => {
    setAmountPaid(amountPaid);
  };

  const reset = () => {
    setAmount(DEFAULT_AMOUNT);
    setAmountPaid(undefined);
  };

  return (
    <FullPage
      title={"MAKE A PAYMENT"}
      back={"/guest"}
      component={
        <div className={"space-y-6 p-6 px-6 bg-white rounded-lg shadow"}>
          {amountPaid ? (
            <div>
              <BotChat
                text={""}
                delays={1000}
                profilePhoto={RitaThumbnailUrl}
                messages={[
                  {
                    content: `Thank you ${customer.firstName}, we have received your payment of ${formatPrice(amountPaid)} and I will update our records accordingly.`,
                    sender: "Rita",
                  },
                ]}
              />
              <div className={"space-y-1 text-center my-9"}>
                <p className={"font-extrabold"}>
                  Want to make another payment?
                </p>
                <button
                  onClick={reset}
                  className={
                    "text-pink-500 underline underline-offset-4 decoration-dashed "
                  }
                >
                  Click here
                </button>
              </div>
            </div>
          ) : (
            <div className={"space-y-6"}>
              <BotChat
                text={""}
                delays={1000}
                profilePhoto={RitaThumbnailUrl}
                messages={[
                  {
                    content: `${getGreeting()} ${customer.firstName}, you can make your payment here and it will be confirmed immediately`,
                    sender: "Rita",
                  },
                ]}
              />
              <div className={"space-y-3"}>
                <p className={"font-semibold"}>How much do you need to pay?</p>
                <SingleEntry
                  disabled={false}
                  type={"number"}
                  onChange={(d) => setAmount(Number(d))}
                  placeholder={"Enter amount here"}
                />
              </div>
              <div className={"space-y-3"}>
                <p className={"font-semibold"}>Pay with</p>
                <PaymentBuilder
                  amount={amount}
                  onPaymentCompleted={onPaymentCompleted}
                />
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default MakeAPayment;
