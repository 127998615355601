import React, { useState } from "react";
import moment, { Moment } from "moment";

interface Props {
  onChange?: (date: Moment) => void;
  checkIn?: Moment;
  checkOut?: Moment;
}

const Calendar = (props: Props) => {
  const { checkIn, checkOut, onChange } = props;

  const [currentMonth, setCurrentMonth] = useState(moment()); // State to track the current month

  // Helper to navigate to previous month
  const handlePrevMonth = () => {
    setCurrentMonth((prev) => prev.clone().subtract(1, "months"));
  };

  // Helper to navigate to next month
  const handleNextMonth = () => {
    setCurrentMonth((prev) => prev.clone().add(1, "months"));
  };

  const startOfMonth = currentMonth.clone().startOf("month"); // First day of the current month
  const totalDaysInMonth = currentMonth.daysInMonth(); // Total days in the current month
  const startDayOfWeek = startOfMonth.day(); // Which day of the week the month starts on
  const daysArray: Moment[] = [];

  // Determine the previous month's last day
  const prevMonth = startOfMonth.clone().subtract(1, "months");
  const lastDayOfPrevMonth = prevMonth.daysInMonth(); // Total days in the previous month

  // Push the last days of the previous month into the array
  for (let i = startDayOfWeek - 1; i >= 0; i--) {
    // Push the last i days of the previous month
    daysArray.push(prevMonth.clone().date(lastDayOfPrevMonth - i));
  }

  // Add actual days of the current month
  for (let day = 1; day <= totalDaysInMonth; day++) {
    daysArray.push(startOfMonth.clone().date(day));
  }

  // Calculate how many days we need to push from the next month to fill the calendar
  const endOfMonth = startOfMonth.clone().endOf("month"); // Last day of the current month
  const endDayOfWeek = endOfMonth.day(); // Which day of the week the month ends on

  // Push days from the next month if the last week is not complete
  const totalDaysToPush = 6 - endDayOfWeek; // Calculate how many more days are needed to complete the week
  const nextMonth = endOfMonth.clone().add(1, "months"); // Move to the next month

  for (let i = 1; i <= totalDaysToPush; i++) {
    daysArray.push(nextMonth.clone().date(i)); // Push the first i days of the next month
  }

  return (
    <div
      className={
        "rounded-xl shadow-xl border p-3 flex-wrap text-sm bg-white py-6"
      }
    >
      {/* Month Navigation */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handlePrevMonth}
          className="text-gray-600 hover:text-black"
        >
          <i className="bx bx-chevron-left text-xl"></i>
        </button>
        <p className=" font-bold">
          {currentMonth.format("MMMM YYYY")} {/* Displays the month and year */}
        </p>
        <button
          onClick={handleNextMonth}
          className="text-gray-600 hover:text-black"
        >
          <i className="bx bx-chevron-right text-xl"></i>
        </button>
      </div>

      {/* Calendar Header: Days of the Week */}
      <div className={"grid grid-cols-7 gap-4 items-center mb-3"}>
        {["S", "M", "T", "W", "T", "F", "S"].map((day, index) => (
          <p
            key={index}
            className={
              "flex items-center justify-center font-semibold my-2 w-6 h-6"
            }
          >
            {day}
          </p>
        ))}
      </div>

      {/* Calendar Days: Generate the grid of days */}
      <div className={"grid grid-cols-7 gap-4 items-center justify-center"}>
        {daysArray.map((day, index) => {
          // Determine the class based on check-in and check-out dates
          const isCheckIn = checkIn && day && day.isSame(checkIn, "day");
          const isCheckOut = checkOut && day && day.isSame(checkOut, "day");
          const isInRange =
            checkIn &&
            checkOut &&
            day &&
            day.isBetween(checkIn, checkOut, "day", "[]");
          const sameMonth = day.month() === currentMonth.month();

          let bgColorClass: string;
          if (isCheckIn) {
            bgColorClass = "bg-red-500 text-white font-bold"; // Red for Check-In
          } else if (isCheckOut) {
            bgColorClass = "bg-red-500 text-white font-bold"; // Red for Check-Out
          } else if (isInRange) {
            bgColorClass = "bg-red-200"; // Light red for dates in between
          } else if (!sameMonth) {
            bgColorClass = "bg-gray-100 text-gray-300";
          } else {
            bgColorClass = "bg-gray-100 hover:bg-blue-200 cursor-pointer"; // Default style
          }

          return (
            <p
              key={index}
              onClick={() => (day && onChange ? onChange(day) : {})}
              className={`flex w-6 h-6 my-2 items-center justify-center rounded-full ${bgColorClass}`}
            >
              {day.date() ? day.date() : ""}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
