import React from "react";
import { CATEGORIES } from "../config/FoodConfig";
import { MenuType } from "../../../../typings/menu";

interface Interface {
  onClick: (type: MenuType) => void;
  selected?: MenuType;
}

const CategoryPills = ({ selected, onClick }: Interface) => {
  return (
    <div
      id={"selection-pills"}
      className={`flex flex-row items-center overflow-x-scroll scrollbar-hidden space-x-1 relative`}
    >
      {Object.values(CATEGORIES)
        .filter((category) => category.title)
        .map((category) => (
          <button
            onClick={() => onClick(category.type)}
            key={category.title}
            className={`
              p-1 flex flex-row items-center justify-center rounded-full text-sm space-x-2 border-2 whitespace-nowrap
              ${category.type === selected ? "bg-pink-500 text-white border-white" : "bg-white border-transparent"}
            `}
          >
            <div className={"h-8 aspect-square"}>
              <img
                alt={category.title}
                className={
                  "w-full h-full rounded-full object-cover border border-white"
                }
                src={category.thumbnailUrl}
              />
            </div>
            <p className={"pr-3"}>{category.title}</p>
          </button>
        ))}
    </div>
  );
};

export default CategoryPills;
