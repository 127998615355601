import React from "react";

const SingleEntry = ({
  disabled,
  onChange = () => {},
  onApply = () => {},
  placeholder = "Coupon code here",
  type = "text",
}: {
  disabled: boolean;
  onChange?: (d: string) => void;
  onApply?: () => void;
  placeholder?: string;
  type?: string;
}) => {
  return (
    <div
      className={
        "flex flex-row justify-between border p-2 rounded-xl space-x-3"
      }
    >
      <input
        disabled={disabled}
        className={"w-full focus:outline-none "}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        type={type}
      />
      <button
        className={`text-pink-500 font-semibold ${disabled ? "opacity-50" : ""}`}
        disabled={disabled}
        onClick={onApply}
      >
        Apply
      </button>
    </div>
  );
};

export default SingleEntry;
