import React, { useEffect, useState } from "react";
import { Booking, Invoice, PaymentInfo } from "../../../../typings/reservation";
import { Customer } from "../../../../typings/customer";
import { formatPrice, getValue } from "../../../../utils/validation";

const Totals = ({
  invoice,
  paymentInfo,
  customer,
  bookings,
  onPaymentInfoChanged,
}: {
  invoice: Invoice;
  paymentInfo: PaymentInfo;
  customer: Customer;
  bookings: Booking[];
  onPaymentInfoChanged: (
    paymentInfo: Pick<PaymentInfo, "paid" | "discount">,
  ) => any;
}) => {
  const [currentPaymentInfo, setCurrentPaymentInfo] = useState<
    Pick<PaymentInfo, "paid" | "discount">
  >({ paid: invoice.paid, discount: invoice.discount });

  useEffect(() => {
    setCurrentPaymentInfo({ discount: invoice.discount, paid: invoice.paid });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice.reservationId]);

  function handlePaidChanged(paid: number) {
    const _new = { ...paymentInfo, ...currentPaymentInfo, paid };
    setCurrentPaymentInfo(_new);
    notify(_new);
  }

  function handleDiscountChanged(discount: number) {
    const _new = { ...paymentInfo, ...currentPaymentInfo, discount };
    setCurrentPaymentInfo(_new);
    notify(_new);
  }

  function notify(newPaymentInfo: PaymentInfo) {
    onPaymentInfoChanged({
      ...paymentInfo,
      ...newPaymentInfo,
    });
  }

  const renderRow = (
    title: string,
    amount: number,
    editable: boolean = false,
    valueChangeCallback: (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => void = () => {},
  ) => {
    return (
      <div className={"flex flex-row justify-between"}>
        <div className={"w/1/2 text-start"}>{title}</div>
        {editable ? (
          <>
            <input
              onChange={valueChangeCallback}
              value={formatPrice(amount)}
              className={"font-extrabold text-end bg-transparent"}
            />
          </>
        ) : (
          <div className={"w/1/2 text-start"}>{formatPrice(amount)}</div>
        )}
      </div>
    );
  };

  function changeAmount(
    e: React.ChangeEvent<HTMLInputElement>,
    callback: (d: number) => any,
  ) {
    e.preventDefault();
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      callback(parsedValue);
    }
  }

  function onPaidChanged(e: React.ChangeEvent<HTMLInputElement>) {
    changeAmount(e, handlePaidChanged);
  }

  function onDiscountChanged(e: React.ChangeEvent<HTMLInputElement>) {
    changeAmount(e, handleDiscountChanged);
  }

  const total = getValue(invoice.entries);

  return (
    <div className={"space-y-3"}>
      <div className={"bg-gray-100 p-3 rounded-xl  space-y-1"}>
        {renderRow("Sub-total", total)}
        {renderRow(
          "Discount",
          currentPaymentInfo.discount,
          true,
          onDiscountChanged,
        )}
        <hr className={"border-gray-400"} />
        {renderRow("Total", total - currentPaymentInfo.discount)}
        {renderRow("Guest Paid", currentPaymentInfo.paid, true, onPaidChanged)}
        <hr className={"border-gray-400"} />
        {renderRow(
          "Balance",
          total - currentPaymentInfo.discount - currentPaymentInfo.paid,
        )}
      </div>
    </div>
  );
};

export default Totals;
