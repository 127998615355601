import { Invoice } from "../../../typings/reservation";
import React from "react";
import { formatPrice, getInvoiceTotals } from "../../../utils/validation";
import {
  AirbnbLink,
  GoogleLocationLink,
  GoogleReviewLink,
} from "../../../constants/links";
import { ourBankDetails } from "../lounge/pages/MakeAPayment";
import { SpanLink } from "../../faq/FaqHome";
import {
  Body,
  Column,
  Container,
  Row,
  Section,
  Text,
} from "@react-email/components";

export const InvoiceView = ({
  invoice,
  checkOutDate,
  checkInDate,
  customerName,
}: {
  invoice: Invoice;
  customerName: string;
  checkInDate?: string;
  checkOutDate?: string;
}) => {
  const renderEntry = (
    index: number,
    item: string,
    rate: string,
    qty: string,
    cost: string,
    style: string = "text-end",
  ) => {
    return (
      <Column key={index} className={"flex flex-row w-full"}>
        <Text className={"w-7/12 " + style}>{item}</Text>
        <Text className={"w-1/12 " + style}>{qty}</Text>
        <Text className={"w-3/12 " + style}>{rate}</Text>
        <Text className={"w-3/12 " + style}>{cost}</Text>
      </Column>
    );
  };

  function renderTotals(text: string, value: number) {
    return (
      <Column className={"flex flex-row"} key={text}>
        <Text
          className={"text-start w-1/2 w-"}
          style={{ padding: 0, margin: 0 }}
        >
          {text}
        </Text>
        <Text
          className={"text-end w-1/2 font-extrabold"}
          style={{ padding: 0, margin: 0 }}
        >
          {formatPrice(value)}
        </Text>
      </Column>
    );
  }

  const invoiceTotals = getInvoiceTotals(invoice);

  const summary = [
    { text: "Total", value: invoiceTotals.total },
    { text: "Discount", value: invoiceTotals.discount * -1 },
    { text: "Paid", value: invoiceTotals.paid },
    {
      text: invoiceTotals.balance < 0 ? "Your refund" : "Balance due",
      value: Math.abs(invoiceTotals.balance),
    },
  ];

  return (
    <>
      <Section>
        <Text>{`Dear ${customerName}`}</Text>
        <Text className={"font-extrabold text-lg"}>
          Your Post-Checkout Invoice #{invoice.reservationId}
        </Text>
        <Text>
          I hope you had a pleasant and enjoyable stay at Ifemide Estates,
          Alagbaka, Akure during your visit from{" "}
          <span className={"font-bold underline"}>{checkInDate}</span> to{" "}
          <span className={"font-bold underline"}>{checkOutDate}</span>.
        </Text>
        <Text>
          We would like to extend our heartfelt gratitude for choosing our
          accommodation for your stay.
        </Text>
        <Text>
          As per our records, I have compiled the following invoice for the
          additional services and charges you've requested during your stay:{" "}
        </Text>
      </Section>
      <Section>
        <Body className={"px-3 bg-transparent mb-0 pb-0"}>
          <Row>
            {renderEntry(
              0,
              "Item",
              "Rate",
              "Qty",
              "Cost",
              "mb-0 font-bold text-start",
            )}
          </Row>{" "}
        </Body>
      </Section>
      <Section>
        <Body className={"bg-gray-100 rounded-lg p-3"}>
          <Row>
            {invoice.entries.map((entry, index) =>
              renderEntry(
                index,
                entry.title,
                formatPrice(entry.rate, true),
                String(entry.quantity),
                formatPrice(entry.rate * entry.quantity, true),
                "text-start",
              ),
            )}
          </Row>
        </Body>
        <Body className={"px-3 pr-4 bg-transparent"}>
          <Row>
            {summary.map((item) => renderTotals(item.text, item.value))}
          </Row>
        </Body>
      </Section>
      <Section>
        <Container className={"my-3 space-y-3"}>
          <Text>
            I kindly request that you review the invoice and let me know if you
            have any questions or concerns regarding the charges.
          </Text>
          <Text>
            If you need to settle an outstanding payment, you can easily do so
            by using the following information:
          </Text>
          <Text className={"whitespace-pre-line bg-gray-100 p-3 rounded-xl"}>
            {ourBankDetails}
          </Text>
          <Text>
            If you've already made your payment or have any specific payment
            instructions, please share them with me. I'll make sure our records
            are updated accordingly.
          </Text>
          <Text>
            If you're anticipating a refund, as indicated on your bill, kindly
            provide me with your account details on your{" "}
            {SpanLink({
              href: "www.ifemide.com/guest",
              title: "Online Guest Lounge",
            })}{" "}
            or simply give me a call.
          </Text>
          <Body
            className={
              "whitespace-pre-line bg-gray-100 p-3 rounded-xl space-y-3"
            }
          >
            <Text>
              I'd greatly appreciate your feedback. You can easily share your
              experience on {SpanLink({ href: AirbnbLink, title: "AirBnb" })} or{" "}
              {SpanLink({ href: GoogleReviewLink, title: "Google" })} or simply
              searching for{" "}
              {SpanLink({
                title: "Ifemide Estates",
                href: GoogleLocationLink,
              })}{" "}
              on Google.
            </Text>
            <Text>Your feedback holds significant value to us!</Text>
          </Body>
          <Text>
            I am sincerely grateful for choosing to stay with us and look
            forward to the opportunity to host you again in the future. Should
            you have any queries or need further assistance, please don't
            hesitate to contact me.
          </Text>
        </Container>
      </Section>
    </>
  );
};
