import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";

type Message = {
  content: string;
  sender?: string;
  timestamp?: Moment;
};

type BotMessagesProps = {
  profilePhoto: string;
  messages: Message[];
  text?: string;
  timestamp?: boolean;
  delays?: number;
};

export default function BotChat({
  delays = 5000,
  text = "",
  profilePhoto,
  messages: botMessages = [],
  timestamp = true,
}: BotMessagesProps) {
  const [messages, setMessages] = useState<Message[]>(botMessages);
  const [isTyping, setIsTyping] = useState(false);
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    setMessages(botMessages);
  }, [botMessages]);

  useEffect(() => {
    if (currentMessageIndex >= botMessages.length) return;

    // Show typing loader
    setIsTyping(true);

    const timer = setTimeout(() => {
      setIsTyping(false);

      setCurrentMessageIndex((prevIndex) => prevIndex + 1);
    }, delays);

    // Cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timer);
  }, [currentMessageIndex, botMessages, delays]);

  return (
    <div className={`text-sm ${text}`}>
      <ul>
        {messages.map((message, index) => (
          <li key={index} className="message-item my-1">
            <div style={{ display: "flex", alignItems: "start" }}>
              <img
                className={"filter brightness-200 contrast-500 shadow-xl"}
                src={profilePhoto}
                alt="Profile"
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
              <div>
                <div
                  className={
                    "bg-white px6 p-3 rounded-lg shadow-xl whitespace-pre-line "
                  }
                >
                  <div dangerouslySetInnerHTML={{ __html: message.content }} />
                </div>
                {timestamp && (
                  <p className={"text-end text-gray-600 text-xs italic my-1"}>
                    {moment(message.timestamp).fromNow()}
                  </p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>

      {/* Display the typing loader */}
      {isTyping && (
        <div
          style={{ display: "flex", alignItems: "center" }}
          className={"my-6"}
        >
          <img
            className={"filter brightness-200 contrast-500 shadow-xl"}
            src={profilePhoto}
            alt="Profile"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
          <div className="flex space-x-1 text-pink-500">
            <div className="h-2 w-2 bg-pink-500 rounded-full animate-bounce"></div>
            <div
              className="h-2 w-2 bg-pink-500 rounded-full animate-bounce"
              style={{ animationDelay: "0.2s" }}
            ></div>
            <div
              className="h-2 w-2 bg-pink-500 rounded-full animate-bounce"
              style={{ animationDelay: "0.4s" }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
