import React, { useCallback, useEffect, useState } from "react";
import { Customer } from "../../typings/customer";
import FormEntry from "../../components/FormEntry";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import { getReservation } from "../../api/reservation";
import { AxiosError } from "axios";
import { getCustomer } from "../../api/customers";
import { Reservation } from "../../typings/reservation";
import Logo from "../../components/Logo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SpanLink } from "../faq/FaqHome";
import { get } from "../../api/database";

const authKey = "e35db649-590d-4b42-972d-12f3a19afaab";

const GuestAuthenticator = (props: any) => {
  const [params] = useSearchParams({ reservationId: "" });

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginIn, setLogin] = useState(false);
  const [error, setError] = useState("");

  const [reservationId, setReservationId] = useState(
    params.get("reservationId") || "",
  );
  const [lastName, setLastName] = useState("");

  const navigate = useNavigate();

  const [customer, setCustomer] = useState<Customer | undefined>();

  const [reservation, setReservation] = useState<Reservation | undefined>(
    undefined,
  );

  const handleLogin = useCallback(async () => {
    setLogin(true);
    setError("");
    if (reservationId && lastName) {
      await getReservation(Number(reservationId))
        .then(async (reservationData) => {
          if (reservationData.status === "CLOSED") {
            setError("This reservation has been closed. [800]");
          } else {
            await getCustomer(reservationData.customerUuid)
              .then((customerData) => {
                if (
                  lastName.trim().toLowerCase() ===
                  customerData.lastName.trim().toLowerCase()
                ) {
                  setCustomer(customerData);
                  setReservation(reservationData);
                } else
                  setError(
                    "Failed to get this reservation, please try again later. [801]",
                  );
              })
              .catch(() => {
                setError(
                  "Failed to get this reservation, please try again later [802]",
                );
              });
          }
        })
        .catch(({ status }: AxiosError) => {
          if (status === 404)
            setError("We do not have this reservation in our system. [803]");
          else
            setError(
              "Failed to get this reservation, please try again later. [804]",
            );
        });
    } else setError("Please enter a reservation id and last name");
    setLogin(false);
  }, [lastName, reservationId]);

  function handleLogOut() {
    localStorage.removeItem(authKey);
    setIsAuthenticated(false);
    navigate("/guest");
  }

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(true);
      const authData = localStorage.getItem(authKey);
      if (authData) {
        try {
          const { customer, reservation } = JSON.parse(authData) as {
            timestamp?: number;
            customer?: Customer;
            reservation?: Reservation;
          };
          if (reservation && customer) {
            setCustomer(customer);
            setReservation(reservation);
            // try and get
            get<Customer>("customers/" + customer.uuid)
              .then((c) => {
                get<Reservation>("reservations/" + reservation.id)
                  .then((r) => {
                    setCustomer(c);
                    setReservation(r);
                  })
                  .catch(handleLogOut);
              })
              .catch(handleLogOut);
          }
        } catch (e) {
          setLoading(false);
        }
      } else setLoading(false);
    }
    // eslint-disable-next-line
  }, [handleLogin, isAuthenticated]);

  useEffect(() => {
    if (reservation && customer) {
      const authData = {
        timestamp: Date.now(),
        reservation,
        customer,
      };

      localStorage.setItem(authKey, JSON.stringify(authData));
      setIsAuthenticated(true);
      setLoading(false);
    }
  }, [customer, reservation]);

  if (loading)
    return (
      <div
        className={"h-full w-full flex flex-row items-center justify-center"}
      >
        <Loader />
      </div>
    );

  if (!isAuthenticated)
    return (
      <div className="flex h-screen justify-center flex-col w-full px-9">
        <div className={"bg-white p-6 px-9 py-12 rounded-xl shadow-2xl"}>
          <Logo size={8} text={1} />
          <p className={"font-extrabold text-pink-500 my-3"}>
            IFEMIDE GUEST LOUNGE
          </p>
          <div>
            <FormEntry
              placeholder="4567"
              onChange={setReservationId}
              title={"Reservation ID"}
              value={reservationId}
              type={"number"}
            />
            <FormEntry
              placeholder="Bucknor"
              onChange={setLastName}
              title={"Last name"}
              value={lastName}
              type={"text"}
            />
            {error && <p className="text-pink-500 py-3">{error}</p>}
            {loginIn && <Loader size={6} />}
            <div className={"flex flex-row justify-end mt-6"}>
              <Button
                disabled={loginIn}
                onClick={handleLogin}
                type="primary"
                text="Login"
              />
            </div>
            <p className={"text-pink-500 my-6"}>
              Have a host account? Login{" "}
              <SpanLink title={"here"} href={"/host"} target={""} />
            </p>
          </div>
        </div>
      </div>
    );

  return (
    <>
      {React.cloneElement(props.children, {
        reservation,
        customer,
        handleLogOut,
        setCustomer,
      })}
    </>
  );
};

export default GuestAuthenticator;
