import toast, { ToastOptions } from "react-hot-toast";
import { AxiosError } from "axios";

export const toastError = (message: string, opts: ToastOptions = {}) =>
  toast.error(message, opts);
export const toastAxiosError = (error: AxiosError, opts: ToastOptions = {}) =>
  toast.error(
    (error.response?.data as { message: string })?.message ||
      error.message ||
      "Unexpected error occurred",
    opts,
  );
export const toastSuccess = (message: string, opts: ToastOptions = {}) =>
  toast.success(message, opts);
export const toastInfo = (message: string, opts: ToastOptions = {}) =>
  toast.success(message, opts);
