import React, { useCallback, useEffect, useState } from "react";
import FullPage from "../../../components/FullPage";
import {
  months,
  Transaction,
  transactionCategoryArray,
} from "../../../typings/reservation";
import { formatPrice, totTitleCase } from "../../../utils/validation";
import { get, patch } from "../../../api/database";
import ListInput from "../../../components/ListInput";
import { AxiosError } from "axios";
import moment from "moment";
import PillSelector from "../../../components/PillSelector";
import Loader from "../../../components/Loader";

const transactionCategories = transactionCategoryArray.map((category) => ({
  title: totTitleCase(category),
  id: category,
}));

const FILTER_OPTIONS = {
  NO_CATEGORY: "NO_CATEGORY",
  DEBITS: "DEBITS",
  CREDITS: "CREDITS",
  TODAY: "TODAY",
  YESTERDAY: "YESTERDAY",
};

const SingleTransaction = ({
  transaction,
  month,
  year,
}: {
  transaction: Transaction;
  month: number;
  year: number;
}) => {
  const [, setError] = useState<AxiosError | undefined>();

  const handleCategoryChange = (id: string) => {
    const category = transactionCategoryArray.find((t) => t === id);
    if (category) {
      patch<Transaction>(`transactions?month=${month}&year=${year}`, {
        ...transaction,
        category,
      }).catch(setError);
    }
  };

  return (
    <div className="p-3 border-b">
      <div className="flex justify-between items-center">
        <ListInput
          selectedId={transaction.category}
          keySelector="id"
          titleSelector="title"
          onChange={handleCategoryChange}
          list={transactionCategories}
        />
        <div
          className={
            transaction.type === "DEBIT" ? "text-pink-500" : "text-green-500"
          }
        >
          {formatPrice(
            transaction.amount * (transaction.type === "DEBIT" ? -1 : 1),
          )}
        </div>
      </div>
      <p className="line-clamp-2 text-ellipsis">{transaction.narration}</p>
      <div className="text-gray-500 flex justify-between">
        <p>{`REF: ${transaction.reference}`}</p>
        <p>{`BAL: ${formatPrice(transaction.ledgerBalance)}`}</p>
      </div>
      <p className="text-gray-500">
        {moment(transaction.dateTime).format("dddd, DD @ hh:mm A")}
      </p>
    </div>
  );
};

const DailyTransactions = () => {
  const [pills, setPills] = useState<string[]>([FILTER_OPTIONS.TODAY]);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [filteredItems, setFilteredItems] = useState<Transaction[]>([]);

  const currentDate = new Date();
  const [month, setMonth] = useState(currentDate.getMonth() + 1);
  const year = currentDate.getFullYear();

  const calculateTotals = (items: Transaction[]) => {
    return items.reduce(
      (acc, transaction) => {
        if (transaction.type === "DEBIT") {
          acc.debits += transaction.amount;
        } else if (transaction.type === "CREDIT") {
          acc.credits += transaction.amount;
        }
        return acc;
      },
      { debits: 0, credits: 0 },
    );
  };

  const { debits, credits } = calculateTotals(transactions);
  const { debits: filteredDebits, credits: filteredCredits } =
    calculateTotals(filteredItems);

  const handleMonthChange = (id: string) => {
    setMonth(Number(id));
  };

  const filterTransactions = useCallback(() => {
    if (pills.length === 0) {
      setFilteredItems(transactions);
      return;
    }

    const currentDate = new Date();
    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    const filtered = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.dateTime);
      return (
        (pills.includes(FILTER_OPTIONS.NO_CATEGORY)
          ? !transaction.category
          : true) &&
        (pills.includes(FILTER_OPTIONS.TODAY)
          ? transactionDate.toDateString() === currentDate.toDateString()
          : true) &&
        (pills.includes(FILTER_OPTIONS.YESTERDAY)
          ? transactionDate.toDateString() === yesterday.toDateString()
          : true) &&
        (pills.includes(FILTER_OPTIONS.DEBITS)
          ? transaction.type === "DEBIT"
          : true) &&
        (pills.includes(FILTER_OPTIONS.CREDITS)
          ? transaction.type === "CREDIT"
          : true)
      );
    });

    setFilteredItems(filtered);
  }, [transactions, pills]);

  useEffect(() => {
    setLoading(true);
    get<Transaction[]>(`transactions?month=${month}&year=${year}`)
      .then(setTransactions)
      .catch((error) => {
        console.error("Error fetching transactions:", error);
        setTransactions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [month, year]);

  useEffect(() => {
    filterTransactions();
  }, [transactions, pills, filterTransactions]);

  return (
    <FullPage
      back="/host"
      title="DAILY TRANSACTIONS"
      component={
        <div className="space-y-3">
          <div className="flex justify-end my-6">
            <div className="w-fit rounded-2xl bg-white">
              <ListInput
                keySelector="id"
                titleSelector="title"
                onChange={handleMonthChange}
                list={months}
                selectedId={String(month)}
              />
            </div>
          </div>
          <div className="flex justify-end items-center space-x-3">
            <div className="w-fit bg-white rounded-xl p-3 text-center py-6">
              <p>DEBITS</p>
              <p className="text-xl font-extrabold text-pink-500">
                -{formatPrice(debits)}
              </p>
            </div>
            <div className="w-fit bg-white rounded-xl p-3 text-center py-6">
              <p>CREDITS</p>
              <p className="text-lg font-extrabold text-green-500">
                +{formatPrice(credits)}
              </p>
            </div>
          </div>
          <div className="space-y-3 pt-9">
            <div className="flex justify-end">
              <PillSelector
                onChange={setPills}
                selectedIds={pills}
                items={[
                  { id: FILTER_OPTIONS.NO_CATEGORY, title: "No category" },
                  { id: FILTER_OPTIONS.TODAY, title: "Today" },
                  { id: FILTER_OPTIONS.YESTERDAY, title: "Yesterday" },
                  { id: FILTER_OPTIONS.CREDITS, title: "Credits" },
                  { id: FILTER_OPTIONS.DEBITS, title: "Debits" },
                ]}
              />
            </div>
            <div className="flex justify-between">
              <p className="text-xs">TRANSACTIONS</p>
              {pills.length > 0 && (
                <div className="flex space-x-3">
                  <p className="text-pink-500">
                    -{formatPrice(filteredDebits)}
                  </p>
                  <p className="text-green-500">
                    +{formatPrice(filteredCredits)}
                  </p>
                </div>
              )}
            </div>
            {loading ? (
              <Loader />
            ) : (
              <div className="bg-white rounded-xl shadow p-3">
                {filteredItems.length > 0 ? (
                  filteredItems.map((transaction) => (
                    <SingleTransaction
                      transaction={transaction}
                      key={transaction.id}
                      month={month}
                      year={year}
                    />
                  ))
                ) : (
                  <p className="p-3">No transactions to show at the moment.</p>
                )}
              </div>
            )}
          </div>
        </div>
      }
    />
  );
};

export default DailyTransactions;
