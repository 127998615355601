import React, { useState } from "react";
import FormEntry from "../../../../components/FormEntry";
import Button from "../../../../components/Button";
import { InvoiceEntry } from "../../../../typings/reservation";
import { v4 } from "uuid";

interface Props {
  onCancel?: () => void;
  onSave?: (d: InvoiceEntry) => void;
  onChange?: (d: InvoiceEntry) => void;
  entry?: InvoiceEntry & { index?: number };
}

interface Error {
  name?: string;
  rate?: string;
  qty?: string;
  unit?: string;
}

const AddCustomService = ({
  entry,
  onCancel = () => {},
  onSave = () => {},
}: Props) => {
  const [uuid] = useState(entry?.uuid || v4());
  const [name, setName] = useState(entry?.title || "");
  const [rate, setRate] = useState(entry?.rate || 0);
  const [qty, setQty] = useState(entry?.quantity || 0);
  const [unit, setUnit] = useState(entry?.unit || "");

  const [errors, setErrors] = useState<Error>({});

  function build(): InvoiceEntry {
    return {
      ...entry,
      uuid: uuid,
      title: name,
      description: name,
      rate,
      quantity: qty,
      cost: rate * qty,
      unit,
      service: "OTHER",
    };
  }

  function validate(): Error {
    const data: Error = {};
    if (name.trim() === "") data.name = "Invalid name";
    if (unit.trim() === "") data.unit = "Invalid unit";
    if (rate <= 0) data.rate = "Invalid rate";
    if (qty <= 0) data.qty = "Invalid qty";

    return data;
  }

  function handleOnSave() {
    const error = validate();
    setErrors(error);
    if (Object.keys(error).length < 1) {
      onSave(build());
    }
  }

  return (
    <div
      className={"w-full h-auto p-6 rounded-lg shadow-xl bg-white space-y-3"}
    >
      <p className={"font-bold text-lg"}>Add custom service</p>
      <hr />
      <div>
        <FormEntry
          title={"Service Name"}
          placeholder={"Product of service name"}
          value={name}
          type={"text"}
          onChange={setName}
          error={errors?.name}
        />
        <div className={"grid grid-cols-3 gap-4"}>
          <FormEntry
            title={"Rate"}
            placeholder={"Rate"}
            value={String(rate)}
            type={"number"}
            onChange={(d) => setRate(Number(d))}
            error={errors?.rate}
          />
          <FormEntry
            title={"Quantity"}
            placeholder={"Qty"}
            value={String(qty)}
            type={"number"}
            onChange={(d) => setQty(Number(d))}
            error={errors?.qty}
          />
          <FormEntry
            title={"Unit"}
            placeholder={"Unit"}
            value={unit}
            type={"text"}
            onChange={setUnit}
            error={errors?.unit}
          />
        </div>
      </div>
      <hr />
      <div className={"flex flex-row justify-between items-center"}>
        <Button onClick={onCancel} type={"secondary"} text={"Cancel"} />
        <Button onClick={handleOnSave} type={"primary"} text={"Save"} />
      </div>
    </div>
  );
};

export default AddCustomService;
