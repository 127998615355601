import React, { useCallback, useEffect, useState } from "react";
import Loader from "./Loader";
import { Drink } from "../typings/menu";
import { AxiosError } from "axios";
import { get, patch, post, remove } from "../api/database";
import { formatPrice } from "../utils/validation";
import { v4 } from "uuid";
import DoubleClickButton from "./DoubleClickButton";
import Search from "./Search";

export const DrinkItem = ({
  drink,
  editable = false,
  modified = () => {},
}: {
  drink: Drink;
  editable?: boolean;
  modified?: (state: string) => void;
}) => {
  const [working, setWorking] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(false);

  const [title, setTitle] = useState(drink.title);
  const [currentStock, setCurrentStock] = useState(drink.currentStock);
  const [rate, setRate] = useState(drink.rate);
  const [thumbnailUrl, setThumbnailUrl] = useState(drink.thumbnailUrl);

  const [error, setError] = useState<AxiosError | undefined>(undefined);

  async function removeItem() {
    if (!drink.uuid) {
      modified(v4());
      return;
    }

    if (currentStock > 0) {
      window.alert("Please clear the stock before deleting the item.");
      return;
    }

    setWorking(true);
    setError(undefined);
    await remove<Drink>("drinks/" + drink.uuid)
      .then(() => modified(v4()))
      .catch(setError);
    setWorking(false);
  }

  async function edit() {
    if (!title || title === "Unnamed") {
      window.alert("Invalid drink name");
      return;
    }
    if (rate < 1) {
      window.alert(
        `The rate of '${title || "the drink"}' should be at least 1 naira`,
      );
      return;
    }

    setWorking(true);
    setError(undefined);

    const update: Drink = {
      ...drink,
      title,
      currentStock,
      rate,
      thumbnailUrl,
    };

    if (!drink.uuid.trim()) {
      await post<Omit<Drink, "uuid">, Drink>("drinks", update)
        .then((d) => modified(d.uuid))
        .catch(setError);
    } else {
      await patch<Drink>("drinks", update).catch(setError);
    }

    setWorking(false);
  }

  function onTitleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setTitle(e.target.value);
  }

  function onStockChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue) && Number.isInteger(parsedValue)) {
      setCurrentStock(parsedValue);
    }
  }

  function onRateChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      setRate(parsedValue);
    }
  }

  function onThumbnailUrlChanged(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setThumbnailUrl(e.target.value);
  }

  function toggleThumbnail() {
    setShowThumbnail(!showThumbnail);
  }

  return (
    <div className={`w-full border-b my-1 ${working ? "opacity-30" : ""}`}>
      {error && (
        <p className={"text-red-500 text-xs mx-3"}>
          <span className={"mr-3"}>
            <i className={"bx bxs-comment-error"}></i>
          </span>
          {error.message || "An error occurred."}
        </p>
      )}
      <div
        className={`flex flex-row justify-between items-center text-sm p-3 ${editable ? "px-1 text-xs" : ""}`}
      >
        <div className={"flex flex-row space-x-3 items-center min-w-0"}>
          {editable && (
            <DoubleClickButton
              disabled={working}
              onRelease={removeItem}
              component={<i className={"bx bx-trash text-pink-500"}></i>}
            />
          )}
          <button disabled={working || !editable} onClick={toggleThumbnail}>
            <img
              alt={"drink"}
              className={"h-6 w-6 rounded-full overflow-hidden object-contain"}
              src={
                drink.thumbnailUrl ||
                "https://cdn-icons-png.freepik.com/256/2390/2390955.png?semt=ais_hybrid"
              }
            />
          </button>
          {editable ? (
            <input
              onChange={onTitleChange}
              disabled={working}
              className={"text-start min-w-0 max-w-[800px]"}
              style={{}} // Limit the width of the input
              defaultValue={title}
            />
          ) : (
            <p className={"text-start min-w-0 max-w-[800px]"}>{title}</p>
          )}
        </div>
        <div className={"space-x-3 flex flex-row items-center min-w-0"}>
          {editable ? (
            <input
              disabled={working}
              onChange={onRateChange}
              className={"text-end truncate min-w-0"}
              style={{ maxWidth: "50px" }}
              value={formatPrice(rate)}
            />
          ) : (
            <p className={"text-start min-w-0 max-w-[800px]"}>
              {formatPrice(rate)}
            </p>
          )}
          {editable && (
            <div className={"space-x-1 flex-row flex"}>
              <input
                onChange={onStockChange}
                disabled={working}
                className={`rounded truncate min-w-0 text-center text-white py-1 ${currentStock === 0 ? "bg-gray-400" : "bg-pink-400"}`}
                style={{ maxWidth: "30px" }}
                value={currentStock}
                type={"number"}
              />
              <button disabled={working} onClick={edit}>
                <i className={"bx bx-check"}></i>
              </button>
            </div>
          )}
        </div>
      </div>
      {showThumbnail && (
        <input
          value={thumbnailUrl}
          onChange={onThumbnailUrlChanged}
          className={
            "text-xs p-1 mb-2 w-full mx-auto border rounded text-gray-600"
          }
          placeholder={`https://www.product-image-link-here`}
        />
      )}
    </div>
  );
};

const Drinks = ({
  editor = false,
}: {
  editor?: boolean;
  action?: (drink: Drink, type: "delete") => boolean;
}) => {
  const [drinks, setDrinks] = useState<Drink[]>([]);
  const [error, setError] = useState<AxiosError | undefined>(undefined);
  const [loadingDrinks, setLoadingDrinks] = useState(true);

  const [search, setSearch] = useState("");
  const [filterItems, setFilterItems] = useState<Drink[]>(drinks);
  const [newDto, setNewDto] = useState<Drink | undefined>(undefined);

  const [listVersion, setListVersion] = useState("");

  const handleSearchChange = (data: string) => {
    setSearch(data);
  };

  function modified(uuid: string) {
    setNewDto(undefined);
    setListVersion(uuid);
  }

  function addNew() {
    if (newDto) return;

    setNewDto({
      title: "Unnamed",
      currentStock: 0,
      rate: 0,
      thumbnailUrl: "",
      uuid: "",
      createdAt: Date.now().toString(),
      updatedAt: Date.now().toString(),
      description: "No description",
    });
  }

  const searchList = useCallback(
    (value: string) => {
      if (value.trim() === "") {
        setFilterItems(drinks);
      } else {
        const filtered = drinks.filter((item: Drink) =>
          item.title.toLowerCase().includes(value.toLowerCase()),
        );
        setFilterItems(filtered);
      }
    },
    [drinks],
  );

  useEffect(() => {
    setLoadingDrinks(true);
    get<Drink[]>("drinks")
      .then((d) => {
        setDrinks(d);
        setLoadingDrinks(false);
      })
      .catch((e) => {
        setError(e);
        setLoadingDrinks(false);
      });
  }, [listVersion]);

  useEffect(() => {
    searchList(search);
  }, [drinks, search, searchList]);

  const value = drinks.reduce(
    (total, drink) => total + drink.currentStock * drink.rate,
    0,
  );

  return (
    <div className={"text-sm space-y-3"}>
      {error && <p className={"my-6"}>{error.message}</p>}
      <div className={"flex-row flex items-center justify-end space-x-3"}>
        <Search onChange={handleSearchChange} />
        {editor && (
          <button onClick={addNew}>
            <i className={"bx bx-plus text-xl"}></i>
          </button>
        )}
      </div>
      {loadingDrinks && <Loader />}

      <p className={"text-xs"}>DRINKS</p>
      <div className={"flex flex-row justify-between items-center text-xs"}>
        {editor && (
          <>
            <p className={"text-gray-600"}>
              To delete an item, double tap the trash button
            </p>
            <p>
              Value:{" "}
              <span className={"font-extrabold"}>{formatPrice(value)}</span>
            </p>
          </>
        )}
      </div>
      {newDto && (
        <div className={"bg-white rounded-xl shadow-xl px-3"}>
          <DrinkItem drink={newDto} editable={true} modified={modified} />
        </div>
      )}
      <div className={"bg-white rounded-xl p-3"}>
        {filterItems.map((drink) => (
          <DrinkItem
            key={drink.uuid}
            drink={drink}
            editable={editor}
            modified={modified}
          />
        ))}
      </div>
    </div>
  );
};

export default Drinks;
