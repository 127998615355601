import { Customer, CustomerDto } from "../typings/customer";
import { get, patch, post } from "./database";

const FILE_NAME = "customers";

export const getCustomers = (): Promise<Customer[]> => {
  return new Promise((resolve, reject) => {
    get(FILE_NAME)
      .then((r) => resolve(r as Customer[]))
      .catch(reject);
  });
};

export const getCustomer = (id: string): Promise<Customer> => {
  return new Promise((resolve, reject) => {
    get(`${FILE_NAME}/${id}`)
      .then((r) => resolve(r as Customer))
      .catch(reject);
  });
};

export const createCustomer = (customer: CustomerDto): Promise<Customer> => {
  return new Promise((resolve, reject) => {
    post(FILE_NAME, customer)
      .then((r) => resolve(r as Customer))
      .catch(reject);
  });
};

export const patchCustomer = (customer: CustomerDto): Promise<Customer> => {
  return new Promise((resolve, reject) => {
    patch(FILE_NAME, customer)
      .then((r) => resolve(r as Customer))
      .catch(reject);
  });
};
