import React from "react";
import { faqs, Sections } from "../../../faq/FaqHome";
import { ModalView } from "../Reservations";

export const houseRules = faqs.find((fa) => fa.id === Sections.HOUSE_RULES);

const ReadHouseRules = ({ setModal }: { setModal: (d: ModalView) => void }) => {
  return (
    <>
      {houseRules && (
        <section className={"p-9 bg-white space-y-3 rounded-lg"}>
          <p className={"font-semibold"}>Ground rules</p>
          <p className={"line-clamp-3 text-ellipsis"}>
            {houseRules.paragraphs[0]}
          </p>
          <p
            className={"underline underline-offset-4 text-end font-semibold"}
            onClick={() => setModal("ground rules")}
          >
            More info
          </p>
        </section>
      )}
    </>
  );
};

export default ReadHouseRules;
