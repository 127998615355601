import React, { useCallback, useEffect, useState } from "react";
import Calendar from "./Calendar";
import { StringKeys } from "../typings/reservation";

interface Props<T = any> {
  showSearch?: boolean;
  placeholder?: string;
  selectedId?: string;
  isCalendar?: boolean;
  onChange?: (id: string) => void;
  keySelector: StringKeys<T>;
  titleSelector: StringKeys<T>;
  thumbnailUrlSelector?: StringKeys<T>;
  list: T[];
  icon?: string;
  error?: string;
  title?: string;
}

const ListInput = <T,>(props: Props<T>) => {
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [itemSelected, setItemSelected] = useState<T | undefined>();
  const [filterItems, setFilterItems] = useState<T[]>(props.list);
  const [search, setSearch] = useState("");

  const toggleDropDown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    searchList(event.target.value);
  };

  const searchList = useCallback(
    (value: string) => {
      if (value.trim() === "") {
        setFilterItems(props.list);
      } else {
        const filtered = props.list.filter((item) =>
          (item[props.titleSelector] || "")
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
        );
        setFilterItems(filtered);
      }
    },
    [props.list, props.titleSelector],
  );

  const setItem = (item: T) => {
    props.onChange && props.onChange(item[props.keySelector] as string);
    toggleDropDown();
    setItemSelected(item);
    setSearch("");
  };

  const showContent = () => {
    if (props.isCalendar) return <Calendar />;

    return (
      <ul className="absolute top-full left-0 right-0 z-50 mt-2 p-3 shadow-2xl rounded-xl border max-h-80 overflow-auto bg-white text-sm">
        {props.showSearch && (
          <input
            value={search}
            onChange={handleSearchChange}
            className="border rounded-xl p-2 w-full"
            placeholder="Search"
          />
        )}
        {filterItems.map((item) => (
          <p
            key={item[props.keySelector] as string}
            onClick={() => setItem(item)}
            className="p-2"
          >
            {item[props.titleSelector] as string}
          </p>
        ))}
        {filterItems.length === 0 ? (
          <p className={"mt-3 text-gray-500"}>No records to show</p>
        ) : null}
      </ul>
    );
  };

  useEffect(() => {
    for (let item of props.list) {
      if (item[props.keySelector] === props.selectedId) {
        setItemSelected(item);
      }
    }
    searchList(search);
  }, [props.keySelector, props.list, props.selectedId, search, searchList]);

  useEffect(() => {
    if (!props.selectedId) setItemSelected(undefined);
  }, [props.selectedId]);

  return (
    <div className={"flex flex-row"}>
      {props.title && (
        <p className={"mb-2 text-xs text-gray-500"}>
          {props.title.toUpperCase()}
        </p>
      )}
      <div
        className={`rounded-2xl border flex flex-row flex-wrap py-3 px-2 items-center text-sm w-full relative ${props.error ? "border-pink-500" : ""}`}
      >
        {props.icon && (
          <i className={`${props.icon} text-2xl text-gray-500`}></i>
        )}
        <input
          onClick={toggleDropDown}
          className={`h-full mx-3 flex-grow bg-transparent focus:outline-none ${itemSelected ? "" : "text-gray-500"}`}
          placeholder={props.placeholder}
          readOnly
          value={String(itemSelected?.[props.titleSelector] ?? "")}
        />
        {isDropdownVisible && showContent()}
      </div>
    </div>
  );
};

export default ListInput;
