export const Fuels: Record<number, Omit<Fuel, "id">> = {
  1: {
    name: "petrol",
    unit: "l",
  },
  2: {
    name: "diesel",
    unit: "l",
  },
  3: {
    name: "gas",
    unit: "kg",
  },
  4: {
    name: "oil_petrol",
    unit: "l",
  },
  5: {
    name: "oil_diesel",
    unit: "l",
  },
};

export const Sources: Source[] = [
  {
    id: 1,
    name: "BEDC 1",
  },
  {
    id: 2,
    name: "BEDC 2",
  },
  {
    id: 3,
    name: "Fireman",
  },
  {
    id: 4,
    name: "Tec Ultimate",
  },
  {
    id: 5,
    name: "Maxi",
  },
  {
    id: 6,
    name: "CAT",
  },
];

export interface Fuel {
  id: number;
  name: string;
  description?: number;
  unit: "l" | "m3" | "kg";
}

export interface Source {
  id: number;
  name: string;
  description?: string;
}

export interface Run {
  id: string;
  from: string;
  to: string;
}

export interface Runs {
  [sourceId: number]: Run[];
}

export interface Stock {
  [fuel: number]: number;
}

export interface Order {
  [fuel: number]: number[];
}

export interface EnergyRecord {
  [date: string]: { stock: Stock; order: Order; runs: Runs };
}

export const EnergyRecordSample: EnergyRecord = {
  "2024-07-01": {
    stock: {
      1: 20,
      2: 0,
    },
    order: {
      1: [20],
    },
    runs: {
      1: [{ from: "", to: "", id: "2" }],
    },
  },
};

/**
 * How should the energy record look like?
 *
 * 1. Dashboard should select the current month
 * 2. Select the current day of the month, should list all entries for that month, no day selection
 * 4. Month view should have 3 tabs
 * 5. Balance Tab, Usages Tab and Orders Tab
 * 6. Balance Tab should show per day, of the month, what we have for all fuel sources
 * 7. Usages tab should show per day, of the month, what we have for all usages, from and to
 * 8. Usages tab will also have tabs for each source Id
 * 9. Order tab will have
 *
 *
 *
 */
