import { post } from "./database";

const RESOURCE_PATH = "email";

export const sendEmail = ({
  to,
  subject,
  html,
}: {
  html: string;
  to: string;
  subject: string;
}): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    post(`${RESOURCE_PATH}?to=${to}&subject=${subject}`, html, {
      headers: { "Content-Type": "text/html" },
    })
      .then(() => resolve())
      .catch(reject);
  });
};
