import React from "react";
import ActivityModal from "../../../../components/ActivityModal";
import BankTransfer from "../../../../components/BankTransfer";
import { PayWith } from "../../../../components/PaymentOption";

const ConfirmAndPay = ({
  handlePaymentCompleted,
  amountToPay,
  onClose,
  payWith,
}: {
  amountToPay: number;
  payWith: PayWith;
  handlePaymentCompleted: (amountToPaid: number) => void;
  onClose: () => void;
}) => {
  return (
    <ActivityModal
      footer={""}
      onClose={onClose}
      title={"Confirm and pay"}
      body={
        <BankTransfer
          amount={amountToPay}
          onPaymentCompleted={handlePaymentCompleted}
        />
      }
    />
  );
};

export default ConfirmAndPay;
