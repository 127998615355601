import React, { useEffect, useState } from "react";
import ListInput from "../../../../../components/ListInput";
import NumberInput from "../../../../../components/NumberInput";
import Calendar from "../../../../../components/Calendar";
import { Room } from "../../../../../typings/rooms";
import { Moment } from "moment/moment";
import Guests from "./Guests";
import { Booking } from "../../../../../typings/reservation";
import { getCheckInOut } from "../../../../../utils/validation";
import moment from "moment";

interface Props {
  roomsMap: Map<string, Room & { id: string; title: string }>;
  onChange?: (data: Partial<Booking>) => void;
  validate?: boolean;
  booking?: Partial<Booking>;
}

export enum CalendarView {
  EMPTY,
  CHECK_IN,
  CHECK_OUT,
}

export const DEFAULT_CHECK_IN_HOURS_UTC = 14;
export const DEFAULT_CHECK_OUT_HOURS_UTC = 11;

export const DEFAULT_CALENDER_VIEW = CalendarView.EMPTY;
export const DEFAULT_ADULTS = 4;
export const DEFAULT_CHILDREN = 0;

const SingleBooking = (props: Props) => {
  const [showCalendarFor, setShowCalendarFor] = useState<CalendarView>(
    DEFAULT_CALENDER_VIEW,
  );

  const [checkIn, setCheckIn] = useState<Moment>(
    moment(props.booking?.checkInDateTime) || moment(),
  );
  const [checkOut, setCheckOut] = useState<Moment>(
    moment(props.booking?.checkOutDateTime) || moment().add(2, "days"),
  );

  const [checkInTime, setCheckInTime] = useState(
    moment(props.booking?.checkInDateTime).format("HH:mm") ||
      `${DEFAULT_CHECK_IN_HOURS_UTC}:00`,
  );
  const [checkOutTime, setCheckOutTime] = useState(
    moment(props.booking?.checkOutDateTime).format("HH:mm") ||
      `${DEFAULT_CHECK_OUT_HOURS_UTC}:00`,
  );

  const [room, setRoom] = useState<Room | undefined>(
    props.roomsMap.get(props.booking?.roomUuid || ""),
  );

  const [adults, setAdults] = useState(
    props.booking?.guests?.adults || DEFAULT_ADULTS,
  );
  const [children, setChildren] = useState(
    props.booking?.guests?.children || DEFAULT_CHILDREN,
  );

  const [errors, setErrors] = useState<{
    room?: string;
    checkInDate?: string;
    checkOutDate?: string;
    checkInTime?: string;
    checkOutTime?: string;
    adults?: string;
    children?: string;
  }>({});

  function onCalendarChange(date: Moment): void {
    if (showCalendarFor === CalendarView.CHECK_IN) {
      if (checkOut && date.isAfter(checkOut)) {
        setCheckIn(checkOut);
        setCheckOut(date);
      } else setCheckIn(date);
    }

    if (showCalendarFor === CalendarView.CHECK_OUT) {
      if (checkIn && date.isBefore(checkIn)) {
        setCheckIn(date);
        setCheckOut(checkIn);
      } else setCheckOut(date);
    }

    setShowCalendarFor(CalendarView.EMPTY);
  }

  function handleRoomChange(id: string): void {
    if (props.roomsMap.has(id)) {
      setRoom(props.roomsMap.get(id) as Room);
    }
  }

  function onCheckInTimeChange(time: string): void {
    setCheckInTime(time);
  }

  function onCheckOutTimeChange(time: string): void {
    setCheckOutTime(time);
  }

  useEffect(() => {
    if (props.onChange) {
      props.onChange({
        checkInDateTime: checkIn
          ? getCheckInOut(checkIn, checkInTime, "in")
          : undefined,
        checkOutDateTime: checkOut
          ? getCheckInOut(checkOut, checkOutTime, "out")
          : undefined,
        guests: { adults, children },
        roomUuid: room?.uuid,
      });
    }
  }, [
    adults,
    checkIn,
    checkInTime,
    checkOut,
    checkOutTime,
    children,
    props,
    props.onChange,
    room?.uuid,
  ]);

  useEffect(() => {
    if (props.validate) {
      setErrors({
        adults: adults < 1 ? "At least one adult is required" : undefined,
        checkInDate: !checkIn ? "Please select a check in date" : undefined,
        checkOutDate: !checkOut ? "Please select a check out date" : undefined,
        children: undefined,
        room: !room?.uuid ? "Please select a room" : undefined,
      });
    } else {
      setErrors({});
    }
  }, [adults, checkIn, checkOut, props.validate, room?.uuid]);

  return (
    <div>
      <div className={""}>
        <ListInput
          error={errors?.room}
          keySelector={"uuid"}
          titleSelector={"title"}
          thumbnailUrlSelector={"thumbnailUrl"}
          onChange={handleRoomChange}
          icon={"bx bx-building-house"}
          placeholder={room?.title || "Add room"}
          selectedId={room?.uuid}
          list={Array.from(props.roomsMap.values())}
          showSearch={true}
        />
        <div className={"mt-2"} />
        <div className={"mt-2"} />
        <div
          className={`rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4 mb-2 ${errors?.checkInDate || errors?.checkOutDate ? "border-pink-500" : ""}`}
        >
          <div className={"flex flex-row items-center"}>
            <i className="bx bx-log-out-circle text-2xl"></i>
            <button
              onClick={() =>
                setShowCalendarFor(
                  showCalendarFor ? CalendarView.EMPTY : CalendarView.CHECK_IN,
                )
              }
              className={`h-full mx-3 items-center ${checkIn ? "" : "text-gray-500"}`}
            >
              {checkIn ? checkIn?.format("MMM D 'YY") : "Check In"}
            </button>
          </div>
          <div className={"flex flex-row items-center"}>
            <i className="bx bx-log-in-circle text-2xl"></i>
            <button
              onClick={() =>
                setShowCalendarFor(
                  showCalendarFor ? CalendarView.EMPTY : CalendarView.CHECK_OUT,
                )
              }
              className={`h-full mx-3 items-center ${checkOut ? "" : "text-gray-500"}`}
            >
              {checkOut ? checkOut?.format("MMM D 'YY") : "Check Out"}
            </button>
          </div>
        </div>
        <div
          className={`rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4 ${errors?.checkInTime || errors?.checkOutTime ? "border-pink-500" : ""}`}
        >
          <NumberInput
            onChange={onCheckInTimeChange}
            icon={"bx bxs-timer"}
            placeholder={"HH:mm"}
            value={checkInTime}
            valuePrefix={""}
          />
          <NumberInput
            onChange={onCheckOutTimeChange}
            icon={"bx bx-timer"}
            placeholder={"HH:mm"}
            value={checkOutTime}
            valuePrefix={""}
          />
        </div>
      </div>
      {showCalendarFor !== CalendarView.EMPTY && (
        <div className={"w-full mt-3"}>
          <Calendar
            onChange={onCalendarChange}
            checkIn={checkIn}
            checkOut={checkOut}
          />
        </div>
      )}
      <div className={"mt-2"}>
        <Guests
          errors={{ children: errors?.children, adults: errors?.adults }}
          adults={adults}
          children={children}
          setAdults={setAdults}
          setChildren={setChildren}
        />
      </div>
    </div>
  );
};

export default SingleBooking;
