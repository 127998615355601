import React, { useState } from "react";
import { formatPrice } from "../../../../utils/validation";
import { Counter } from "../../../../components/Counter";
import { Menu } from "../../../../typings/menu";

const FoodPreview = ({
  food,
  onClose,
  defaultQty = 1,
  onAdd,
}: {
  food: Menu;
  onClose: () => void;
  onAdd: (qty: number) => void;
  defaultQty?: number;
}) => {
  const [qty, setQty] = useState(defaultQty);

  function handleOnAdd() {
    onAdd(qty);
  }

  return (
    <div
      className={
        "absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-80 z-50 flex flex-col justify-end"
      }
    >
      <div
        className={
          "relative max-w-[600px] mx-auto pb-[env(safe-area-inset-bottom)]"
        }
      >
        <div className={"bg-white w-full rounded-t-3xl overflow-hidden"}>
          <div className={"overflow-hidden w-full h-60 aspect-square"}>
            <img
              src={food.thumbnailUrl}
              className={"object-cover w-full h-full"}
              alt={""}
            />
          </div>
          <div className={"text-center p-9 space-y-1"}>
            <p className={"text-lg font-bold"}>{food.title}</p>
            <p className={"text-lg font-bold"}>{formatPrice(food.rate)}</p>
            {food.outOfStock && (
              <p
                className={
                  "text-pink-500 font-extrabold underline decoration-dashed underline-offset-4"
                }
              >
                Out of stock
              </p>
            )}
            <div className={"max-h-32 overflow-y-scroll scrollbar-hidden"}>
              <p>{food.description}</p>
            </div>
          </div>
          <div
            className="pb-[env(safe-area-inset-bottom)] mb-4 flex flex-row px-9 justify-between items-center space-x-3"
            style={{
              paddingBottom: `calc(env(safe-area-inset-bottom) + 1rem)`,
            }}
          >
            <Counter
              min={defaultQty}
              max={10000}
              defaultValue={qty}
              onChange={setQty}
            />
            <button
              disabled={qty < 1}
              onClick={handleOnAdd}
              className={
                "p-4 px-6 rounded-full flex flex-row justify-between items-center text-white text-lg bg-pink-500 flex-grow font-bold"
              }
            >
              <p className={""}>Add</p>
              <p>{formatPrice(qty * food.rate)}</p>
            </button>
          </div>
          <button
            onClick={onClose}
            className={
              "absolute top-0 right-0 m-6 bg-black bg-opacity-40 rounded-full w-8 h-8 flex flex-row items-center justify-center text-white"
            }
          >
            <i className={"bx bx-x text-xl"}></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FoodPreview;
