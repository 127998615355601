import React from "react";
import Navigation from "./app/nav/Navigation";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Navigation />
        <Toaster />
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
