import React, { useState, useEffect } from "react";
import moment from "moment/moment";
import { getRoomBgColor } from "../BookingCalendar";
import { Reservation } from "../../../../typings/reservation";

const WeekView = ({ reservations }: { reservations: Reservation[] }) => {
  // Read the current week start from search params or use the current date if not set
  const [currentWeekStart, setCurrentWeekStart] = useState<moment.Moment>(
    moment().startOf("week"),
  );

  // This function will update the URL with the new week's start date
  const updateWeekInURL = (weekStart: moment.Moment) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set("week", weekStart.format("YYYY-MM-DD"));
    window.history.pushState(null, "", "?" + searchParams.toString());
  };

  // Function to calculate the days of the current week based on `currentWeekStart`
  const getWeekDays = (start: moment.Moment) => {
    return Array.from({ length: 7 }, (_, index) =>
      start.clone().add(index, "days"),
    );
  };

  const goToNextWeek = () => {
    const nextWeekStart = currentWeekStart.clone().add(1, "week");
    setCurrentWeekStart(nextWeekStart);
    updateWeekInURL(nextWeekStart); // Update URL when navigating to next week
  };

  const goToPreviousWeek = () => {
    const previousWeekStart = currentWeekStart.clone().subtract(1, "week");
    setCurrentWeekStart(previousWeekStart);
    updateWeekInURL(previousWeekStart); // Update URL when navigating to previous week
  };

  // Get the current week days
  const currentWeekDays = getWeekDays(currentWeekStart);

  // Effect to read the week from the URL on initial load
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const weekParam = searchParams.get("week");
    if (weekParam) {
      const weekStart = moment(weekParam, "YYYY-MM-DD");
      if (weekStart.isValid()) {
        setCurrentWeekStart(weekStart);
      }
    }
  }, []); // Run only once on mount

  return (
    <div className={"space-y-3"}>
      <div className="flex justify-between mb-0 items-center">
        <button onClick={goToPreviousWeek} className="bg-gray-200 rounded">
          <i className="bx bx-chevron-left text-xl"></i>
        </button>
        <p className={"font-extrabold"}>
          {`WEEK ${moment(currentWeekStart).week()}, ${moment(currentWeekStart).format("MMMM YYYY").toUpperCase()}`}
        </p>

        <button onClick={goToNextWeek} className="bg-gray-200 text-xl">
          <i className="bx bx-chevron-right"></i>
        </button>
      </div>

      <div className={"p-3 bg-white rounded-2xl shadow-xl"}>
        <div className={"relative"}>
          <div className="absolute inset-0 grid grid-cols-7">
            {Array.from({ length: 7 }).map((_, index) => {
              return (
                <div
                  key={index}
                  className={`border-l border-gray-200 border-dashed ${index === 0 ? "border-transparent" : "border-gray-200"}`}
                  style={{
                    position: "relative",
                    zIndex: 0,
                  }}
                />
              );
            })}
          </div>
          <div className="grid grid-cols-7 gap-0 text-xs text-center">
            {currentWeekDays.map((day) => (
              <div
                key={day.format()}
                className="flex-1 my-3 flex flex-row justify-end"
              >
                <p
                  className={
                    "text-xs w-fit " +
                    (moment().startOf("day").isSame(day.startOf("day"))
                      ? "font-extrabold bg-pink-500 rounded-full px-1 text-white" // Highlight today
                      : "")
                  }
                >
                  {day.format("ddd D")}
                </p>
              </div>
            ))}
          </div>

          {reservations
            .filter((reservation) => reservation.status !== "CANCELLED")
            .map((reservation) => {
              return reservation.bookings
                .filter((booking) => {
                  const checkIn = moment(booking.checkInDateTime);
                  const checkOut = moment(booking.checkOutDateTime);
                  // Only consider bookings that overlap with the current week
                  return (
                    checkIn.isBefore(currentWeekStart.clone().add(7, "days")) &&
                    checkOut.isAfter(currentWeekStart)
                  );
                })
                .map((booking, bookingIndex) => {
                  const checkInDate = moment(booking.checkInDateTime);
                  const checkOutDate = moment(booking.checkOutDateTime);

                  // Calculate the startIndex based on the check-in date relative to the start of the week
                  const startIndex = checkInDate.isBefore(currentWeekStart)
                    ? 0 // Booking starts before the current week, so start at the beginning of the week
                    : checkInDate.diff(currentWeekStart, "days");

                  // Calculate the span based on the check-out date relative to the end of the current week
                  const weekEndDate = currentWeekStart.clone().add(7, "days");

                  const span = checkOutDate.isAfter(weekEndDate)
                    ? 7 - startIndex // If the booking goes beyond the week, it should only span till Sunday
                    : checkOutDate.diff(
                        checkInDate.isBefore(currentWeekStart)
                          ? currentWeekStart
                          : checkInDate,
                        "days",
                      );

                  const bgColor = getRoomBgColor(booking.roomUuid);

                  return (
                    <div
                      key={`${reservation.id}-${bookingIndex}`}
                      className="grid grid-cols-7 gap-0 items-center p-0 m-0 justify-center text-xs text-white font-bold border-b border-gray-200 border-dashed"
                    >
                      <a
                        href={`/host/reservations/${reservation.id}`}
                        className={`shadow-xl ${bgColor} p-1 rounded-full text-center line-clamp-1 my-2 z-10`}
                        style={{
                          gridColumnStart: startIndex + 1, // Offset for 1-based index
                          gridColumnEnd: `span ${span}`, // Span across the number of nights booked
                        }}
                      >
                        {reservation.id}
                      </a>
                    </div>
                  );
                });
            })}
        </div>
      </div>
    </div>
  );
};

export default WeekView;
