import React from "react";
import ActivityModal from "../../../../components/ActivityModal";
import { PayWith } from "../../../../components/PaymentOption";
import BankTransfer from "../../../../components/BankTransfer";

interface Interface {
  onClose: () => void;
  payWith: PayWith;
  amount: number;
  onPaymentCompleted: (paid: number) => void;
}

const MakePayment = ({
  onClose,
  payWith,
  onPaymentCompleted,
  amount,
}: Interface) => {
  const handlePayment = () => {
    switch (payWith) {
      case "bank transfer": {
        return (
          <BankTransfer
            amount={amount}
            onPaymentCompleted={onPaymentCompleted}
          />
        );
      }
    }
  };

  return (
    <ActivityModal
      title={"Confirm and pay"}
      body={<>{handlePayment()}</>}
      footer={""}
      onClose={onClose}
    />
  );
};

export default MakePayment;
