import React from "react";

const Logo = ({ size = 8, text = 1 }: { size?: number; text?: number }) => {
  return (
    <a href={"/"}>
      <div
        className={`bg-pink-500 h-${size} w-${size} flex flex-row items-center justify-center rounded-lg overflow-hidden`}
      >
        <p className={`text-${text}xl flex text-white font-extrabold`}>IF</p>
      </div>
    </a>
  );
};

export default Logo;
