import React, { useEffect, useState } from "react";

export const Counter = ({
  min = 0,
  max = 8,
  defaultValue = 0,
  onChange = () => {},
  showTrash = false,
}: {
  min?: number;
  max?: number;
  defaultValue?: number;
  onChange?: (i: number) => void;
  showTrash?: boolean;
}) => {
  const [counter, setCounter] = useState(defaultValue);

  const minusDisabled = counter === min;
  const plusDisabled = counter >= max;

  const increase = () => {
    if (counter < max) setCounter(counter + 1);
  };

  const decrease = () => {
    if (counter > min) setCounter(counter - 1);
  };

  useEffect(() => {
    onChange(counter);
  }, [counter]);

  const trash = showTrash && counter <= 1;

  return (
    <div className={"flex flex-row justify-center items-center space-x-3"}>
      <button
        onClick={decrease}
        disabled={minusDisabled}
        className={`w-8 h-8 ${trash ? "" : "border border-gray-500 rounded-full "}  flex flex-row items-center justify-center ${minusDisabled ? "opacity-30" : ""}`}
      >
        {showTrash && counter <= 1 ? (
          <i className={"bx bxs-trash text-lg"}></i>
        ) : (
          <i className={"bx bx-minus text-lg text-gray-500"}></i>
        )}
      </button>
      <div className={"w-4 h-4 flex flex-row items-center justify-center"}>
        <p className={""}>{counter}</p>
      </div>
      <button
        onClick={increase}
        disabled={plusDisabled}
        className={`w-8 h-8 rounded-full border border-gray-500 flex flex-row items-center justify-center ${plusDisabled ? "opacity-30" : ""}`}
      >
        <i className={"bx bx-plus text-lg text-gray-500"}></i>
      </button>
    </div>
  );
};
