import React, { useEffect, useState } from "react";
import { get } from "../../../api/database";
import { Reservation } from "../../../typings/reservation";
import FullPage from "../../../components/FullPage";
import { Room } from "../../../typings/rooms";
import PillSelector from "../../../components/PillSelector";
import WeekView from "./pages/WeekView";
import MonthView from "./pages/MonthView";
import Loader from "../../../components/Loader";
import { useLocation, useNavigate } from "react-router-dom";

export const roomColorMap: Record<string, string> = {
  "e6474788-4756-4b04-b842-3b8a2f37de7e": "bg-blue-400", // Bright Blue
  "5579d88d-77d9-4b60-94ec-0ce912893da3": "bg-green-400", // Bright Green
  "a277f60a-791d-40db-83b6-317991b9bd4f": "bg-yellow-400", // Bright Yellow
  "9ac94e6f-c297-4d46-8234-6a91f83dbe5a": "bg-purple-400", // Bright Purple
  "fd9e994b-cdf4-4628-a785-cdb64577ca35": "bg-red-400", // Bright Red
  "ede8128a-781f-4cc5-abcb-40cb9b02067e": "bg-teal-400", // Bright Teal
  "6dcd18f8-8d45-457f-a823-f2215cbf0e4b": "bg-pink-400", // Bright Pink
  "b3d83b29-2e39-43e3-898b-9a785fc4c583": "bg-orange-400", // Bright Orange
  "fcf566b6-4e2e-4b37-a8d0-64017d5e28b5": "bg-indigo-400", // Bright Indigo
  "c95e94e4-930b-4630-82ec-f7b3df38deff": "bg-lime-400", // Bright Lime
};

export const getRoomBgColor = (uuid: string) => {
  return roomColorMap[uuid] || "bg-gray-200";
};

export type View = "month" | "week";

const BookingCalendar: React.FC<{}> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  // Directly initialize selectedView from the URL search params
  const [selectedView, setSelectedView] = useState<View>(
    (new URLSearchParams(location.search).get("view") as View) || "month",
  );

  const [reservationsLoading, setReservationsLoading] = useState(true);
  const [roomsLoading, setRoomsLoading] = useState(true);

  const views: { id: View; title: string; disabled?: boolean }[] = [
    { id: "month", title: "Month View", disabled: false },
    { id: "week", title: "Week View" },
  ];

  // Fetch rooms on mount
  useEffect(() => {
    setRoomsLoading(true);
    get<Room[]>("rooms")
      .then(setRooms)
      .catch(console.log)
      .finally(() => setRoomsLoading(false));
  }, []);

  // Fetch reservations on mount
  useEffect(() => {
    setReservationsLoading(true);
    get<Reservation[]>("reservations")
      .then(setReservations)
      .catch(console.log)
      .finally(() => setReservationsLoading(false));
  }, []);

  // Handle view change and update URL
  const handleViewChange = (view: View) => {
    setSelectedView(view);
    const newSearch = new URLSearchParams(location.search);
    newSearch.set("view", view);
    navigate({
      pathname: location.pathname,
      search: newSearch.toString(), // Update URL search params
    });
  };

  return (
    <FullPage
      title={"BOOKINGS CALENDAR"}
      back={"/host"}
      component={
        <div className="flex flex-col space-y-3 py-6 text-xs">
          <div className={"mb-6"}>
            <PillSelector
              multiple={false}
              selectedIds={[selectedView]}
              items={views}
              onChange={(ids: string[]) => {
                handleViewChange(ids[0] as View);
              }}
            />
          </div>

          {reservationsLoading ? (
            <Loader />
          ) : (
            <>
              {selectedView === "week" ? (
                <WeekView reservations={reservations} />
              ) : selectedView === "month" ? (
                <MonthView reservations={reservations} />
              ) : (
                <p className={"p-3 bg-white rounded-xl shadow-xl"}>
                  Please select view
                </p>
              )}
            </>
          )}
          {!roomsLoading && (
            <div className={"text-xs pt-6 space-y-3"}>
              <p>LEGEND</p>
              <div
                className={
                  "text-xs bg-white p-3 rounded-xl shadow-xl w-fit space-y-1"
                }
              >
                {rooms.map((room) => (
                  <div
                    className={"flex flex-row space-x-3 items-center"}
                    key={room.uuid}
                  >
                    <div
                      className={`w-6 h-6 rounded-lg ${getRoomBgColor(room.uuid)}`}
                    ></div>
                    <p>{room.title}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};

export default BookingCalendar;
