import React from "react";

const Button = ({
  onClick = () => {},
  type = "primary",
  text,
  disabled,
  block,
}: {
  onClick?: () => void;
  type?: "primary" | "secondary";
  text: string;
  disabled?: boolean;
  block?: boolean;
}) => {
  const styles = {
    primary: "bg-pink-500 text-white",
    secondary: "bg-white border",
  };
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`font-semibold shadow-xl rounded-lg p-2 px-6 ${disabled ? "opacity-50" : ""} ${styles[type]} ${block ? "w-full p-4" : "w-fit"}`}
    >
      {text}
    </button>
  );
};

export default Button;
