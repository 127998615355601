import React from "react";

interface Interface {
  title: string;
  body: React.ReactNode;
  footer: React.ReactNode;
  onClose?: () => void;
  noClose?: boolean;
}

const ActivityModal = ({
  onClose = () => {},
  title,
  body,
  footer,
  noClose,
}: Interface) => {
  return (
    <div
      className={
        "absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-80 z-50"
      }
    >
      <div className="h-full max-w-[600px] mx-auto mt-9 rounded-t-2xl bg-white flex flex-col overflow-hidden">
        {/* Fixed header */}
        <div id="header" className="sticky top-0 bg-white z-10">
          <p className="text-center font-bold py-6 border-b">{title}</p>

          {!noClose && (
            <button className={"absolute top-6 left-6"} onClick={onClose}>
              <i className={"bx bx-arrow-back text-lg"}></i>
            </button>
          )}
        </div>

        {/* Scrollable middle section */}
        <div
          id="scrollable-body"
          className="overflow-y-auto w-full flex-1 pb-80 space-y-6 scrollbar-hidden"
        >
          {body}
        </div>

        {/* Fixed footer */}
        <div
          id="fixed-button-area"
          className="sticky bottom-0 bg-white pb-[env(safe-area-inset-bottom)] mb-4 flex flex-row px-6 justify-between items-center space-x-3 z-10"
          style={{
            paddingBottom: `calc(env(safe-area-inset-bottom) + 3rem)`, // Adds 1rem above the safe zone for spacing
          }}
        >
          {footer}
        </div>
      </div>
    </div>
  );
};

export default ActivityModal;
