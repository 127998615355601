import React, { useEffect, useState } from "react";
import { InvoiceEntry, Service } from "../../../../typings/reservation";
import DoubleClickButton from "../../../../components/DoubleClickButton";
import ListInput from "../../../../components/ListInput";
import { shortenPrice } from "../../../../utils/validation";
import AddCustomService from "./AddCustomService";
import Modal from "../../../../components/Modal";
import { v4 } from "uuid";

const ServiceGroup = ({
  service,
  entries: _entries,
  services = [],
  onChange = () => {},
  customize,
}: {
  service: Service;
  entries: InvoiceEntry[];
  services?: InvoiceEntry[];
  onChange?: (service: Service, entries: InvoiceEntry[]) => void;
  customize?: boolean;
}) => {
  const [entries, setEntries] = useState<InvoiceEntry[]>(_entries);

  const [customEntry, setCustomEntry] = useState<
    (InvoiceEntry & { index?: number }) | undefined
  >(undefined);

  function addEmpty() {
    if (services.length > 0) {
      const array = [{ ...services[0], quantity: 1 }, ...entries];
      setEntries(array);
      notify(array);
    }
  }

  function removeItem(index: number) {
    const array = [...entries];
    array.splice(index, 1);
    setEntries(array);
    notify(array);
  }

  function notify(items: InvoiceEntry[]) {
    onChange(service, items);
  }

  function onQtyChanged(value: string, index: number) {
    const newArray = [...entries];

    // Parse quantity and ensure it's a valid number, defaulting to 0 if not
    const quantity = Number(value) || 0;

    // Update the entry directly
    newArray[index] = {
      ...newArray[index],
      quantity,
      cost: quantity * newArray[index].rate,
    };

    // Update state and notify with the new array
    setEntries(newArray);
    notify(newArray);
  }

  function onTitleChange(value: string, index: number) {
    const newArray = [...entries];
    newArray[index].title = value;
    setEntries(newArray);
    notify(newArray);
  }

  function onServiceChanged(id: string, index: number) {
    const find = services.find((product) => product.uuid === id);
    if (find) {
      const oldItem = { ...entries[index] };
      const newArray = [...entries];
      newArray[index] = { ...find, quantity: oldItem.quantity };
      setEntries(newArray);
      notify(newArray);
    }
  }

  function handleCustomOnSave(d: InvoiceEntry & { index?: number }) {
    setCustomEntry(undefined);
    let newArray = [...entries];

    if (d.index !== undefined && d.index !== null) {
      newArray[d.index] = { ...newArray[d.index], ...d };
    } else {
      newArray.unshift(d);
    }
    setEntries(newArray);
    notify(newArray);
  }

  useEffect(() => {
    setEntries(_entries); // Update internal state when _entries prop changes
  }, [_entries]);

  return (
    <div className={"text-sm py-0"}>
      <div className={"flex flex-row justify-between text-gray-800 mb-1"}>
        <p className={"font-extrabold"}>
          {service}
          {customize && (
            <span className={"italic text-gray-700 font-light mx-3"}>
              <button
                onClick={() =>
                  setCustomEntry({
                    cost: 0,
                    description: "Untitled",
                    quantity: 0,
                    rate: 0,
                    service: "OTHER",
                    title: "Untitled",
                    unit: "nos",
                    uuid: v4(),
                    index: undefined,
                  })
                }
              >
                (Click to add custom entries)
              </button>
            </span>
          )}
        </p>
        {!customize && services.length > 0 && (
          <button onClick={addEmpty}>
            <i className={"bx bx-plus text-lg font-extrabold"}></i>
          </button>
        )}
      </div>
      <div>
        {entries.map((entry, index) => (
          <div
            key={entry + "-" + index}
            className={
              "flex flex-row justify-between flex-nowrap items-center my-2"
            }
          >
            <div className={"flex flex-row space-x-3 w-3/4"}>
              <DoubleClickButton
                onRelease={() => {
                  removeItem(index);
                }}
                component={<i className={"bx bx-trash text-pink-500"}></i>}
              />
              {services.length > 0 ? (
                <ListInput<InvoiceEntry>
                  keySelector={"uuid"}
                  titleSelector={"title"}
                  list={services}
                  selectedId={entry.uuid}
                  onChange={(id) => onServiceChanged(id, index)}
                />
              ) : (
                <input
                  type={"text"}
                  className={"flex-shrink-0"}
                  value={entry.title}
                  onChange={(e) => onTitleChange(e.target.value, index)}
                />
              )}
            </div>
            <div
              className={
                "flex flex-row justify-start items-center space-x-1 w-1/4"
              }
            >
              <input
                type={"number"}
                className={"text-center text-sm p-2 rounded w-10 h-8"}
                value={entry.quantity}
                onChange={(e) => onQtyChanged(e.target.value, index)}
              />
              <div className={"flex flex-col text-xs text-gray-700 italic"}>
                <p>{shortenPrice(entry.rate)}/</p>
                <button
                  disabled={!customize}
                  onClick={() => {
                    setCustomEntry({ ...entry, index });
                  }}
                >
                  {entry.unit}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <hr className={"my-2"} />
      <Modal
        show={!!customEntry}
        component={
          <AddCustomService
            onCancel={() => {
              setCustomEntry(undefined);
            }}
            entry={customEntry}
            onSave={handleCustomOnSave}
          />
        }
      />
    </div>
  );
};

export default ServiceGroup;
