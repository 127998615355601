import { Booking, Invoice, InvoiceEntry } from "../typings/reservation";
import html2canvas from "html2canvas";
import moment from "moment-timezone";
import { Moment } from "moment";
import {
  DEFAULT_CHECK_IN_HOURS_UTC,
  DEFAULT_CHECK_OUT_HOURS_UTC,
} from "../pages/host/reservations/fragments/shared/SingleBooking";
import { Room } from "../typings/rooms";
import { Customer } from "../typings/customer";
import { toastInfo } from "./toast";

export function isValidTime(time: string): boolean {
  const format = "HH:mm";
  return moment(time, format, true).isValid();
}

export function isValidEmail(email: string) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string) {
  const regex = /^\+?[1-9]\d{1,14}$/;
  return regex.test(phoneNumber);
}

export function isValidName(name: string) {
  const regex = /^[\p{L}\s'-]{2,50}$/u;
  return regex.test(name);
}

export function formatPrice(
  value: number,
  noCurrency: boolean = false,
): string {
  // Format the value in Naira
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  })
    .format(value)
    .replace(noCurrency ? "₦" : "", ""); // Divide by 100 because we're including kobo
}

export function getTimeOfDayImage(): string {
  // Get the current hour
  const currentHour = new Date().getHours();

  // Determine which image URL to return based on the time of day
  let imageUrl: string;

  if (currentHour >= 5 && currentHour < 12) {
    // Morning (5 AM to 11:59 AM)
    imageUrl = "https://images7.alphacoders.com/355/thumb-1920-355464.jpg";
  } else if (currentHour >= 12 && currentHour < 17) {
    // Afternoon (12 PM to 4:59 PM)
    imageUrl =
      "https://img.freepik.com/premium-photo/sky-blue-background-vector-bright-sky-clouds-sunny-day-with-morning-sunlight-winter-daytime-horizon-nature-scenery-afternoon-summer-spring-weather-seasons-illustration-banner_880763-33851.jpg";
  } else if (currentHour >= 17 && currentHour < 19) {
    // Evening (5 PM to 8:59 PM)
    imageUrl =
      "https://img.freepik.com/premium-photo/clouds-dawn-blue-sky-sun-rays-banner-format_980224-626.jpg";
  } else {
    // Midnight (9 PM to 4:59 AM)
    imageUrl =
      "https://img.freepik.com/premium-photo/night-sky-background-space-background-long-banner-midnight-sky-stars-background_569725-22627.jpg";
  }

  return imageUrl;
}

export function getGreeting(): string {
  const currentHour = new Date().getHours();

  let greeting = "Hello";

  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Good morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    greeting = "Good afternoon";
  } else if (currentHour >= 17 && currentHour <= 24) {
    greeting = "Good evening";
  }

  return greeting;
}

export function getInvoiceTotals(invoice: Invoice): {
  total: number;
  balance: number;
  paid: number;
  vat: number;
  discount: number;
} {
  let total = 0;
  for (let entry of invoice.entries) {
    total += entry.quantity * entry.rate;
  }

  return {
    total,
    balance: total - invoice.paid - invoice.discount + invoice.vat,
    paid: invoice.paid,
    vat: invoice.vat,
    discount: invoice.discount,
  };
}

export const printToJpg = async (
  element: HTMLDivElement | null,
  name: string,
) => {
  if (element) {
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    link.href = data;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export function getCheckInOut(
  date: Moment,
  time: string,
  type: "in" | "out",
  timezone = "Africa/Lagos",
  format = "YYYY-MM-DD",
) {
  if (!isValidTime(time)) {
    return moment
      .tz(`${moment(date).format(format)}`, timezone)
      .set({
        hour:
          type === "in"
            ? DEFAULT_CHECK_IN_HOURS_UTC
            : DEFAULT_CHECK_OUT_HOURS_UTC,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
      .toISOString();
  }
  return moment
    .tz(`${moment(date).format(format)} ${time}`, timezone)
    .toISOString();
}

export function getDates(bookings: Booking[]) {
  let minCheckIn = moment();
  let maxCheckOut = moment();

  const dates: Moment[] = [];

  // Collect all check-in and check-out dates in an array
  for (let booking of bookings) {
    let inDate = moment(booking.checkInDateTime).startOf("day");
    let outDate = moment(booking.checkOutDateTime).endOf("day");

    dates.push(inDate, outDate);
  }

  // Sort the dates array in ascending order
  dates.sort((a, b) => a.valueOf() - b.valueOf());

  // Return the minimum and maximum dates
  minCheckIn = dates[0];
  maxCheckOut = dates[dates.length - 1];

  return { minCheckIn, maxCheckOut };
}

export function getValue(entries: InvoiceEntry[]) {
  return entries.reduce((acc, entry) => acc + entry.rate * entry.quantity, 0);
}

export function shortenPrice(num: number): string {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 10,
    notation: "compact",
    compactDisplay: "short",
  }).format(num);
}

export function totTitleCase(word: string) {
  return word
    .replaceAll("_", " - ")
    .replaceAll("-", " ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function isValidDate(word: string) {
  return moment(word.trim(), "YYYY-MM-DD", true).isValid();
}

export function validateReservation(
  bookings: Partial<Booking>[],
  rooms: Map<string, Room>,
  total: number,
  paid: number,
  customer?: Customer,
): {
  bookings: Booking[];
  value: number;
  isValid?: boolean;
  error?: string;
  roomNames: string[];
} {
  const createdBookings: Booking[] = [];
  let value = 0;
  const roomNames: string[] = [];

  if (!customer)
    return { error: "Invalid customer", value, bookings: [], roomNames };

  for (let booking of bookings) {
    const {
      roomUuid,
      checkInDateTime,
      checkOutDateTime,
      guests: { adults, children } = { adults: 0, children: 0 },
    } = booking;

    if (isNaN(adults) || Number(adults) < 1) {
      return {
        error: "Invalid number of adults",
        value,
        bookings: [],
        roomNames,
      };
    }

    if (isNaN(children)) {
      return {
        error: "Invalid number of children",
        value,
        bookings: [],
        roomNames,
      };
    }

    if (!roomUuid) {
      return { error: "Invalid room", value, bookings: [], roomNames };
    }

    if (!(checkInDateTime && moment(checkInDateTime).isValid())) {
      return {
        error: "Invalid check out date or time",
        value,
        bookings: [],
        roomNames,
      };
    }

    if (!(checkOutDateTime && moment(checkOutDateTime).isValid())) {
      return {
        error: "Invalid check out date or time",
        value,
        bookings: [],
        roomNames,
      };
    }

    const room = rooms.get(roomUuid) as Room;
    let qty = 1;
    if (room.unit === "night") {
      qty = moment(checkOutDateTime).diff(moment(checkInDateTime), "days");
    } else if (room.unit === "hour") {
      qty = moment(checkOutDateTime).diff(moment(checkInDateTime), "hours");
    }

    value += room.rate * qty;

    roomNames.push(room.title);

    createdBookings.push({
      checkInDateTime,
      checkOutDateTime,
      guests: { children, adults },
      roomUuid,
    });
  }

  if (bookings.length === 0) {
    return {
      error: "Empty booking [ERROR: 900]",
      value,
      bookings: [],
      roomNames,
    };
  }

  if (!customer) {
    return { error: "Invalid customer", value, bookings: [], roomNames };
  }

  if (total <= 0) {
    return { error: "Invalid total value", value, bookings: [], roomNames };
  }

  if (paid < 0) {
    return { error: "Invalid paid value", value, bookings: [], roomNames };
  }

  return { bookings: createdBookings, value, isValid: true, roomNames };
}

export function copyToClipboard(text: string) {
  navigator.clipboard
    .writeText(text)
    .then(() => toastInfo("Copied"))
    .catch((err) => {
      console.error("Failed to copy: ", err);
    });
}
