import React, { useState } from "react";
import PaymentOption from "./PaymentOption";
import Button from "./Button";
import BankTransfer from "./BankTransfer";
import ActivityModal from "./ActivityModal";

type PayWith = "bank transfer" | "credit or debit card" | "paypal";

const PaymentBuilder = ({
  amount,
  onPaymentCompleted,
}: {
  amount: number;
  onPaymentCompleted: (amountPaid: number) => void;
}) => {
  const [paymentOption, setPaymentOption] = useState<PayWith>("bank transfer");
  const [confirmAndPay, setConfirmAndPay] = useState(false);

  const handlePayment = () => {
    switch (paymentOption) {
      case "bank transfer": {
        return (
          <BankTransfer
            amount={amount}
            onPaymentCompleted={onPaymentCompleted}
          />
        );
      }
    }
  };

  if (confirmAndPay) {
    return (
      <ActivityModal
        title={"Confirm and pay"}
        body={<>{handlePayment()}</>}
        footer={""}
        onClose={() => {
          setConfirmAndPay(false);
        }}
      />
    );
  }

  return (
    <div className={"space-y-6"}>
      <PaymentOption setPaymentMethod={setPaymentOption} />
      <Button
        text={"Confirm and pay"}
        block={true}
        onClick={() => {
          setConfirmAndPay(true);
        }}
      />
    </div>
  );
};

export default PaymentBuilder;
