import React, { useCallback, useEffect, useRef, useState } from "react";

interface Props {
  items: { id: string; title: string; disabled?: boolean }[];
  onChange: (ids: string[]) => void;
  selectedIds?: string[];
  multiple?: boolean;
  large?: boolean;
}

const PillSelector = (props: Props) => {
  const { multiple = true, large = false } = props;
  const [selectedIds, setSelectedIds] = useState<string[]>(
    props.selectedIds || [],
  );
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const onSelect = (id: string) => {
    if (!multiple) {
      setSelectedIds((prev) => (prev.includes(id) ? [] : [id]));
      return;
    }

    setSelectedIds((prev) => {
      const updatedIds = new Set(prev);
      if (updatedIds.has(id)) {
        updatedIds.delete(id);
      } else {
        updatedIds.add(id);
      }
      return Array.from(updatedIds);
    });
  };

  const scrollToFirstSelected = useCallback(() => {
    if (selectedIds.length > 0) {
      const firstSelectedId = selectedIds.sort()[0];
      const firstSelectedButton = document.getElementById(firstSelectedId);
      if (firstSelectedButton) {
        firstSelectedButton.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }
  }, [selectedIds]); // Dependency on selectedIds

  useEffect(() => {
    // Delay the scroll to ensure the DOM is updated
    const timeoutId = setTimeout(scrollToFirstSelected, 100);
    return () => clearTimeout(timeoutId); // Clean up on unmount
  }, [scrollToFirstSelected]); // Run effect when scrollToFirstSelected changes

  useEffect(() => {
    props.onChange(selectedIds);
    // eslint-disable-next-line
  }, [selectedIds]);

  return (
    <div
      ref={scrollContainerRef}
      className={`flex flex-row items-center overflow-x-scroll space-x-3 ${large ? "text-lg" : "text-sm"}`}
    >
      {props.items.map((item) => (
        <button
          id={item.id} // Ensure the button has the correct ID
          disabled={item.disabled}
          key={item.id}
          onClick={() => {
            onSelect(item.id);
          }}
          className={`${
            item.disabled ? "opacity-50" : ""
          } whitespace-nowrap px-6 rounded-full border border-white py-1 shadow-xl ${
            selectedIds.includes(item.id)
              ? "bg-pink-500 text-white"
              : "bg-white"
          }`}
        >
          {item.title}
        </button>
      ))}
    </div>
  );
};

export default PillSelector;
