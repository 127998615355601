import React, { useState } from "react";
import moment, { Moment } from "moment";
import { Reservation } from "../../../../typings/reservation";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";
import MonthCalendar from "../../../../components/MonthCalendar";
import { composeBookingDates } from "../../../../utils/room";

interface Interface {
  onChange: (d: { checkIn?: Moment; checkOut?: Moment }) => void;
  checkIn?: Moment;
  checkOut?: Moment;
  onClose: () => void;
  reservations: Reservation[];
  roomUuid: string;
  blockedNights: Moment[];
}

export const MAX_MONTHS_IN_ADVANCE = 12;

const SelectDates = (props: Interface) => {
  const [checkIn, setCheckIn] = useState<Moment | undefined>(props.checkIn);
  const [checkOut, setCheckOut] = useState<Moment | undefined>(props.checkOut);

  const startMonth = moment();
  const monthsToDisplay = MAX_MONTHS_IN_ADVANCE;

  const isDateDisabled = (date: Moment) => {
    return (
      date.isBefore(moment(), "day") ||
      props.blockedNights.some((disabledDate) =>
        date.isSame(disabledDate, "day"),
      )
    );
  };

  function onSelect(selection: Moment) {
    const bookingDates = composeBookingDates(
      selection,
      { checkIn, checkOut },
      props.blockedNights,
    );

    // Update the state with the newly calculated checkIn and checkOut dates
    setCheckIn(bookingDates.checkIn);
    setCheckOut(bookingDates.checkOut);

    props.onChange(bookingDates);
  }

  return (
    <ActivityModal
      title={"Select dates"}
      body={
        <div className="w-full px-6 p-3">
          {Array.from({ length: monthsToDisplay }).map((_, index) => (
            <MonthCalendar
              key={index}
              monthMoment={moment(startMonth).add(index, "months")}
              onSelect={onSelect}
              isDateDisabled={isDateDisabled}
              checkIn={checkIn}
              checkOut={checkOut}
            />
          ))}
        </div>
      }
      footer={<Button text={"Save"} block={true} onClick={props.onClose} />}
      onClose={props.onClose}
    />
  );
};

export default SelectDates;
