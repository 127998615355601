import React, { useEffect, useState } from "react";
import FullPage from "../../../components/FullPage";
import BotChat from "../../../components/BotChat";
import { RitaThumbnailUrl } from "../../../constants/links";
import { Customer } from "../../../typings/customer";
import { getGreeting } from "../../../utils/validation";
import AvailableInvoice from "./AvailableInvoice";
import { get } from "../../../api/database";
import { Invoice, Reservation } from "../../../typings/reservation";
import { useNavigate } from "react-router-dom";

const GuestInvoice = ({
  customer,
  reservation,
}: {
  customer?: Customer;
  reservation?: Reservation;
}) => {
  const navigate = useNavigate();

  const [invoice, setInvoice] = useState<Invoice | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (reservation)
      get<Invoice>("invoices/" + reservation.id)
        .then((i) => {
          setInvoice(i);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
  }, [reservation]);

  useEffect(() => {
    if (!customer || !reservation) navigate("/guest");
  }, [customer, navigate, reservation]);

  return (
    <FullPage
      title={"YOUR INVOICE"}
      back={"/guest"}
      bannerUrl={
        "https://www.shutterstock.com/image-vector/isometric-online-payment-concept-internet-260nw-1105866398.jpg"
      }
      component={
        loading || !customer ? (
          <BotChat delays={100} profilePhoto={RitaThumbnailUrl} messages={[]} />
        ) : (
          <div>
            {invoice ? (
              <AvailableInvoice
                customer={customer}
                reservation={reservation!}
                invoice={invoice}
              />
            ) : (
              <BotChat
                text={"text-sm"}
                profilePhoto={RitaThumbnailUrl}
                messages={[
                  {
                    content: `${getGreeting()} ${customer?.firstName}, I have not been able to create your invoice yet, kindly check back later...`,
                    sender: "Rita",
                  },
                  {
                    content:
                      "Alternatively, if this is urgent and you are expecting a refund please give me a phone call.",
                    sender: "Rita",
                  },
                  {
                    content: "Thanks again, for staying with us!",
                    sender: "Rita",
                  },
                ]}
              />
            )}
          </div>
        )
      }
    />
  );
};

export default GuestInvoice;
