import React from "react";
import moment from "moment/moment";
import { RitaThumbnailUrl } from "../constants/links";

export const HostPhoto = ({
  thumbnailUrl = RitaThumbnailUrl,
  size = 16,
}: {
  thumbnailUrl?: string;
  size?: number;
}) => {
  return (
    <div className={`shadow rounded-full w-${size} h-${size}`}>
      <img
        alt={"Ifemide Host"}
        className={
          "filter brightness-200 contrast-500 object-cover rounded-full m-0 p-0 shadow-2xl"
        }
        src={thumbnailUrl}
      ></img>
    </div>
  );
};

const HostProfile = ({
  name = "Rita Esosa",
  thumbnailUrl = RitaThumbnailUrl,
}: {
  name?: string;
  thumbnailUrl?: string;
}) => {
  return (
    <div>
      <HostPhoto thumbnailUrl={thumbnailUrl} />
      <p className={"mt-3"}>{name}</p>
      <p className={"font-thin text-sm"}>{moment().format("MMMM DD, YYYY")}</p>
    </div>
  );
};

export default HostProfile;
