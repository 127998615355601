import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FullPage from "../../../components/FullPage";
import BotChat from "../../../components/BotChat";
import { BotThumbnailUrl } from "../../../constants/links";
import { get, patch, post } from "../../../api/database";
import {
  Invoice,
  InvoiceEntry,
  PaymentInfo,
  Reservation,
  Service,
} from "../../../typings/reservation";
import { Room } from "../../../typings/rooms";
import { Drink } from "../../../typings/menu";
import { AxiosError } from "axios";
import ListInput from "../../../components/ListInput";
import { Customer } from "../../../typings/customer";
import Button from "../../../components/Button";
import ServiceGroup from "./fragments/ServiceGroup";
import moment from "moment";
import Totals from "./fragments/Totals";
import { getDates, getValue } from "../../../utils/validation";
import { render } from "@react-email/components";
import { sendEmail } from "../../../api/email";
import InvoiceIsReady from "../../../emails/InvoiceIsReady";
import Modal from "../../../components/Modal";
import { InvoiceView } from "../../guest/invoice/InvoiceView";

const HostInvoice = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError | undefined>();
  const [saving, setSaving] = useState(false);

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [drinks, setDrinks] = useState<Drink[]>([]);
  const initialized = useRef(false);

  const [reservation, setReservation] = useState<Reservation | undefined>();
  const [invoice, setInvoice] = useState<Invoice | undefined>();
  const [customer, setCustomer] = useState<Customer | undefined>();

  const [preview, setPreview] = useState(false);

  const reservationsMap = useMemo(
    () =>
      new Map(
        reservations.map((item) => [
          item.id,
          { ...item, id: item.id, title: item.id, thumbnailUrl: "" },
        ]),
      ),
    [reservations],
  );

  const invoicesMap = useMemo(
    () =>
      new Map(
        invoices.map((item) => [
          item.reservationId,
          { ...item, reservationId: item.reservationId },
        ]),
      ),
    [invoices],
  );

  // Function for handling reservation change
  function onReservationChanged(_id: string) {
    const id = Number(_id);
    if (reservationsMap.has(id)) {
      const selectedReservation = reservationsMap.get(id);
      setReservation(selectedReservation); // This will trigger the useEffect above
    }
  }

  function onClear() {
    setReservation(undefined);
    setInvoice(undefined);
    setCustomer(undefined);
    setError(undefined);
  }

  async function onSend() {
    if (reservation && invoice && customer) {
      const { minCheckIn, maxCheckOut } = getDates(reservation.bookings);
      setSaving(true);
      await onSave()
        .then(async () => {
          const html = await render(
            <InvoiceIsReady
              customerName={customer.firstName}
              invoice={invoice}
              checkInDate={minCheckIn.format("ddd. MMMM Do")}
              checkOutDate={maxCheckOut.format("ddd. MMMM Do")}
            />,
          );
          await sendEmail({
            to: customer.emailAddress,
            html,
            subject: `Dear ${customer.firstName}, your invoice is ready!`,
          }).catch(setError);
        })
        .catch(setError)
        .finally(() => setSaving(false));
    }
  }

  async function onSave() {
    if (reservation && invoice) {
      setSaving(true);
      await get<Invoice>("invoices/" + reservation.id)
        .then(async (r) => {
          await patch<Invoice>("invoices", { ...r, ...invoice })
            .then(setInvoice)
            .catch(setError);
        })
        .catch(async (e: AxiosError) => {
          if (e.status === 404) {
            await post<Invoice>("invoices", invoice)
              .then(setInvoice)
              .catch(setError);
          } else setError(e);
        })
        .finally(() => {
          setSaving(false);
        });
    }
  }

  async function removeFromStock() {
    if (reservation && invoice) {
      setSaving(true);
      await onSave()
        .then(async () => {
          setSaving(true);
          await patch<Invoice>("invoices?removeFromStock=true", invoice)
            .then(setInvoice)
            .catch(setError);
        })
        .catch(setError);
      setSaving(false);
    }
  }

  const onEntriesChanged = useCallback(
    (service: Service, entries: InvoiceEntry[]) => {
      if (!invoice) return;

      const nonServiceEntries = invoice.entries.filter(
        (f) => f.service !== service,
      );

      // Create a new invoice with updated entries
      const newInvoice = {
        ...invoice,
        entries: [...nonServiceEntries, ...entries],
      };

      const total = invoice.entries.reduce(
        (acc, entry) => acc + entry.rate * entry.quantity,
        0,
      );

      setInvoice({ ...newInvoice, total });
    },
    [invoice, setInvoice], // Dependencies: `invoice` and `setInvoice`
  );

  const onPaymentInfoChanged = useCallback(
    (paymentInfo: Pick<PaymentInfo, "discount" | "paid">) => {
      if (!invoice || !reservation) return;

      const total = getValue(invoice.entries);

      setInvoice({
        ...invoice,
        discount: paymentInfo.discount,
        paid: paymentInfo.paid,
        total,
        balance: total - paymentInfo.paid - paymentInfo.discount,
      });
    },
    [invoice, reservation],
  );

  useEffect(() => {
    if (initialized.current) return; // Prevent re-triggering if already initialized

    initialized.current = true; // Mark as initialized after the first call
    get<Invoice[]>("invoices")
      .then((_invoices) => {
        get<Reservation[]>("reservations")
          .then((_reservations) => {
            get<Room[]>("rooms")
              .then((_rooms) => {
                get<Drink[]>("drinks")
                  .then((_drinks) => {
                    setRooms(_rooms);
                    setDrinks(_drinks);
                    setInvoices(_invoices);
                    setReservations(_reservations);
                  })
                  .catch(setError);
              })
              .catch(setError);
          })
          .catch(setError);
      })
      .catch(setError)
      .finally(() => setLoading(false));
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (reservation) {
      get<Customer>("customers/" + reservation.customerUuid)
        .then((c) => {
          const id = reservation.id;

          if (invoicesMap.has(id)) {
            // If there's already an invoice for the selected reservation, set it
            setInvoice(invoicesMap.get(id));
          } else {
            // Create default entries for the new reservation
            const defaultEntries: InvoiceEntry[] = [];
            for (let booking of reservation.bookings) {
              const room = rooms.find((room) => room.uuid === booking.roomUuid);

              if (room) {
                const checkInMoment = moment(booking.checkInDateTime);
                const checkOutMoment = moment(booking.checkOutDateTime);

                let quantity: number = 0;

                if (room.unit === "night") {
                  // Calculate the number of nights
                  const checkInDateTime = checkInMoment.startOf("day");
                  const checkOutDateTime = checkOutMoment.startOf("day");

                  quantity = checkOutDateTime.diff(checkInDateTime, "days"); // Difference in days (nights)
                } else if (room.unit === "hour") {
                  // Calculate the number of hours
                  quantity = checkOutMoment.diff(checkInMoment, "hours"); // Difference in hours
                }

                defaultEntries.push({
                  description: room.description,
                  quantity,
                  rate: room.rate,
                  service: "ROOM", // Assume rooms for now, but can be extended to other services
                  title: room.title,
                  uuid: booking.roomUuid,
                  unit: room.unit,
                  cost: room.rate * quantity,
                });
              }
            }

            const value = getValue(defaultEntries);
            const paid = reservation.paymentInfo.paid;
            const offer = reservation.paymentInfo.total;
            const discount = Math.max(0, value - offer);
            const due = value - discount - paid;

            // Create and set the new invoice for this reservation
            setInvoice({
              createdAt: moment().toISOString(),
              updatedAt: moment().toISOString(),

              // Host changes
              discount: discount,
              paid: paid,

              // Auto calculated
              total: value,
              balance: due,

              entries: defaultEntries,
              reservationId: reservation.id,
              vat: 0,
            });
          }
          setCustomer(c);
        })
        .catch(setError);
    }
  }, [invoicesMap, reservation, rooms]);

  const services: Record<Service, InvoiceEntry[]> = {
    ROOM: rooms.map((room) => ({
      title: room.title,
      uuid: room.uuid,
      service: "ROOM",
      rate: room.rate,
      description: room.description,
      quantity: 1,
      cost: room.rate,
      unit: room.unit,
    })),
    DRINK: drinks.map((drink) => ({
      title: drink.title,
      uuid: drink.uuid,
      service: "DRINK",
      rate: drink.rate,
      description: drink.description,
      quantity: 1,
      cost: drink.rate,
      unit: "nos",
    })),
    SPA: [],
    SALON: [],
    FOOD: [],
    OTHER: [],
  };

  return (
    <FullPage
      back={"/host"}
      title={"CUSTOMER INVOICE"}
      component={
        <div className={"bg-white p-6 rounded-xl text-sm"}>
          {loading ? (
            <BotChat
              delays={1000}
              messages={[]}
              profilePhoto={BotThumbnailUrl}
            />
          ) : (
            <div className={"space-y-3"}>
              <BotChat
                delays={1000}
                text={"text-sm"}
                profilePhoto={BotThumbnailUrl}
                messages={[
                  {
                    content:
                      "Hi, I have collected all your reservations and sorted by last updated.",
                  },
                ]}
              />
              {error && (
                <p className={"border border-pink-500 p-3 rounded-xl bg-white"}>
                  {error.message || "An unexpected error occurred"}
                </p>
              )}

              <div className={"space-y-1 p-3 rounded-xl bg-gray-100"}>
                <p className={"text-xs font-extrabold"}>RESERVATIONS</p>
                <ListInput
                  showSearch={true}
                  keySelector={"id"}
                  titleSelector={"title"}
                  thumbnailUrlSelector={"thumbnailUrl"}
                  placeholder={
                    "Select reservations to create or modify its invoice"
                  }
                  icon={""}
                  onChange={onReservationChanged}
                  list={reservations.map((item: Reservation) => ({
                    id: String(item.id),
                    title: String(item.id),
                    thumbnailUrl: "",
                  }))}
                />
              </div>

              {invoice && reservation && customer && (
                <div>
                  <hr className={"my-3"} />
                  <div className={"space-y-4"}>
                    {Object.keys(services).map((serviceKey) => {
                      const service = serviceKey as Service; // Cast to Service type
                      return (
                        <ServiceGroup
                          key={service}
                          service={service}
                          entries={invoice.entries.filter(
                            (i) => i.service === service,
                          )}
                          onChange={onEntriesChanged}
                          services={services[service]}
                          customize={serviceKey === "OTHER"}
                        />
                      );
                    })}

                    <div className={"flex flex-row justify-between"}>
                      <Button
                        onClick={onClear}
                        type={"secondary"}
                        text={"Cancel"}
                      />
                      <div className={"space-x-1"}>
                        <Button
                          onClick={onSave}
                          type={"secondary"}
                          text={"Save"}
                        />
                        <Button
                          onClick={onSend}
                          type={"primary"}
                          text={"Send"}
                        />
                      </div>
                    </div>

                    <hr />

                    <Totals
                      invoice={invoice}
                      paymentInfo={reservation.paymentInfo}
                      customer={customer}
                      bookings={reservation.bookings}
                      onPaymentInfoChanged={onPaymentInfoChanged}
                    />

                    <hr />

                    <div className={"flex flex-row justify-between"}>
                      <Button
                        disabled={invoice.removedFromStock}
                        onClick={removeFromStock}
                        type={"secondary"}
                        text={"Remove from Stock"}
                      />
                      <Button
                        onClick={() => {
                          setPreview(!preview);
                        }}
                        type={"primary"}
                        text={"Preview"}
                      />
                    </div>

                    <Modal
                      show={preview}
                      component={
                        <div
                          className={
                            "m-auto p-3 h-screen overflow-y-scroll max-w-[465px] space-y-3"
                          }
                        >
                          <div className={"flex flex-row justify-end"}>
                            <button
                              onClick={() => setPreview(!preview)}
                              className={
                                "bg-transparent text-white font-extrabold text-4xl rounded-full"
                              }
                            >
                              <i className={"bx bx-x"}></i>
                            </button>
                          </div>
                          <div className={"bg-white rounded-xl p-6"}>
                            <InvoiceView
                              invoice={invoice}
                              customerName={customer.firstName}
                              checkInDate={getDates(
                                reservation?.bookings,
                              ).minCheckIn.format("ddd. MMMM D")}
                              checkOutDate={getDates(
                                reservation?.bookings,
                              ).maxCheckOut.format("ddd. MMMM D")}
                            />
                          </div>
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {saving && (
            <div className="absolute top-0 bottom-0 right-0 left-0 bg-black bg-opacity-50 flex flex-col items-center justify-center space-y-3">
              <BotChat
                text={"text-sm"}
                delays={100000000}
                profilePhoto={BotThumbnailUrl}
                messages={[{ content: "Taking longer than usual..." }]}
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default HostInvoice;
