import React from "react";
import { Column, Img, Link, Row, Section, Text } from "@react-email/components";
import { LogoText } from "../ConfirmedReservations";
import IfemideAddress from "./IfemideAddress";
import {
  BUSINESS_EMAIL,
  BUSINESS_PHONE,
  BUSINESS_WEBSITE,
  InstagramIconLink,
  InstagramLink,
  TwitterIconLink,
  TwitterLink,
  WhatsAppIconLink,
  WhatsAppLink,
} from "../../constants/links";
import LogoRider from "./LogoRider";

export const Contact = () => {
  return (
    <Section className={"mt-3 text-sm"}>
      <Text style={{ padding: 0, margin: 0 }}>
        <Link href="tel:+2349067921124" style={{ padding: 0, margin: 0 }}>
          {BUSINESS_PHONE}
        </Link>
      </Text>
      <Text style={{ padding: 0, margin: 0 }}>
        <Link href={"mailto:" + BUSINESS_EMAIL}>{BUSINESS_EMAIL}</Link>
      </Text>
      <Text style={{ padding: 0, marginTop: 0 }}>
        <Link href="https://www.ifemide.com">{BUSINESS_WEBSITE}</Link>
      </Text>
    </Section>
  );
};

const IfemideFooter = ({ body = "" }: { body?: string }) => {
  return (
    <Section>
      <div className={"my-6"}>
        <LogoRider />
      </div>
      <Row style={{ margin: 0, padding: 0 }}>
        <Column align={"left"} className={"my-6"}>
          <LogoText />
        </Column>
        <Column align={"right"}>
          <Section>
            <Row>
              <Column align={"left"} className={"m-0 p-0"}>
                <Link href={WhatsAppLink}>
                  <Img
                    height={18}
                    width={18}
                    className={"rounded-full overflow-hidden"}
                    src={WhatsAppIconLink}
                  />
                </Link>
              </Column>
              <Column align={"center"} className={"m-0 p-0"}>
                <Link
                  href={InstagramLink}
                  className={"m-0 p-0"}
                  style={{ padding: 0, margin: 0 }}
                >
                  <Img
                    height={18}
                    width={18}
                    className={"rounded-full overflow-hidden m-0 p-0"}
                    src={InstagramIconLink}
                  />
                </Link>
              </Column>
              <Column align={"right"} className={"m-0 p-0"}>
                <Link href={TwitterLink}>
                  <Img
                    height={18}
                    width={18}
                    className={"rounded-full overflow-hidden"}
                    src={TwitterIconLink}
                  />
                </Link>
              </Column>
            </Row>
          </Section>
        </Column>
      </Row>
      <IfemideAddress text={body} />
    </Section>
  );
};

export default IfemideFooter;
