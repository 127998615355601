import React from "react";
import { Section, Text } from "@react-email/components";
import { Contact } from "./IfemideFooter";
import {
  BUSINESS_DESCRIPTION,
  BUSINESS_FULL_ADDRESS,
  BUSINESS_NAME,
} from "../../constants/links";

const IfemideAddress = ({ text = "" }: { text?: string }) => {
  return (
    <Section
      className={
        "text-xs font-thin flex flex-row justify-start items-start w-1/2 p-0 m-0 "
      }
    >
      <Text className={"m-0 p-0 " + text} style={{ margin: 0, padding: 0 }}>
        {BUSINESS_NAME + ", " + BUSINESS_DESCRIPTION}
      </Text>
      <Text className={"m-0 p-0 " + text} style={{ margin: 0, padding: 0 }}>
        {BUSINESS_FULL_ADDRESS}
      </Text>
      <Contact />
    </Section>
  );
};

export default IfemideAddress;
