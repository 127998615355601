import PaymentOption, { PayWith } from "../../../../components/PaymentOption";
import React from "react";

const Payment = ({ setPayWith }: { setPayWith: (d: PayWith) => void }) => {
  return (
    <section className={"p-9 bg-white space-y-3 rounded-lg"}>
      <p className={"font-semibold"}>Pay with</p>
      <PaymentOption setPaymentMethod={setPayWith} />
    </section>
  );
};

export default Payment;
