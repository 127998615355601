import React from "react";
import { formatPrice, totTitleCase } from "../../../../utils/validation";
import { Menu, MenuType } from "../../../../typings/menu";
import { Cart } from "../Kitchen";

const CategoryList = ({
  items,
  type,
  setFood,
  cart = new Map(),
  search,
}: {
  items: Menu[];
  type: MenuType;
  setFood: (food: Menu) => void;
  cart?: Cart;
  search: string;
}) => {
  const filteredFoods = items.filter((food) =>
    food.title.toLowerCase().includes(search.toLowerCase()),
  );

  if (!filteredFoods.length) return null;

  return (
    <section id={`category-${type}`} key={type}>
      <p className={"py-3 font-extrabold"}>{totTitleCase(type)}</p>
      <div className={`rounded-xl shadow-xl overflow-hidden`}>
        {filteredFoods.map((food, index) => {
          const inCart = (cart.get(food.uuid)?.quantity || 0) > 0;
          return (
            <div
              key={food.uuid}
              className={`flex flex-row justify-between space-x-3 p-3 py-6 ${index % 2 > 0 ? "bg-slate-100" : "bg-white"}`}
            >
              <div className={"flex-grow space-y-1"}>
                <p className={"font-extrabold"}>{food.title}</p>
                <p className={"font-extrabold"}>{formatPrice(food.rate)}</p>
                <p className={"line-clamp-3 text-ellipsis text-xs"}>
                  {food.description}
                </p>
              </div>
              <div className={"w-28 h-20 flex-shrink-0 relative"}>
                <img
                  onClick={() => {
                    setFood(food);
                  }}
                  alt=""
                  src={food.thumbnailUrl}
                  className={`w-full h-full object-cover overflow-hidden rounded-xl shadow-xl cursor-pointer ${food.outOfStock ? "opacity-50" : ""}`}
                />
                {!food.outOfStock && (
                  <button
                    onClick={() => {
                      setFood(food);
                    }}
                    className={`absolute -top-2 -right-3 border ${inCart ? "bg-white text-pink-500 border-pink-500 " : "bg-pink-500 text-white border-white"} rounded-full h-6 w-6 flex flex-row justify-center items-center shadow-2xl`}
                  >
                    {inCart ? (
                      cart.get(food.uuid)?.quantity
                    ) : (
                      <i className={"bx bx-plus text-lg"}></i>
                    )}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default CategoryList;
