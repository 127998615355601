import React, { useState } from "react";
import moment from "moment/moment";
import { Booking, Reservation } from "../../../../typings/reservation";
import { getRoomBgColor } from "../BookingCalendar";
import { useLocation, useNavigate } from "react-router-dom";

interface BookingExtended extends Booking {
  reservationId: number;
}

interface GroupedBookings {
  [roomUuid: string]: BookingExtended[];
}

const MonthView = ({ reservations }: { reservations: Reservation[] }) => {
  const location = useLocation(); // Get the current location object
  const navigate = useNavigate();

  const [currentMonth, setCurrentMonth] = useState<moment.Moment>(() => {
    const params = new URLSearchParams(location.search);
    const monthParam = params.get("month");

    // If 'month' parameter exists in URL, parse it, otherwise default to current month
    return monthParam ? moment(monthParam, "YYYY-MM") : moment();
  });

  // Navigate to next month
  const goToNextMonth = () => {
    const nextMonth = currentMonth.clone().add(1, "month");
    setCurrentMonth(nextMonth);
    const newSearch = new URLSearchParams(location.search);
    newSearch.set("month", nextMonth.format("YYYY-MM"));
    navigate({
      pathname: location.pathname,
      search: newSearch.toString(), // Update the search parameters in the URL
    });
  };

  // Navigate to previous month
  const goToPreviousMonth = () => {
    const previousMonth = currentMonth.clone().subtract(1, "month");
    setCurrentMonth(previousMonth);
    const newSearch = new URLSearchParams(location.search);
    newSearch.set("month", previousMonth.format("YYYY-MM"));
    navigate({
      pathname: location.pathname,
      search: newSearch.toString(), // Update the search parameters in the URL
    });
  };

  const startOfMonth = currentMonth.clone().startOf("month");
  const endOfMonth = currentMonth.clone().endOf("month");
  const startOfCalendar = startOfMonth.clone().startOf("week");
  const endOfCalendar = endOfMonth.clone().endOf("week");

  const calendarDays = Array.from(
    { length: endOfCalendar.diff(startOfCalendar, "days") + 1 },
    (_, index) => startOfCalendar.clone().add(index, "days"),
  );

  const weeks = [];
  for (let i = 0; i < calendarDays.length; i += 7) {
    weeks.push(calendarDays.slice(i, i + 7));
  }

  const groupBookingsByRoom = (
    reservations: Reservation[],
  ): GroupedBookings => {
    return reservations.reduce((acc: GroupedBookings, reservation) => {
      if (reservation.status !== "CANCELLED") {
        // Extend each booking with reservationId
        reservation.bookings.forEach((booking) => {
          const extendedBooking: BookingExtended = {
            ...booking,
            reservationId: reservation.id, // Adding reservationId to the booking
          };

          // Group bookings by roomUuid
          if (!acc[booking.roomUuid]) {
            acc[booking.roomUuid] = []; // Initialize empty array if no bookings exist for this roomUuid
          }

          acc[booking.roomUuid].push(extendedBooking);
        });
      }

      return acc;
    }, {} as GroupedBookings); // Return the grouped bookings as GroupedBookings type
  };

  const groupedBookings = groupBookingsByRoom(reservations);

  return (
    <div className="space-y-3">
      <div className="flex justify-between mb-0 items-center">
        <button onClick={goToPreviousMonth} className="bg-gray-200 rounded">
          <i className="bx bx-chevron-left text-xl"></i>
        </button>
        <p className="font-extrabold">
          {currentMonth.format("MMMM YYYY").toUpperCase()}
        </p>
        <button onClick={goToNextMonth} className="bg-gray-200 text-xl">
          <i className="bx bx-chevron-right"></i>
        </button>
      </div>

      <div className="p-3 bg-white rounded-2xl shadow-xl">
        <div className="relative">
          {/* Days of the week header */}
          <div className="grid grid-cols-7 text-end font-bold">
            {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
              <div key={day} className="py-2">
                {day}
              </div>
            ))}
          </div>
          <div className="absolute inset-0 grid grid-cols-7">
            {Array.from({ length: 7 }).map((_, index) => (
              <div
                key={index}
                className={`border-l border-gray-200 border-dashed ${
                  index === 0 ? "border-transparent" : "border-gray-200"
                }`}
                style={{
                  position: "relative",
                  zIndex: 0,
                }}
              />
            ))}
          </div>
        </div>

        {weeks.map((week, weekIndex) => {
          // For the current week, check if there are any bookings for any room
          const allBookingsForWeek = Object.keys(groupedBookings).map(
            (roomUuid) => {
              const currentWeekStart = moment(week[0]).startOf("week");
              const currentWeekEnd = moment(week[6]).endOf("day");

              // Get bookings for the current room in the current week
              return groupedBookings[roomUuid]
                .sort((a, b) => a.reservationId - b.reservationId) // Sort by reservation ID
                .filter(
                  (booking) =>
                    moment(booking.checkInDateTime).isBetween(
                      currentWeekStart,
                      currentWeekEnd,
                      null,
                      "[]",
                    ) ||
                    moment(booking.checkOutDateTime).isBetween(
                      currentWeekStart,
                      currentWeekEnd,
                      null,
                      "[]",
                    ),
                );
            },
          );

          // Flatten the array of room bookings and check if there are any bookings in this week
          const bookingsInThisWeek = allBookingsForWeek.flat();

          return (
            <div key={weekIndex}>
              <div className="grid grid-cols-7 gap-0 items-start relative bg-gray-100">
                {/* Render days of the week */}
                {week.map((day) => (
                  <div key={day.format()} className="flex flex-row justify-end">
                    <p
                      className={
                        "text-xs " +
                        (moment().startOf("day").isSame(day.startOf("day"))
                          ? "font-extrabold bg-pink-500 rounded-full px-1 text-white" // Highlight today
                          : "")
                      }
                    >
                      {day.format("D")}
                    </p>
                  </div>
                ))}
              </div>

              {/* Check if there are any bookings for this week */}
              {bookingsInThisWeek.length > 0 ? (
                // Render the bookings if there are any
                Object.keys(groupedBookings).map((roomUuid) => {
                  const currentWeekStart = moment(week[0]).startOf("week");
                  const currentWeekEnd = moment(week[0]).endOf("week");

                  // Get bookings for the current room in the current week
                  const roomBookings = groupedBookings[roomUuid]
                    .sort((a, b) => a.reservationId - b.reservationId)
                    .filter(
                      (booking) =>
                        moment(booking.checkInDateTime).isBetween(
                          currentWeekStart,
                          currentWeekEnd,
                          null,
                          "[]",
                        ) ||
                        moment(booking.checkOutDateTime).isBetween(
                          currentWeekStart,
                          currentWeekEnd,
                          null,
                          "[]",
                        ),
                    );

                  return (
                    <div
                      key={`${roomUuid}-${weekIndex}`} // Unique key for each room and week combination
                      className="grid grid-cols-7 gap-1 grid-flow-row"
                    >
                      {/* Render each booking for this room */}
                      {roomBookings.map((booking, bookingIndex) => {
                        const checkInDate = moment(booking.checkInDateTime);
                        const checkOutDate = moment(
                          booking.checkOutDateTime,
                        ).add(1);

                        let start = checkInDate.clone();
                        if (checkInDate.isBefore(currentWeekStart))
                          start = currentWeekStart.clone();

                        let end = checkOutDate.clone();
                        if (checkOutDate.isAfter(currentWeekEnd))
                          end = currentWeekEnd.clone();

                        let startIndex = start.diff(currentWeekStart, "days");
                        let span = end.diff(start, "days") + 1;

                        if (checkInDate.isBefore(currentWeekStart)) {
                          span = span - 1;
                          if (span === 0) {
                            return null;
                          }
                        }

                        return (
                          <div
                            key={`${booking.reservationId}-${bookingIndex}`}
                            className="col-span-7 items-center p-0 m-0 justify-center text-xs text-white font-bold border-b border-dashed"
                            style={{
                              gridColumnStart: startIndex + 1, // Adjust for 1-based index
                              gridColumnEnd: `span ${span}`, // Adjust for the number of days the booking spans
                            }}
                          >
                            <a
                              href={`/host/reservations/${booking.reservationId}`}
                              className={`shadow-xl ${getRoomBgColor(booking.roomUuid)} p-0 rounded-full text-center line-clamp-1 my-1 z-10`}
                              style={{
                                gridColumnStart: startIndex + 1, // Correct starting column
                                gridColumnEnd: `span ${span}`, // Correct span based on check-in and check-out dates
                              }}
                            >
                              {booking.reservationId}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                // Display message if there are no bookings for the week
                <div className="col-span-7 text-center py-6 font-extrabold">
                  NO BOOKINGS FOR THIS WEEK
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthView;
