import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";
import LottieAnimation from "./lottie/LottieAnimation";

const FullPageRider = ({
  onAction = () => {},
  imageUrl,
  title,
  subtitle,
  actionText = "Check availability",
  x = "left",
  backgroundPosition = "center",
  onSlidePressed = () => {},
}: {
  onAction?: () => void;
  imageUrl: string;
  title: string;
  subtitle: string;
  actionText?: string;
  backgroundPosition?: string;
  x?: "left" | "right";
  onSlidePressed?: () => void;
}) => {
  const [inView, setInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setInView(entry.isIntersecting);
      },
      { threshold: 0.3 }, // Trigger when 30% of the section is in view
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={sectionRef}
      className={
        "text-white mt-0 h-screen flex flex-col justify-end pb-40 space-y-3 p-6 text-" +
        x
      }
      style={{
        backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0) 100%), url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition,
      }}
    >
      <p className={`font-thin text-5xl ${inView ? "animate-fadeInUp" : ""}`}>
        {title}
      </p>
      <p
        className={`font-semibold text-lg ${inView ? "animate-fadeInUpDelayed" : ""}`}
      >
        {subtitle}
      </p>
      <div className={" flex flex-row items-center"}>
        <Button onClick={onAction} type={"primary"} text={actionText} />
        <button onClick={onSlidePressed} className="transition-all shadow-lg">
          <LottieAnimation />
        </button>
      </div>
    </div>
  );
};

export default FullPageRider;
