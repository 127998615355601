import React, { useEffect, useRef, useState } from "react";
import { NewCustomerData } from "./CompleteReservation";
import LottieAnimation from "../../../../components/lottie/LottieAnimation";
import Loader from "../../../../components/Loader";
import { Enquiry } from "../../../../typings/reservation";
import { Room } from "../../../../typings/rooms";
import moment, { Moment } from "moment";
import { post } from "../../../../api/database";
import { render } from "@react-email/components";
import ReservationRequest from "../../../../emails/ReservationRequest";
import { sendEmail } from "../../../../api/email";
import ActivityModal from "../../../../components/ActivityModal";

const SendEnquiry = ({
  customer,
  room,
  checkOut,
  checkIn,
  onClose,
}: {
  customer: NewCustomerData;
  room: Room;
  checkIn: Moment;
  checkOut: Moment;
  onClose: () => void;
}) => {
  const [done, setDone] = useState(false);

  const init = useRef(false);

  useEffect(() => {
    if (!init.current && !done) {
      init.current = true;

      const request: Enquiry = {
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumberLine1: customer.phoneNumberLine1,
        emailAddress: customer.emailAddress,
        checkInDateTime: checkIn.format(),
        checkOutDateTime: checkOut.format(),
        room: room?.title || "Not specified",
        addressLine1: customer.addressLine1,
        addressLine2: customer.addressLine2,
        roomUuids: [room.uuid],
      };

      post<Enquiry>("enquiries", request)
        .then((enq) => {
          render(<ReservationRequest {...request} />)
            .then((html) => {
              sendEmail({
                to: customer.emailAddress,
                subject:
                  "Your reservation request at Ifemide Estates, " +
                  moment(request.checkInDateTime).format("Do MMMM YYYY") +
                  " to " +
                  moment(request.checkOutDateTime).format("Do MMMM YYYY"),
                html: html,
              })
                .then(() => {
                  setDone(true);
                })
                .catch(console.log);
            })
            .catch(console.log);
        })
        .catch(console.log);
    }
  }, []);

  return (
    <ActivityModal
      footer={""}
      onClose={onClose}
      title={"Send enquiry"}
      body={
        <>
          {done ? (
            <>
              <LottieAnimation
                type={"star"}
                className={"w-full aspect-square"}
              />
              <div className={"px-9 space-y-3 text-center"}>
                <p className={"text-3xl font-thin"}>
                  Hooray! You've made an awesome choice with Ifemide Estates! 🌟
                </p>
                <p className={"font-bold text-lg"}>
                  We'll give you a quick call to iron out the details—let’s make
                  this happen! 📲😊
                </p>
              </div>
            </>
          ) : (
            <>
              <LottieAnimation
                type={"walking"}
                className={"w-full aspect-square"}
              />
              <Loader />
            </>
          )}
        </>
      }
    />
  );
};

export default SendEnquiry;
