import React from "react";
import { faqs, Sections } from "../../../faq/FaqHome";
import { ModalView } from "../Reservations";

export const cancellationPolicy = faqs.find(
  (fa) => fa.id === Sections.CANCELLATION_POLICY,
);

const ReadCancellationPolicy = ({
  setModal,
}: {
  setModal: (d: ModalView) => void;
}) => {
  return (
    <>
      {cancellationPolicy && (
        <section className={"p-9 bg-white space-y-3 rounded-lg"}>
          <p className={"font-semibold"}>Cancellation Policy</p>
          <p className={"line-clamp-3 text-ellipsis"}>
            {cancellationPolicy.paragraphs[0]}
          </p>
          <p
            className={"underline underline-offset-4 text-end font-semibold"}
            onClick={() => setModal("cancellation policy")}
          >
            More info
          </p>
        </section>
      )}
    </>
  );
};

export default ReadCancellationPolicy;
