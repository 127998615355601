import React from "react";

const FormEntry = (props: {
  title: string;
  placeholder: string;
  defaultValue?: string;
  value: string;
  type: string;
  disabled?: boolean;
  onChange: (data: string) => void;
  error?: string;
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    props.onChange(event.target.value);
  }

  return (
    <div className={"flex flex-row w-full"}>
      <div className={"my-3 w-full mb-9"}>
        <p className={"mb-2 text-xs text-gray-500"}>
          {props.title.toUpperCase()}
        </p>
        <input
          disabled={props.disabled}
          type={props.type}
          onChange={handleChange}
          className={`h-full w-full focus:border-pink-500 focus:outline-none rounded-xl border px-3 py-1 text-sm ${props.value ? "bg-white" : " bg-gray-100"} ${props.error ? "border-pink-500" : "border-gray-300"}`}
          placeholder={props.placeholder}
          value={props.value || props.defaultValue || ""}
        />
      </div>
    </div>
  );
};

export default FormEntry;
