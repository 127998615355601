import React from "react";
import { ModalView } from "../Reservations";
import { formatPrice } from "../../../../utils/validation";
import SingleEntry from "../../../../components/SingleEntry";

const PriceDetails = ({
  setCouponCode,
  validateCouponCode,
  total,
  rate,
  grandTotal,
  cleaningFee,
  nights,
  coupon,
}: {
  rate?: number;
  total: number;
  cleaningFee: number;
  coupon: number;
  nights: number;
  grandTotal: number;
  setModal: (p: ModalView) => void;
  setCouponCode: (p: string) => void;
  validateCouponCode: () => void;
}) => {
  return (
    <section className={"p-9 bg-white space-y-3 rounded-lg"}>
      <p className={"font-semibold"}>Price Details</p>
      <div className={"space-y-2"}>
        <div className={"flex flex-row justify-between"}>
          <p>
            {formatPrice(rate || 0)} x {nights} night(s)
          </p>
          <p>{formatPrice(total)}</p>
        </div>
        <div className={"flex flex-row justify-between"}>
          <p>Cleaning fee</p>
          <p>{formatPrice(cleaningFee)}</p>
        </div>
        <div className={"flex flex-row justify-between"}>
          <p>Coupon</p>
          <p>{formatPrice(-coupon)}</p>
        </div>
        <SingleEntry
          disabled={false}
          onChange={setCouponCode}
          onApply={validateCouponCode}
        />
      </div>
      <hr />
      <div className={"flex flex-row justify-between font-semibold"}>
        <p>Total</p>
        <p>{formatPrice(grandTotal)}</p>
      </div>
    </section>
  );
};

export default PriceDetails;
