import moment, { Moment } from "moment/moment";
import { Reservation } from "../typings/reservation";
import { RoomUuidRecordByNightly, RoomUuids } from "../typings/rooms";
import {
  BookingDates,
  Validation,
} from "../pages/visitor/reservation/Reservations";
import { validCustomer } from "../pages/visitor/reservation/helpers/reservation";
import { Customer } from "../typings/customer";

const getRoomsAvailabilityForDay = (
  date: Moment,
  reservations: Reservation[],
  roomsNightly: RoomUuidRecordByNightly,
): RoomUuidRecordByNightly => {
  let dayNightly: RoomUuidRecordByNightly = { ...roomsNightly };

  const removeNight = (roomUuid: string, qty: number = 1) => {
    dayNightly[roomUuid] = Math.max(0, (dayNightly[roomUuid] || 0) - qty);
  };

  for (let reservation of reservations) {
    for (let booking of reservation.bookings) {
      const roomUuid = booking.roomUuid;

      if (
        date.isSameOrAfter(moment(booking.checkInDateTime), "day") &&
        date.isBefore(moment(booking.checkOutDateTime), "day")
      ) {
        if (
          [
            RoomUuids.FOUR_BEDROOM,
            RoomUuids.THREE_BEDROOM,
            RoomUuids.TWO_BEDROOM,
          ].includes(roomUuid)
        ) {
          removeNight(RoomUuids.FOUR_BEDROOM);
          removeNight(RoomUuids.THREE_BEDROOM);
          removeNight(RoomUuids.TWO_BEDROOM);
          removeNight(RoomUuids.INDOOR_PARTY);
          removeNight(RoomUuids.ONE_BEDROOM, 4);
        } else if ([RoomUuids.WEDDING_PARTY_AND_EVENTS].includes(roomUuid)) {
          dayNightly = {}; // Empty the yard
        } else removeNight(roomUuid);
      }
    }
  }

  return dayNightly;
};

export const getBlockedNights = (
  from: Moment,
  to: Moment,
  reservations: Reservation[],
  roomsNightly: RoomUuidRecordByNightly,
  roomUuid: string,
): Moment[] => {
  const blockedNights: Moment[] = [];

  const nights = to.diff(from, "day");

  for (let i = 0; i < nights; i++) {
    const day = from.clone().add(i, "day");

    const roomsAvailabilityForDay = getRoomsAvailabilityForDay(
      day,
      reservations,
      roomsNightly,
    );

    const availableNights = roomsAvailabilityForDay[roomUuid] || 0;

    if (availableNights < 1) {
      blockedNights.push(day.clone());
    }
  }

  return blockedNights;
};

export const isAtLeastTwoNightsFromThursday = (
  dates: BookingDates,
): boolean => {
  if (!dates.checkIn || !dates.checkOut) return false;

  const TWO_NIGHTS = 2;

  // Calculate the number of nights
  const nights = dates.checkOut.diff(dates.checkIn, "days");

  // Dynamically check if check-in is a Thursday or later
  const isCheckInThursdayOrLater = dates.checkIn.clone().isoWeekday() >= 4; // 4 = Thursday

  // If check-in is on or after Thursday, require a minimum of two nights
  if (isCheckInThursdayOrLater) {
    return nights >= TWO_NIGHTS;
  }

  // If check-in is before Thursday, no restriction on the number of nights
  return true;
};

export const composeBookingDates = (
  selection: Moment,
  dates: BookingDates,
  blockedNights: Moment[],
): BookingDates => {
  const { checkIn, checkOut } = dates;

  let _in: Moment | undefined = checkIn?.clone();
  let _out: Moment | undefined = checkOut?.clone();

  // Case 1: Both checkIn and checkOut are already selected
  if (checkIn && checkOut) {
    // Reset both dates to start a new selection
    _in = selection.clone();
    _out = undefined;

    // Case 2: checkIn is defined but checkOut is not
  } else if (checkIn && !checkOut) {
    if (selection.isAfter(checkIn, "day")) {
      // Set checkOut to the new selection if it's after checkIn
      _out = selection.clone();
    } else {
      // If the selection is before checkIn, treat it as the new checkIn
      _in = selection.clone();
    }

    // Case 3: checkOut is defined but checkIn is not
  } else if (!checkIn && checkOut) {
    if (selection.isBefore(checkOut, "day")) {
      // Set checkIn to the new selection if it's before checkOut
      _in = selection.clone();
    } else {
      // If the selection is after checkOut, treat it as the new checkOut
      _out = selection.clone();
    }

    // Case 4: Neither checkIn nor checkOut is defined (first date selection)
  } else {
    _in = selection.clone();
  }

  // Check for disabled dates in the range between _in and _out
  if (_in && _out) {
    const rangeHasDisabledDates = blockedNights.some(
      (disabledDate) => disabledDate.isBetween(_in, _out, "day", "[]"), // Inclusive range check
    );

    if (rangeHasDisabledDates) {
      // If any disabled date is within the range, reset checkOut and set _in to the selected date
      _out = undefined;
      _in = selection.clone();
    }
  }

  return {
    checkIn: _in,
    checkOut: _out,
  };
};

export const validDate = (dates: BookingDates): boolean => {
  return Boolean(dates.checkIn) && Boolean(dates.checkOut);
};

export const getValidation = (
  dates: BookingDates,
  customer: Partial<Customer>,
) => {
  const result: Validation = {
    valid: true,
  };

  if (!validDate(dates)) {
    result.dates = "Please enter dates";
    result.valid = false;
  }

  if (validDate(dates) && !isAtLeastTwoNightsFromThursday(dates)) {
    result.dates = "A minimum of two nights is required from Thursdays";
    result.valid = false;
  }

  if (!validCustomer(customer)) {
    result.details = "Details are not complete";
    result.valid = false;
  }
  return result;
};
