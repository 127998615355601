import { Room } from "../typings/rooms";
import { get } from "./database";

export const getAvailableRooms = (): Promise<Room[]> => {
  return new Promise((resolve, reject) => {
    get("rooms")
      .then((r) => resolve(r as Room[]))
      .catch(reject);
  });
};
