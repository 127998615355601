import React from "react";
import EditCustomer from "../../../host/customers/EditCustomer";
import { Customer } from "../../../../typings/customer";
import Button from "../../../../components/Button";
import ActivityModal from "../../../../components/ActivityModal";
import { renovate } from "../../../../utils/memory";

const CustomerDetails = ({
  customer,
  onChange,
  onClose,
}: {
  customer: Partial<Customer>;
  onChange: (d: Partial<Customer>) => void;
  onClose: () => void;
}) => {
  return (
    <ActivityModal
      title={"Your details"}
      body={
        <div className={"p-6"}>
          <EditCustomer
            dryRun={true}
            onChange={onChange}
            customer={{ ...customer }}
          />
        </div>
      }
      footer={
        <Button type={"primary"} text={"Save"} block={true} onClick={onClose} />
      }
      onClose={onClose}
    />
  );
};

export default CustomerDetails;
