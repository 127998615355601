import React, { useState } from "react";
import { Menu, MenuType } from "../../../../typings/menu";
import CategoryList from "./CategoryList";
import { Cart } from "../Kitchen";
import Search from "../../../../components/Search";

interface Interface {
  cart: Cart;
  foods: Menu[];
  onClick: (food: Menu) => void;
  search?: string;
}

const FoodsList = ({ foods, cart, onClick }: Interface) => {
  const [search, setSearch] = useState("");

  const sortedAllFoods: Record<MenuType, Menu[]> = {
    MAIN_COURSE: [],
    SWALLOWS: [],
    SOUPS: [],
    PROTEIN: [],
    EXTRAS: [],
    SIDES: [],
    SMALL_CHOPS: [],
    DRINK: [],
  };

  for (let food of foods) {
    sortedAllFoods[food.type].push(food);
  }

  return (
    <div className={"space-y-6 pb-40"} id={"food-container"}>
      <div className={"grid grid-cols-2 gap-4"}>
        <div />
        <Search onChange={setSearch} />
      </div>
      {Object.keys(sortedAllFoods).map((type) => {
        const items = sortedAllFoods[type as MenuType];
        if (items.length > 0) {
          return (
            <CategoryList
              key={type}
              search={search}
              items={items}
              type={type as MenuType}
              setFood={onClick}
              cart={cart}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default FoodsList;
