import React from "react";
import { FaqType } from "../../../faq/FaqHome";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";

const HouseRules = ({
  faq,
  onClose,
}: {
  faq: FaqType;
  onClose: () => void;
}) => {
  return (
    <ActivityModal
      title={"Ground rules"}
      body={
        <div className={"space-y-3 p-9"}>
          {faq.paragraphs.map((paragraph, index) => (
            <div key={index} className={"text-md"}>
              {paragraph}
            </div>
          ))}
        </div>
      }
      footer={<Button text={"I understand"} block={true} onClick={onClose} />}
      onClose={onClose}
    />
  );
};

export default HouseRules;
