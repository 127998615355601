export const GoogleReviewLink = "https://g.page/r/CTjX2fvrUq4KEBM/review";
export const WhatsAppLink = "https://wa.me/2349067921124";
export const InstagramLink = "https://www.instagram.com/ifemideestates";
export const AirbnbLink = "https://www.airbnb.com/h/ifemideestates";
export const TwitterLink = "https://www.x.com/ifemideestates";
export const GoogleLocationLink = "https://maps.app.goo.gl/Ko8TgZZYxr7AP6Ux9";
export const FaqLink = "/faq";
export const HostLink = "/host";
export const GuestLink = "/guest";
export const TelLink = "tel:+2349067921124";
export const RitaThumbnailUrl =
  "https://a0.muscache.com/im/pictures/user/User-518640099/original/bed6062b-c13d-43b2-b60f-ad57aed9825d.jpeg?aki_policy=profile_large";
export const BotThumbnailUrl =
  "https://cdn.midjourney.com/3d01d4a9-9317-4032-8ad4-f3d50f6e23f4/grid_0_640_N.webp";
export const BUSINESS_NAME = "Ifemide Estates";
export const BUSINESS_DESCRIPTION =
  "Luxury Homes, Wellness Spa and Beauty Retreat";
export const BUSINESS_FULL_ADDRESS =
  "109/110 Sola Aladesuyi Street Behind SIB, Alagbaka GRA Extension, 340223 Akure Ondo State, Nigeria";
export const BUSINESS_EMAIL = "bookings@ifemide.com";
export const BUSINESS_PHONE = "+234 906 792 1124";
export const BUSINESS_WEBSITE = "www.ifemide.com";
export const GoogleFeedbackForm = "https://forms.gle/YVyZxNnN21d6Tnwd9";

export const RitaProfile = {
  thumbnailUrl: RitaThumbnailUrl,
  firstName: "Rita",
  middleName: "Esosa",
  lastName: "Olu-Ighama",
};

export const DEFAULT_TIMEZONE = "Africa/Lagos";

export const InstagramIconLink =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/2048px-Instagram_logo_2016.svg.png";
export const WhatsAppIconLink =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/2062095_application_chat_communication_logo_whatsapp_icon.svg/2048px-2062095_application_chat_communication_logo_whatsapp_icon.svg.png";
export const TwitterIconLink =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7fj1vqat9XLO4cFwOG1uFqLXYDcISiYil2w&s";

export const PayPalLogo =
  "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/250_Paypal_logo-512.png";

export const CreditCardLogo =
  "https://www.iconpacks.net/icons/2/free-credit-card-icon-2056-thumb.png";

export const BankTransferLogo = "https://www.svgrepo.com/show/497756/bank.svg";
