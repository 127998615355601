import React, { useEffect, useState } from "react";
import RoundPick from "./RoundPick";
import {
  BankTransferLogo,
  CreditCardLogo,
  PayPalLogo,
} from "../constants/links";

export type PayWith = "bank transfer" | "credit or debit card" | "paypal";

export type HowToPay = "full payment" | "pay at the property" | "half payment";

export const PaymentOptions: {
  title: string;
  logo: string;
  disabled?: boolean;
  type: PayWith;
}[] = [
  {
    title: "Bank transfer",
    logo: BankTransferLogo,
    type: "bank transfer",
  },
  {
    title: "Credit or debit card",
    logo: CreditCardLogo,
    disabled: true,
    type: "credit or debit card",
  },
  { title: "PayPal", logo: PayPalLogo, disabled: true, type: "paypal" },
];

const PaymentOption = ({
  setPaymentMethod,
}: {
  setPaymentMethod: (d: PayWith) => void;
}) => {
  const [payWith, setPayWith] = useState<PayWith>("bank transfer");

  useEffect(() => {
    setPaymentMethod(payWith);
  }, [payWith]);
  return (
    <div className={"space-y-3"}>
      {PaymentOptions.map((option) => (
        <button
          key={option.title}
          className={
            "p-4 flex flex-row justify-between border rounded-lg border-gray-400 w-full items-center"
          }
          style={{ opacity: option.disabled ? "50%" : "100%" }}
          disabled={option.disabled}
          onClick={() => setPayWith(option.type)}
        >
          <div className={"flex flex-row space-x-3 items-center"}>
            <img
              alt={"Payment"}
              className={"w-6 aspect-square object-cover overflow-hidden"}
              src={option.logo}
            />
            <p>{option.title}</p>
          </div>
          <RoundPick
            selected={payWith === option.type}
            disabled={option.disabled}
          />
        </button>
      ))}
    </div>
  );
};

export default PaymentOption;
