import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import FullPage from "../../components/FullPage";
import { EnergyRecord } from "../../typings/energy";
import { get } from "../../api/database";
import { AxiosError } from "axios";
import MonthRun from "./runs/MonthRun";

type View = "Runs" | "Stock" | "Orders";

const EnergyDashboard = () => {
  const [searchParams] = useSearchParams();

  const currentMonth = moment().month() + 1;
  const initialMonth =
    searchParams.get("month") !== null
      ? Math.min(Math.max(parseInt(searchParams.get("month")!, 10), 1), 12)
      : currentMonth;

  const [year] = useState(() => {
    const yearParam = searchParams.get("year");
    return yearParam !== null ? parseInt(yearParam, 10) : moment().year();
  });
  const [month] = useState(initialMonth);

  const [, setRecords] = useState<EnergyRecord>({});
  const [error, setError] = useState<AxiosError | any>();
  const [loading, setLoading] = useState(true);
  const [currentView, setCurrentView] = useState<View>("Runs");

  useEffect(() => {
    setLoading(true);
    get<EnergyRecord>(`records?year=${year}&month=${month}`)
      .then(setRecords)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [month, year]);

  const views: View[] = ["Runs", "Stock", "Orders"];

  const renderView = () => {
    switch (currentView) {
      case "Runs":
        return <MonthRun runs={{}} month={month} year={year} />;
      case "Orders":
        return <div>{currentView}</div>;
      case "Stock":
        return <div>{currentView}</div>;
    }
  };

  return (
    <FullPage
      title={"ENERGY"}
      loading={loading}
      error={error}
      component={
        <div className={"space-y-3"}>
          <div className={"flex flex-row space-x-6 items-center"}>
            {views.map((view) => (
              <button
                key={view}
                className={`border-b-4  ${view === currentView ? "border-pink-500" : "border-transparent"} `}
                onClick={() => setCurrentView(view)}
              >
                {view}
              </button>
            ))}
          </div>
          <div className={"bg-white rounded-xl shadow overflow-hidden"}>
            {renderView()}
          </div>
        </div>
      }
    />
  );
};

export default EnergyDashboard;
