import React, { useState } from "react";

interface CarouselModalProps {
  images: string[]; // Array of image URLs
  startIndex: number; // The index to start the carousel from
  onClose: () => void; // Close modal callback
}

const CarouselModal = ({ images, startIndex, onClose }: CarouselModalProps) => {
  const [currentIndex, setCurrentIndex] = useState(startIndex);

  // Go to the previous image
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  // Go to the next image
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  // Handle swipe (left or right)
  const handleSwipe = (event: React.TouchEvent) => {
    const touchStart = event.touches[0].clientX;

    // Handle touchend event to detect swipe
    const handleTouchEnd = (e: TouchEvent) => {
      const touchEnd = e.changedTouches[0].clientX;
      if (touchStart - touchEnd > 50) {
        goToNext(); // Swipe left -> Next image
      } else if (touchStart - touchEnd < -50) {
        goToPrevious(); // Swipe right -> Previous image
      }
      document.removeEventListener("touchend", handleTouchEnd); // Clean up
    };

    // Attach touchend event listener
    document.addEventListener("touchend", handleTouchEnd);
  };

  return (
    <div className="fixed inset-0 bg-black flex items-center justify-center z-50">
      {/* Close button */}
      <div className="absolute top-4 right-4 z-50 cursor-pointer">
        <button
          onClick={onClose}
          className="bg-white bg-opacity-50 text-black text-4xl rounded-full w-12 h-12 flex items-center justify-center hover:bg-opacity-75 transition duration-200"
        >
          <i className="bx bx-x"></i>
        </button>
      </div>

      {/* Image container */}
      <div
        className="relative w-full h-full flex justify-center items-center"
        onTouchStart={handleSwipe} // Enable swipe handling
      >
        {/* Left arrow */}
        <button
          className="absolute left-4 text-black text-4xl z-10 bg-white bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center hover:bg-opacity-75 transition duration-200"
          onClick={goToPrevious}
        >
          <i className="bx bx-left-arrow-alt"></i>
        </button>

        {/* Image */}
        <img
          src={images[currentIndex]}
          alt={`Full-size view ${currentIndex + 1}`}
          className="max-w-full max-h-full object-contain"
        />

        {/* Right arrow */}
        <button
          className="absolute right-4 text-black text-4xl z-10 bg-white bg-opacity-50 rounded-full w-12 h-12 flex items-center justify-center hover:bg-opacity-75 transition duration-200"
          onClick={goToNext}
        >
          <i className="bx bx-right-arrow-alt"></i>
        </button>

        {/* Cue Circles */}
        {/* Fixing the position of cue balls at the bottom */}
        <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {images.map((_, index) => (
            <button
              key={index}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                currentIndex === index
                  ? "bg-pink-500" // Active circle with pink color
                  : "bg-white bg-opacity-50" // Inactive circles with white and low opacity
              }`}
              onClick={() => setCurrentIndex(index)} // Navigate to specific image
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselModal;
