import React from "react";
import EditCustomer from "./EditCustomer";
import FullPage from "../../../components/FullPage";

const Customers = () => {
  return (
    <FullPage
      bannerUrl={
        "https://african.business/wp-content/uploads/2020/04/shutterstock_333848435.jpg"
      }
      back={"/host"}
      title={"MANAGE CUSTOMERS"}
      component={
        <div className={"space-y-6 p-6 bg-white rounded-lg shadow"}>
          <EditCustomer />
        </div>
      }
    />
  );
};

export default Customers;
