import React from "react";
import { Img, Section, Text } from "@react-email/components";
import { RitaThumbnailUrl } from "../../constants/links";
import { Contact } from "./IfemideFooter";

const HostSignature = () => {
  return (
    <Section>
      <Text style={{ margin: 0 }} className={""}>
        Warm regards
      </Text>
      <Img
        alt={"Ifemide Host Thumbnail Photo"}
        height={50}
        width={50}
        className={
          "filter brightness-200 contrast-500 contain-content rounded-full m-0 p-0"
        }
        src={RitaThumbnailUrl}
      ></Img>
      <Text className={"m-0 p-0 mb-0 font-extrabold"}>Rita Esosa</Text>
      <Text className={"m-0 p-0 mb-0 text-pink-500"}>
        Guest Experience Host
      </Text>
      <Contact />
    </Section>
  );
};

export default HostSignature;
