import React, { useEffect, useState } from "react";
import ListInput from "../../../../components/ListInput";
import EditCustomer from "../../customers/EditCustomer";
import { Customer } from "../../../../typings/customer";
import { getCustomers } from "../../../../api/customers";

enum CustomerView {
  SELECT,
  EDIT_OR_ADD,
}

const DEFAULT_CUSTOMER_VIEW = CustomerView.SELECT;

const SelectCustomer = (props: {
  customer?: Customer;
  setCustomer: (customer: Customer) => void;
  validate?: boolean;
}) => {
  const [customerView, setCustomerView] = useState(DEFAULT_CUSTOMER_VIEW);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customer, setCustomer] = useState<Customer | undefined>(
    props.customer,
  );

  const [errors, setErrors] = useState<Partial<{ customer: string }>>({
    customer: undefined,
  });

  const customersMap = new Map(
    customers.map((customer) => [customer.uuid, customer]),
  );

  function handleCustomerChange(id: string): void {
    if (customersMap.has(id)) setCustomer(customersMap.get(id) as Customer);
  }

  const customerListView: {
    id: string;
    title: string;
    thumbnailUrl: string;
  }[] = customers.map((customer) => ({
    id: customer.uuid,
    title: `${customer.salutation} ${customer.firstName} ${customer.lastName}`,
    thumbnailUrl: "",
  }));

  useEffect(() => {
    if (customer) props.setCustomer(customer);
  }, [customer, props]);

  useEffect(() => {
    getCustomers()
      .then(setCustomers)
      .catch(() => {
        window.alert("Failed to get customers list");
      });
  }, [customerView]);

  useEffect(() => {
    if (props.validate) {
      setErrors({ customer: !customer ? "Please select customer" : undefined });
    } else {
      setErrors({});
    }
  }, [customer, props.validate]);

  return (
    <div
      className={"rounded-2xl border p-6 flex flex-col mt-6 bg-white shadow"}
    >
      <div className={"flex flex-row justify-between items-center my-2"}>
        <div className={"flex flex-row"}>
          <p
            onClick={() => setCustomerView(CustomerView.SELECT)}
            className={`${customerView === CustomerView.SELECT ? "" : "text-gray-500"}`}
          >
            Select
          </p>
          <p className={"mx-2"}>&middot;</p>
          <p
            className={`${customerView === CustomerView.EDIT_OR_ADD ? "" : "text-gray-500"}`}
            onClick={() => setCustomerView(CustomerView.EDIT_OR_ADD)}
          >
            Customers
          </p>
        </div>
      </div>
      {customerView === CustomerView.SELECT ? (
        <ListInput
          titleSelector={"title"}
          thumbnailUrlSelector={"thumbnailUrl"}
          error={errors.customer}
          onChange={handleCustomerChange}
          keySelector={"id"}
          showSearch={true}
          icon={"bx bx-body"}
          placeholder={"Customer"}
          selectedId={customer?.uuid}
          list={customerListView}
        />
      ) : (
        <EditCustomer />
      )}
    </div>
  );
};

export default SelectCustomer;
