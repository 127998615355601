import React from "react";
import { InvoiceView } from "../pages/guest/invoice/InvoiceView";
import { Invoice } from "../typings/reservation";
import MailSetup from "./shared/MailSetup";

const messages: string[] = [
  "Hi there! We hope you enjoyed your stay at Ifemide Estates! Your invoice is attached—give it a look when you can!",
  "Hello, fabulous guest! Just a friendly nudge to let you know your invoice from Ifemide Estates is ready for your review. Enjoy reminiscing!",
  "Greetings! We hope your time at Ifemide Estates was delightful! Attached is your invoice—check it out at your leisure!",
  "Hi! We hope you had a blast during your stay at Ifemide Estates! Your invoice is attached—feel free to browse through it!",
  "Hello! We trust you had a lovely experience at Ifemide Estates! Your invoice is all set and attached for your review!",
  "Hope your adventure at Ifemide Estates was fantastic! Attached is your invoice—take a peek when you get a chance!",
  "Hello, lovely guest! We hope you made some great memories at Ifemide Estates! Your invoice is attached—give it a glance!",
  "Hi! We hope your stay at Ifemide Estates was just as wonderful as you are! Your invoice is ready and waiting—take a look!",
  "Greetings from Ifemide Estates! We hope you had a marvelous time! Your invoice is attached for your review—enjoy!",
  "Hey there! We hope your stay at Ifemide Estates was fabulous! Your invoice is attached—have a look when you get a moment!",
];

const InvoiceIsReady = ({
  invoice,
  customerName,
  checkInDate,
  checkOutDate,
}: {
  invoice: Invoice;
  customerName: string;
  checkInDate: string;
  checkOutDate: string;
}) => {
  return (
    <MailSetup
      previewText={messages[Math.floor(Math.random() * messages.length)]}
      component={
        <InvoiceView
          invoice={invoice}
          customerName={customerName}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
        />
      }
    />
  );
};

export default InvoiceIsReady;
