import moment from "moment-timezone";
import { BookingDates } from "../Reservations";
import { ExtendedGuests } from "../../../../typings/reservation";
import {
  NewCustomerData,
  NewReservationData,
} from "../pages/CompleteReservation";
import { Customer } from "../../../../typings/customer";

export const buildReservation = (
  roomUuid: string,
  amountPaid: number,
  grandTotal: number,
  coupon: number,
  dates: BookingDates,
  guests: ExtendedGuests,
): NewReservationData => {
  return {
    bookings: [
      {
        checkInDateTime: moment(dates.checkIn!!)
          .tz("Africa/Lagos")
          .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
          .format(),
        checkOutDateTime: moment(dates.checkOut!!)
          .tz("Africa/Lagos")
          .set({ hour: 12, minute: 0, second: 0, millisecond: 0 })
          .format(),
        roomUuid: roomUuid,
        guests: {
          adults: guests.adults,
          children: guests.children,
        },
      },
    ],
    paymentInfo: {
      paid: amountPaid,
      deposit: 0,
      due: Math.max(0, grandTotal - amountPaid),
      total: grandTotal,
      discount: coupon,
      currency: "NGN",
    },
    specialRequests: [
      "Pets: " + guests.pets,
      "Infant " + guests.infants,
      "Children " + guests.children,
    ],
  };
};

export const validCustomer = (customer: Partial<Customer>): boolean => {
  return (
    Boolean(customer.salutation) &&
    Boolean(customer.firstName) &&
    Boolean(customer.lastName) &&
    Boolean(customer.phoneNumberLine1) &&
    Boolean(customer.addressLine1) &&
    Boolean(customer.addressLine2)
  );
};

export const buildCustomer = (customer: Partial<Customer>): NewCustomerData => {
  return {
    salutation: customer.salutation || "",
    firstName: customer.firstName || "",
    lastName: customer.lastName || "",
    phoneNumberLine1: customer.phoneNumberLine1 || "",
    phoneNumberLine2: customer.phoneNumberLine2 || "",
    emailAddress: customer.emailAddress || "",
    addressLine1: customer.addressLine1 || "",
    addressLine2: customer.addressLine2 || "",
    thumbnailUrl: customer.thumbnailUrl || "",
  };
};

export const getNights = (dates: BookingDates) => {
  if (!dates.checkIn || !dates.checkOut) {
    return 0;
  }
  return dates.checkOut.diff(dates.checkIn, "days");
};
