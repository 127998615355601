import React, { useState, useEffect, useRef } from "react";

interface CarouselProps {
  images: string[]; // Array of image URLs
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Navigate to the previous image
  const goToPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  // Navigate to the next image
  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  // Swipe handling
  const handleSwipe = (event: React.TouchEvent) => {
    const touchStart = event.touches[0].clientX;

    const handleTouchEnd = (e: TouchEvent) => {
      const touchEnd = e.changedTouches[0].clientX;
      if (touchStart - touchEnd > 50) {
        goToNext(); // Swipe left -> Next image
      } else if (touchStart - touchEnd < -50) {
        goToPrevious(); // Swipe right -> Previous image
      }
      document.removeEventListener("touchend", handleTouchEnd);
    };

    document.addEventListener("touchend", handleTouchEnd);
  };

  // Adjusts currentIndex based on available images
  useEffect(() => {
    if (currentIndex >= images.length) {
      setCurrentIndex(0);
    }
  }, [images.length, currentIndex]);

  return (
    <div ref={containerRef} className="relative w-full h-full overflow-hidden">
      {/* Left Arrow */}
      <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-3xl rounded-full p-2 hover:bg-opacity-75 transition duration-200"
        onClick={goToPrevious}
      >
        <i className="bx bx-left-arrow-alt"></i>
      </button>

      {/* Image container */}
      <div
        className="w-full h-full flex justify-center items-center"
        onTouchStart={handleSwipe}
      >
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex + 1}`}
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Arrow */}
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-3xl  rounded-full p-2 hover:bg-opacity-75 transition duration-200"
        onClick={goToNext}
      >
        <i className="bx bx-right-arrow-alt"></i>
      </button>

      {/* Cue Circles */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {images.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 rounded-full transition-all duration-300 ${
              currentIndex === index
                ? "bg-pink-500" // Active cue ball color
                : "bg-white bg-opacity-50" // Inactive cue ball color
            }`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
