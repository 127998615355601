import React, { useEffect, useState } from "react";
import { View, views } from "../../home/Home";
import { Contemporary, Minimalistic } from "../../home/config/Rooms";
import Carousel from "../../../../components/Carousel";
import ListInput from "../../../../components/ListInput";
import Button from "../../../../components/Button";
import { RoomConfig } from "../../home/pages/RoomView";
import ActivityModal from "../../../../components/ActivityModal";

const RoomSelection = ({
  view,
  onChange,
  onClose,
  beds = 4,
}: {
  view: View;
  beds: number;
  onChange: (d: {
    bedrooms: number;
    view: View;
    room: RoomConfig;
    roomUuid: string;
  }) => void;
  onClose: () => void;
}) => {
  const [currentView, setCurrentView] = useState<View>(view);
  const [bedrooms, setBedrooms] = useState(String(beds));

  const [room, setRoom] = useState(Contemporary);

  const rooms = [
    {
      id: "2",
      title: "Two bedrooms",
      uuid: "a277f60a-791d-40db-83b6-317991b9bd4f",
    },
    {
      id: "3",
      title: "Three bedrooms",
      uuid: "5579d88d-77d9-4b60-94ec-0ce912893da3",
    },
    {
      id: "4",
      title: "Four bedrooms",
      uuid: "e6474788-4756-4b04-b842-3b8a2f37de7e",
    },
  ];

  function onSave() {
    onChange({
      bedrooms: Number(bedrooms),
      view: currentView,
      room,
      roomUuid: rooms.find((r) => r.id === bedrooms)!!.uuid,
    });
    onClose();
  }

  useEffect(() => {
    if (currentView === "Contemporary") setRoom(Contemporary);
    else setRoom(Minimalistic);
  }, [currentView]);

  return (
    <ActivityModal
      title={"Room selection"}
      body={
        <div>
          <div
            className={"w-fit overflow-hidden border rounded-xl mx-auto my-3"}
          >
            {views.map((view) => (
              <button
                key={view.view}
                onClick={() => setCurrentView(view.view)}
                className={`py-3 px-6 ${currentView === view.view ? "bg-pink-500 text-white" : ""}`}
              >
                {view.view}
              </button>
            ))}
          </div>
          <div className={"grid grid-cols-2 gap-4 items-center p-9"}>
            <p className={"w-3/4 font-bold"}>Select number of rooms</p>
            <ListInput
              selectedId={bedrooms}
              keySelector={"id"}
              titleSelector={"title"}
              onChange={setBedrooms}
              list={rooms}
            />
          </div>
          <div className={"w-full aspect-square overflow-x-hidden"}>
            <Carousel images={room.photos.map((photo) => photo.url)} />
          </div>
        </div>
      }
      footer={
        <Button type={"primary"} text={"Save"} onClick={onSave} block={true} />
      }
      onClose={onClose}
    />
  );
};

export default RoomSelection;
