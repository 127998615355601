import React, { useEffect, useState } from "react";

const Search = ({ onChange }: { onChange: (d: string) => void }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div
      className={"flex flex-row px-3 py-1 rounded-full bg-white items-center"}
    >
      <i className={"bx bx-search mr-1"}></i>
      <input
        onChange={(e) => setValue(e.target.value)}
        className={"w-full focus:outline-none h-6"}
        placeholder={"Search"}
        value={value}
      />
      {value && (
        <button onClick={() => setValue("")}>
          <i className={"bx bx-x"}></i>
        </button>
      )}
    </div>
  );
};
export default Search;
