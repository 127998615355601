import React, { useEffect, useMemo, useState } from "react";
import { Customer, CustomerDto } from "../../../typings/customer";
import {
  createCustomer,
  getCustomers,
  patchCustomer,
} from "../../../api/customers";
import ListInput from "../../../components/ListInput";
import FormEntry from "../../../components/FormEntry";
import Button from "../../../components/Button";
import {
  isValidDate,
  isValidEmail,
  isValidName,
  isValidPhoneNumber,
} from "../../../utils/validation";
import BotChat from "../../../components/BotChat";
import { BotThumbnailUrl } from "../../../constants/links";
import { AxiosError } from "axios";
import { CustomerInfo, storeInfo } from "../../../utils/memory";
import { data } from "autoprefixer";

interface Props {
  customerId?: string;
  dryRun?: boolean;
  onChange?: (data: Partial<Customer>) => void;
  customer?: Partial<Customer>;
}

const EditCustomer = (props: Props) => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customer, setCustomer] = useState<Customer | undefined>();

  const [salutation, setSalutation] = useState(customer?.salutation || "");
  const [firstName, setFirstName] = useState(customer?.firstName || "");
  const [lastName, setLastName] = useState(customer?.lastName || "");
  const [phoneNumberLine1, setPhoneNumberLine1] = useState(
    customer?.phoneNumberLine1 || "",
  );
  const [phoneNumberLine2, setPhoneNumberLine2] = useState(
    customer?.phoneNumberLine2 || "",
  );
  const [emailAddress, setEmailAddress] = useState(
    customer?.emailAddress || "",
  );
  const [addressLine1, setAddressLine1] = useState(
    customer?.addressLine1 || "",
  );
  const [addressLine2, setAddressLine2] = useState(
    customer?.addressLine2 || "",
  );

  const [anniversaryDate, setAnniversaryDate] = useState<string>(
    customer?.anniversaryDate || "",
  );
  const [dateOfBirth, setDateOfBirth] = useState<string>(
    customer?.dateOfBirth || "",
  );

  const [instagramUsername, setInstagramUsername] = useState<string>(
    customer?.instagramUsername || "",
  );

  const [facebookUsername, setFacebookUsername] = useState<string>(
    customer?.facebookUsername || "",
  );

  const [twitterXUsername, setTwitterXUsername] = useState<string>(
    customer?.twitterXUsername || "",
  );

  const [working, setWorking] = useState(true);

  const customersMapById = useMemo(() => {
    return new Map(customers.map((customer) => [customer.uuid, customer]));
  }, [customers]);

  function handleCancel() {
    if (working) {
      window.alert("Loading...");
      return;
    }
    setCustomer(undefined);
    renovate();
  }

  async function handleSave() {
    if (working) {
      window.alert("Loading...");
      return;
    }

    if (!salutation) {
      window.alert("A salutation is required.");
      return;
    }

    if (!isValidName(firstName)) {
      window.alert("A valid first name is required.");
      return;
    }

    if (!isValidName(lastName)) {
      window.alert("A valid first name is required.");
      return;
    }

    if (!isValidEmail(emailAddress)) {
      window.alert("A valid email is required.");
      return;
    }

    if (!isValidPhoneNumber(phoneNumberLine1)) {
      window.alert("A valid phone number line 1 is required.");
      return;
    }

    if (
      phoneNumberLine2.trim() !== "" &&
      !isValidPhoneNumber(phoneNumberLine2)
    ) {
      window.alert("A valid phone number line 2 is required.");
      return;
    }

    if (!addressLine1) {
      window.alert("A valid address line 1 is required.");
      return;
    }

    if (!addressLine2) {
      window.alert("A valid address line 2 is required.");
      return;
    }

    if (dateOfBirth && !isValidDate(dateOfBirth.trim())) {
      window.alert("Invalid date of birth");
      return;
    }

    if (anniversaryDate && !isValidDate(anniversaryDate.trim())) {
      window.alert("Invalid anniversary date");
      return;
    }

    setWorking(true);

    let customerData: CustomerDto = {
      addressLine1,
      addressLine2,
      emailAddress,
      firstName,
      lastName,
      phoneNumberLine1,
      phoneNumberLine2,
      dateOfBirth: dateOfBirth?.trim(),
      anniversaryDate: anniversaryDate?.trim(),
      salutation,
      thumbnailUrl: "",
      instagramUsername,
      facebookUsername,
      twitterXUsername,
    };

    if (customer) {
      await patchCustomer({ ...customer, ...customerData })
        .then(async (updatedCustomerData) => {
          setCustomer(updatedCustomerData);
          customersMapById.set(customer.uuid, updatedCustomerData);
          setCustomers(Array.from(customersMapById.values()));
        })
        .catch((e: AxiosError) => {
          window.alert(
            e.message || "Could not complete action, please try again later.",
          );
        });
    } else {
      await createCustomer(customerData)
        .then(async (newCustomer) => {
          setCustomer(newCustomer);
          customersMapById.set(newCustomer.uuid, newCustomer);
          setCustomers(Array.from(customersMapById.values()));
        })
        .catch((e: AxiosError) => {
          window.alert(
            e.message || "Could not complete action, please try again later.",
          );
        });
    }

    setWorking(false);
  }

  function handleCustomerChange(id: string) {
    if (customersMapById.has(id)) setCustomer(customersMapById.get(id));
  }

  function renovate(customer?: Customer) {
    setSalutation(props.customer?.salutation || customer?.salutation || "");
    setFirstName(props.customer?.firstName || customer?.firstName || "");
    setLastName(props.customer?.lastName || customer?.lastName || "");
    setEmailAddress(
      props.customer?.emailAddress || customer?.emailAddress || "",
    );
    setPhoneNumberLine1(
      props.customer?.phoneNumberLine1 || customer?.phoneNumberLine1 || "",
    );
    setPhoneNumberLine2(
      props.customer?.phoneNumberLine2 || customer?.phoneNumberLine2 || "",
    );
    setAddressLine1(
      props.customer?.addressLine1 || customer?.addressLine1 || "",
    );
    setAddressLine2(
      props.customer?.addressLine2 || customer?.addressLine2 || "",
    );
    setDateOfBirth(props.customer?.dateOfBirth || customer?.dateOfBirth || "");
    setAnniversaryDate(
      props.customer?.anniversaryDate || customer?.anniversaryDate || "",
    );

    setInstagramUsername(
      props.customer?.instagramUsername || customer?.instagramUsername || "",
    );
    setFacebookUsername(
      props.customer?.facebookUsername || customer?.facebookUsername || "",
    );
    setTwitterXUsername(
      props.customer?.twitterXUsername || customer?.twitterXUsername || "",
    );
  }

  useEffect(() => {
    if (!props.dryRun) {
      setWorking(true);
      getCustomers()
        .then(setCustomers)
        .catch((e) => {
          window.alert(
            e.message || "Could not complete action, please try again later.",
          );
        });
    }
  }, [props.dryRun]);

  useEffect(() => {
    if (
      !props.dryRun &&
      props.customerId &&
      customersMapById.has(props.customerId)
    ) {
      const newVar = customersMapById.get(props.customerId) as Customer;
      setCustomer(newVar);
      renovate(newVar);
    }
    setWorking(false);
  }, [customer, customersMapById, props.customerId, props.dryRun]);

  useEffect(() => {
    renovate(customer);
  }, [customer]);

  useEffect(() => {
    if (props.onChange) {
      const data: Partial<Customer> = {
        salutation,
        firstName,
        lastName,
        emailAddress,
        phoneNumberLine1,
        phoneNumberLine2,
        addressLine1,
        addressLine2,
      };
      props.onChange(data);
      storeInfo({
        firstName: "",
        lastName: "",
        phoneNumberLine1: "",
        emailAddress: "",
        ...data,
      });
    }
  }, [
    addressLine1,
    addressLine2,
    emailAddress,
    firstName,
    lastName,
    phoneNumberLine1,
    phoneNumberLine2,
    salutation,
  ]);

  return (
    <div className={"flex flex-col"}>
      {!props.dryRun && (
        <ListInput<Customer & { title: string }>
          onChange={handleCustomerChange}
          keySelector={"uuid"}
          showSearch={true}
          icon={"bx bx-body"}
          placeholder={"Select existing customers to edit"}
          selectedId={customer?.uuid}
          titleSelector={"title"}
          list={customers.map((customer: Customer) => ({
            ...customer,
            title: `${customer.salutation} ${customer.firstName} ${customer.lastName}`,
          }))}
        />
      )}
      <FormEntry
        placeholder={"Captain"}
        title={"Salutation*"}
        value={salutation}
        type={"text"}
        defaultValue={salutation}
        onChange={setSalutation}
      />
      <div className={"grid-cols-2 grid gap-4"}>
        <FormEntry
          placeholder={"Demurin Peter"}
          title={"First names*"}
          value={firstName}
          type={"name"}
          defaultValue={firstName}
          onChange={setFirstName}
        />
        <FormEntry
          placeholder={"Bucknor"}
          title={"Last name*"}
          value={lastName}
          type={"name"}
          defaultValue={lastName}
          onChange={setLastName}
        />
      </div>
      <FormEntry
        title={"Email address*"}
        placeholder={"captaindemurinbucknor@gmail.com"}
        value={emailAddress}
        defaultValue={emailAddress}
        type={"email"}
        onChange={setEmailAddress}
      />
      <div className={"grid-cols-2 grid gap-4"}>
        <FormEntry
          title={"Phone line 1*"}
          placeholder={"+23407061047490"}
          value={phoneNumberLine1}
          type={"phone"}
          defaultValue={phoneNumberLine1}
          onChange={setPhoneNumberLine1}
        />
        <FormEntry
          title={"Phone line 2"}
          placeholder={"+23407061047490"}
          value={phoneNumberLine2}
          type={"phone"}
          defaultValue={phoneNumberLine2}
          onChange={setPhoneNumberLine2}
        />
      </div>
      <FormEntry
        title={"Address line 1*"}
        placeholder={"109, Parkview Street"}
        value={addressLine1}
        defaultValue={addressLine1}
        type={"address"}
        onChange={setAddressLine1}
      />
      <FormEntry
        title={"Address line 2*"}
        placeholder={"Ikoyi, Lagos Nigeria"}
        value={addressLine2}
        defaultValue={addressLine2}
        type={"postcode"}
        onChange={setAddressLine2}
      />
      {!props.dryRun && (
        <>
          <p className={"italic text-gray-500"}>
            Please use any year if the year is not known, we only need the month
            and the date for automatic wishes via email.
          </p>
          <div className={"grid grid-cols-2 gap-4"}>
            <FormEntry
              title={"Date of birth"}
              placeholder={"1990-10-31"}
              value={dateOfBirth}
              defaultValue={dateOfBirth}
              type={"text"}
              onChange={setDateOfBirth}
            />
            <FormEntry
              title={"Anniversary date"}
              placeholder={"1999-09-01"}
              value={anniversaryDate}
              defaultValue={anniversaryDate}
              type={"text"}
              onChange={setAnniversaryDate}
            />
          </div>
          <div className={"grid grid-cols-2 gap-4"}>
            <FormEntry
              title={"Instagram username"}
              placeholder={"cptdemurin"}
              value={instagramUsername}
              type={"text"}
              onChange={setInstagramUsername}
            />
            <FormEntry
              title={"Facebook username"}
              placeholder={"cptdemurin"}
              value={facebookUsername}
              type={"text"}
              onChange={setFacebookUsername}
            />
          </div>
          <div className={"grid grid-cols-2"}>
            <FormEntry
              title={"X username"}
              placeholder={"cptdemurin"}
              value={twitterXUsername}
              type={"text"}
              onChange={setTwitterXUsername}
            />
          </div>
        </>
      )}
      {!props.dryRun && (
        <div className={"flex flex-row justify-end space-x-3"}>
          <Button
            disabled={working}
            onClick={handleSave}
            type={"primary"}
            text={"Save"}
          />
          <Button
            disabled={working}
            onClick={handleCancel}
            type={"secondary"}
            text={"Clear"}
          />
        </div>
      )}
      {working && (
        <div className="absolute top-0 bottom-0 right-0 left-0 flex flex-col items-center justify-center space-y-3">
          <BotChat
            text={"text-sm"}
            delays={1000000}
            profilePhoto={BotThumbnailUrl}
            messages={[{ content: "Taking longer than usual..." }]}
          />
        </div>
      )}
    </div>
  );
};

export default EditCustomer;
