import React from "react";
import Lottie from "lottie-react";

import cameraAnimation from "./Animation - 1731104271402.json";
import walkingAnimation from "./Animation-Walking.json";
import completedAnimation from "./Animation-Completed.json";
import starAnimation from "./Animation-Star.json";
import errorAnimation from "./Animation-Error.json";
import cookingAnimation from "./Animation-Cooking.json";

type animation =
  | "camera"
  | "walking"
  | "completed"
  | "star"
  | "error"
  | "cooking";

const LottieAnimation = ({
  type = "camera",
  className = "w-20 h-20",
  loop = true,
}: {
  className?: string;
  type?: animation;
  loop?: boolean;
}) => {
  const animations: Record<animation, any> = {
    camera: cameraAnimation,
    walking: walkingAnimation,
    completed: completedAnimation,
    star: starAnimation,
    error: errorAnimation,
    cooking: cookingAnimation,
  };
  return (
    <Lottie
      animationData={animations[type]} // Pass the Lottie JSON data
      loop={loop} // Set looping to true for continuous animation
      autoplay={true} // Set autoplay to true to start immediately
      className={className}
    />
  );
};

export default LottieAnimation;
