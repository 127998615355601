import React, { useCallback, useEffect, useState } from "react";
import { Customer } from "../../../../typings/customer";
import { AxiosError } from "axios";
import { patch, post } from "../../../../api/database";
import { Reservation } from "../../../../typings/reservation";
import { Room } from "../../../../typings/rooms";
import { render } from "@react-email/components";
import ConfirmedReservations from "../../../../emails/ConfirmedReservations";
import { sendEmail } from "../../../../api/email";
import Loader from "../../../../components/Loader";
import LottieAnimation from "../../../../components/lottie/LottieAnimation";
import Button from "../../../../components/Button";
import { useNavigate } from "react-router-dom";
import OrderResult from "../../../../components/OrderResult";
import ActivityModal from "../../../../components/ActivityModal";
import { toastAxiosError } from "../../../../utils/toast";

export type NewCustomerData = Omit<
  Customer,
  "uuid" | "updatedAt" | "createdAt"
>;
export type NewReservationData = Omit<
  Reservation,
  "id" | "status" | "tags" | "customerUuid" | "updatedAt" | "createdAt"
>;

type NewReservationDto = Omit<Reservation, "id" | "updatedAt" | "createdAt">;

export interface Interface {
  customer: NewCustomerData;
  reservation: NewReservationData;
  room: Room;
}

const CompleteReservation = ({ room, customer, reservation }: Interface) => {
  const [customerData, setCustomerData] = useState<Customer>();

  const [working, setWorking] = useState(false);
  const [error, setError] = useState<AxiosError>();

  const navigate = useNavigate();

  const [finalReservation, setFinalReservation] =
    useState<Pick<Reservation, "id">>();

  const getUser = useCallback(async (): Promise<Customer> => {
    return new Promise(async (resolve, reject) => {
      if (customerData) {
        resolve(customerData);
        return;
      }
      await post<NewCustomerData, Customer>("customers", customer)
        .then(resolve)
        .catch(async (e: AxiosError) => {
          if (e.isAxiosError && e.response) {
            if (e.response?.data) {
              const { message = "" } = e.response.data as { message: string };

              const strings = message.split("uuid=");
              if (strings.length > 1) {
                await patch<NewCustomerData & { uuid: string }, Customer>(
                  "customers",
                  {
                    uuid: strings[1],
                    ...customer,
                  },
                )
                  .then(resolve)
                  .catch(reject);
              } else reject();
            } else reject();
          } else reject();
        });
    });
  }, [customerData, customer]); // `customerData` and `customer` are dependencies

  useEffect(() => {
    if (!customerData) getUser().then(setCustomerData).catch(console.log);
  }, [customerData, getUser]);

  useEffect(() => {
    if (customerData && !working) {
      setWorking(true);
      const reservationData: NewReservationDto = {
        ...reservation,
        customerUuid: customerData.uuid,
        tags: [
          customerData.firstName + " " + customerData.lastName,
          room.title,
        ],
        status: "CONFIRMED",
      };

      post<NewReservationData, Reservation>("reservations", reservationData)
        .then((data) => {
          const rooms: Map<string, Room> = new Map();
          rooms.set(room.uuid, room);
          render(
            <ConfirmedReservations
              reservation={data}
              customer={customerData}
              rooms={rooms}
            />,
          )
            .then((html) => {
              sendEmail({
                to: customerData.emailAddress,
                html,
                subject: "Reservation Confirmation",
              })
                .then(() => setFinalReservation(data))
                .catch(setError);
            })
            .catch(setError);
        })
        .catch(setError);
    }
  }, [customerData, reservation, room, room.title, working]);

  useEffect(() => {
    if (error) toastAxiosError(error);
  }, [error]);

  return (
    <ActivityModal
      title={"Reservation"}
      body={
        <>
          {!finalReservation && (
            <div>
              <LottieAnimation
                type={"walking"}
                className={"w-full aspect-square"}
              />
              <Loader />
            </div>
          )}
          {finalReservation && (
            <div className={""}>
              <LottieAnimation
                type={"completed"}
                className={"w-full aspect-square"}
              />
              <div className={"px-9 space-y-3"}>
                <OrderResult
                  id={String(finalReservation.id)}
                  title={"Booking Completed!"}
                  subtitle={"YOUR BOOKING ID"}
                />
                <Button
                  type={"primary"}
                  text={"GO HOME"}
                  onClick={() => navigate("/")}
                  block={true}
                />
              </div>
            </div>
          )}
        </>
      }
      footer={""}
      noClose={true}
    />
  );
};

export default CompleteReservation;
