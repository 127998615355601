import { CreateDto, Reservation } from "../typings/reservation";
import { get, patch, post } from "./database";

const RESOURCE_PATH = "reservations";

export const getReservations = (): Promise<Reservation[]> => {
  return new Promise(async (resolve, reject) => {
    await get(RESOURCE_PATH)
      .then((r) => resolve(r as Reservation[]))
      .catch(reject);
  });
};

export const getReservation = (id: number): Promise<Reservation> => {
  return new Promise(async (resolve, reject) => {
    await get(`${RESOURCE_PATH}/${id}`)
      .then((r) => resolve(r as Reservation))
      .catch(reject);
  });
};

export const createReservation = (
  reservation: CreateDto<Reservation>,
): Promise<Reservation> => {
  return new Promise(async (resolve, reject) => {
    await post(RESOURCE_PATH, reservation)
      .then((r) => resolve(r as Reservation))
      .catch(reject);
  });
};

export const updateReservation = (
  reservation: CreateDto<Reservation>,
): Promise<Reservation> => {
  return new Promise(async (resolve, reject) => {
    await patch(RESOURCE_PATH, reservation)
      .then((r) => resolve(r as Reservation))
      .catch(reject);
  });
};
