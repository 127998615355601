import React, { useState } from "react";
import moment, { Moment } from "moment";
import { Run } from "../../../typings/energy";
import { v4 } from "uuid";
import DoubleClickButton from "../../../components/DoubleClickButton";

const DayRun = ({ day }: { day: Moment }) => {
  const [runs, setRuns] = useState<Run[]>([]);

  function addEmpty() {
    setRuns([
      ...runs,
      {
        from: moment().format(),
        to: moment().add("15", "minute").format(),
        id: v4().slice(0, 5),
      },
    ]);
  }

  function remove(index: number) {
    const newRun = [...runs];
    newRun.splice(index, 1);
    setRuns(newRun);
  }

  return (
    <div className={""}>
      <div
        className={
          "bg-slate-200 text-xs flex flex-row justify-between items-center px-3 my-3"
        }
      >
        <p className={""}>{day.format("dddd, Do").toUpperCase()}.</p>
        <button onClick={addEmpty}>
          <i className={"bx bx-plus text-lg"}></i>
        </button>
      </div>
      <div className={"px-3"}>
        {runs.map((run, index) => (
          <div
            key={index}
            className={
              "flex flex-row justify-between border-b last:border-none py-2"
            }
          >
            <div className={"flex flex-row space-x-3"}>
              <p>{moment(run.from).format("hh:mm A")}</p>
              <p>-</p>
              <p>{moment(run.to).format("hh:mm A")}</p>
              <p>({moment(run.to).diff(moment(run.from), "minute")}min)</p>
            </div>
            <DoubleClickButton
              onRelease={() => remove(index)}
              component={<i className={"bx bx-trash text-pink-500"}></i>}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DayRun;
