import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import { ExtendedGuests } from "../../../../typings/reservation";
import { Counter } from "../../../../components/Counter";
import ActivityModal from "../../../../components/ActivityModal";

const Guests = ({
  max = 8,
  onChange,
  guests,
  onClose,
}: {
  max?: number;
  guests: ExtendedGuests;
  onChange: (d: ExtendedGuests) => void;
  onClose: () => void;
}) => {
  const [maxGuests] = useState(max);

  const [adults, setAdults] = useState(guests.adults); // Adults default to 1
  const [children, setChildren] = useState(guests.children);
  const [infants, setInfants] = useState(guests.infants);
  const [pets, setPets] = useState(guests.pets);

  // Function to handle changes in adults and children counts
  const onAdultChange = (newAdultCount: number) => {
    if (newAdultCount + children <= maxGuests) {
      setAdults(newAdultCount);
    }
  };

  const onChildrenChange = (newChildrenCount: number) => {
    if (adults + newChildrenCount <= maxGuests) {
      setChildren(newChildrenCount);
    }
  };

  const onSave = () => {
    onChange({ adults, children, infants, pets });
    onClose();
  };

  useEffect(() => {
    const currentNos = adults + children;
    // Make sure that we don't exceed maxGuests and ensure the total stays within bounds
    if (currentNos > maxGuests) {
      setAdults(maxGuests - children); // If adults and children together exceed maxGuests, set adults to the max allowed
    }
  }, [adults, children, maxGuests]);

  return (
    <ActivityModal
      title={"Guests"}
      body={
        <div className={"space-y-3"}>
          <div className={"p-9 space-y-6"}>
            <div className={"flex flex-row justify-between"}>
              <div>
                <p className={"font-semibold"}>Adults</p>
                <p className={"text-gray-500"}>Age 13+</p>
              </div>
              <Counter
                min={1} // Minimum adults should be 1
                max={maxGuests - children} // Max adults based on remaining capacity for children
                defaultValue={adults}
                onChange={onAdultChange}
              />
            </div>
            <div className={"flex flex-row justify-between"}>
              <div>
                <p className={"font-semibold"}>Children</p>
                <p className={"text-gray-500"}>Ages 2 -12</p>
              </div>
              <Counter
                min={0} // Minimum children can be 0
                max={maxGuests - adults} // Max children based on remaining capacity for adults
                defaultValue={children}
                onChange={onChildrenChange}
              />
            </div>
            <div className={"flex flex-row justify-between"}>
              <div>
                <p className={"font-semibold"}>Infants</p>
                <p className={"text-gray-500"}>Under 2</p>
              </div>
              <Counter
                min={0}
                max={4}
                defaultValue={infants}
                onChange={setInfants}
              />
            </div>
            <div className={"flex flex-row justify-between"}>
              <div>
                <p className={"font-semibold"}>Pets</p>
                <p className={"text-gray-500"}>Requires extra cleaning fee*</p>
              </div>
              <Counter min={0} max={2} defaultValue={pets} onChange={setPets} />
            </div>
          </div>
          <hr />
        </div>
      }
      footer={
        <Button type={"primary"} text={"Save"} block={true} onClick={onSave} />
      }
      onClose={onClose}
    />
  );
};

export default Guests;
