import React, { ReactNode } from "react";
import {
  Body,
  Container,
  Head,
  Hr,
  Html,
  Preview,
  Section,
  Tailwind,
} from "@react-email/components";
import HostSignature from "./HostSignature";
import IfemideFooter from "./IfemideFooter";
import LogoRider from "./LogoRider";

const MailSetup = ({
  previewText,
  component,
}: {
  previewText: string;
  component: ReactNode;
}) => {
  return (
    <Html lang="en" className={"bg-gray-100 p-3"}>
      <Head />
      <Preview>{previewText}</Preview>
      <Tailwind>
        <Body className="bg-white my-auto mx-auto font-sans px-2">
          <Container className="border border-solid border-[#eaeaea] rounded-xl my-[40px] mx-auto p-6 max-w-[600px]">
            <Section>
              <div className={"my-6 mb-9"}>
                <LogoRider />
              </div>
            </Section>
            {component}
            <HostSignature />
            <Hr />
            <IfemideFooter />
          </Container>
        </Body>
      </Tailwind>
    </Html>
  );
};

export default MailSetup;
