import React from "react";
import { ModalView } from "../Reservations";
import { Moment } from "moment/moment";
import { getNights } from "../helpers/reservation";

const YourDetails = ({
  setModal,
  noOfGuests,
  dates,
  validation,
  id,
}: {
  setModal: (p: ModalView) => void;
  noOfGuests: number;
  validation?: string;
  dates: {
    checkIn?: Moment;
    checkOut?: Moment;
  };
  id: ModalView;
}) => {
  const renderDateRange = () => {
    if (!dates.checkIn || !dates.checkOut) {
      return (
        <p className={`${validation ? "text-pink-500" : ""}`}>
          Please enter dates
        </p>
      );
    }

    const checkInMonth = dates.checkIn.format("MMM YYYY"); // Get the month and year of checkIn
    const checkOutMonth = dates.checkOut.format("MMM YYYY"); // Get the month and year of checkOut

    const nights = getNights(dates);

    const nightsString = `(${nights} ${nights === 1 ? "night" : "nights"})`;

    // If checkIn and checkOut are in different months, display full date range
    let formattedCheckIn = dates.checkIn.format("D MMM");
    let formattedCheckOut = dates.checkOut.format("D MMM");

    // If checkIn and checkOut are in the same month, format without repeating the month
    if (checkInMonth === checkOutMonth) {
      formattedCheckIn = dates.checkIn.format("D");
      formattedCheckOut = dates.checkOut.format("D MMM");
    }

    return (
      <>
        <p>{`${formattedCheckIn} - ${formattedCheckOut}  ${nightsString}`}</p>
        {validation && <p className={"text-pink-500"}>{validation}</p>}
      </>
    );
  };

  return (
    <section
      id={`reservation-${id}`}
      className={"p-9 bg-white space-y-3 rounded-lg"}
    >
      <p className={"font-semibold"}>Your trip</p>
      <div className={"flex flex-row justify-between"}>
        <div className={"space-y-1"}>
          <p className={""}>Dates</p>
          {renderDateRange()}
        </div>
        <p
          className={
            "underline font-semibold underline-offset-4 cursor-pointer"
          }
          onClick={() => setModal("select dates")}
        >
          Edit
        </p>
      </div>
      <div className={"flex flex-row justify-between"}>
        <div className={"space-y-1"}>
          <p className={""}>Guests</p>
          <p>{`${noOfGuests} ${noOfGuests <= 1 ? "guest" : "guests"}`}</p>
        </div>
        <p
          className={
            "underline font-semibold underline-offset-4 cursor-pointer"
          }
          onClick={() => setModal("guests")}
        >
          Edit
        </p>
      </div>
    </section>
  );
};

export default YourDetails;
