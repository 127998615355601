import React from "react";

const WhereYouWillStay = ({
  onClick,
  thumbnailUrl,
  bedrooms,
  roomTitle,
}: {
  onClick: () => void;
  thumbnailUrl: string;
  bedrooms: number;
  roomTitle: string;
}) => {
  return (
    <section className={"p-9 bg-white rounded-lg grid grid-cols-2 gap-4 "}>
      <img
        alt={"Property"}
        src={thumbnailUrl}
        className={"w-full h-24 rounded-xl object-cover shadow-xl"}
      />
      <div className={"flex flex-col justify-between text-end"}>
        <div className={"space-y-3"}>
          <p className={"text-xs text-gray-500"}>Where you will stay</p>
          <div>
            <p>{roomTitle}</p>
            <p className={"font-extrabold"}>{bedrooms} Bedrooms</p>
          </div>
        </div>
        <p
          className={"font-semibold underline underline-offset-4 text-end"}
          onClick={onClick}
        >
          Edit
        </p>
      </div>
    </section>
  );
};

export default WhereYouWillStay;
