import React, { useEffect, useState } from "react";

interface Props {
  placeholder?: string;
  icon?: string;
  valuePrefix?: string;
  value?: string;
  onChange?: (value: string) => void;
  error?: string;
}

const NumberInput = (props: Props) => {
  const [value, setValue] = useState<string | undefined>(props.value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    props.onChange && e.target.value && props.onChange(e.target.value);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div
      className={`flex flex-row items-center ${props.error ? "bg-pink-100" : ""}`}
    >
      <i className={`${props.icon} text-2xl`}></i>
      <div className={"flex flex-row items-center"}>
        {value && props.valuePrefix && (
          <p className={"ml-3"}>{props.valuePrefix}</p>
        )}
        <input
          onChange={handleChange}
          className="h-full w-full mx-3 flex-grow bg-transparent focus:outline-none focus:border-red-500 focus:border-b"
          placeholder={props.placeholder}
          value={value}
        />
      </div>
    </div>
  );
};

export default NumberInput;
