import React from "react";

const RoundPick = ({
  selected,
  disabled,
  onClick = () => {},
}: {
  selected?: boolean;
  onClick?: () => any;
  disabled?: boolean;
}) => {
  return (
    <div
      onClick={() => (disabled ? undefined : onClick())}
      className={`w-5 h-5 p-1 rounded-full ${selected ? "border-4" : "border"} border-black cursor-pointer`}
    ></div>
  );
};

export default RoundPick;
