import React from "react";
import { copyToClipboard } from "../utils/validation";

interface Interface {
  id: string;
  title: string;
  subtitle: string;
}

const OrderResult = ({ id, title, subtitle }: Interface) => {
  return (
    <div
      className={"flex flex-col items-center justify-center space-y-3 px-12"}
    >
      <p className={"text-5xl font-thin text-center"}>{title}</p>
      <p className={"text-xs font-bold"}>{subtitle}</p>
      <p
        onClick={() => copyToClipboard(id)}
        className={
          "p-3 w-fit font-extrabold border-2 border-dashed bg-slate-100 rounded-xl text-3xl tracking-widest border-black"
        }
      >
        {id}
      </p>
      <p className={"text-xs text-gray-500"}>TAP TO COPY</p>
    </div>
  );
};
export default OrderResult;
