import React, { useEffect, useState } from "react";

const CountdownTimer = ({
  start = 30,
  onComplete,
}: {
  start: number;
  onComplete: () => void;
}) => {
  const [timeLeft, setTimeLeft] = useState(start);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (onComplete) onComplete(); // Trigger onComplete callback if provided
      return; // Stop the countdown when time reaches 0
    }

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000); // Decrease time by 1 every second

    // Clear interval on component unmount or if timeLeft changes
    return () => clearInterval(timerId);
  }, [timeLeft, onComplete]);

  // Convert timeLeft from seconds to mm:ss format
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return <p className={"text-xs"}>{formattedTime}</p>;
};

export default CountdownTimer;
