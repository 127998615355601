import React, { ReactNode, useState } from "react";

interface HoldButtonProps {
  disabled?: boolean;
  onRelease: () => void;
  component: ReactNode;
}

const DoubleClickButton: React.FC<HoldButtonProps> = ({
  disabled = false,
  onRelease,
  component,
}) => {
  const [lastTap, setLastTap] = useState<number | null>(null);
  const doubleTapThreshold = 300; // Time in ms to detect double tap (adjust as needed)

  const handleClick = () => {
    if (disabled) return;

    const currentTime = Date.now();
    if (lastTap && currentTime - lastTap < doubleTapThreshold) {
      onRelease(); // Trigger the action on double-tap/double-click
      setLastTap(null); // Reset the last tap
    } else {
      setLastTap(currentTime); // Store the time of the first tap
    }
  };

  return (
    <button
      disabled={disabled}
      onClick={handleClick} // Use single click for double-tap logic
    >
      {component}
    </button>
  );
};

export default DoubleClickButton;
