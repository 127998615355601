import React from "react";
import NumberInput from "../../../../../components/NumberInput";

const Guests = (props: {
  adults: number;
  children: number;
  setAdults: (v: number) => void;
  setChildren: (v: number) => void;
  errors?: { adults?: string; children?: string };
}) => {
  const { adults, children, setChildren, setAdults, errors } = props;

  return (
    <div className={""}>
      <div className={"flex flex-row items-center text-xs space-x-1"}>
        <div>GUESTS</div>
        <div className={"flex flex-row justify-between items-center "}>
          <div className={"flex flex-row items-center"}>
            <i className="bx bxs-group text-lg"></i>
            <p>{`${Number(adults) + Number(children)}`}</p>
          </div>
        </div>
      </div>
      <div
        className={`rounded-2xl border px-3 py-3 grid grid-cols-2 gap-4 ${errors?.adults || errors?.children ? "border-pink-500" : ""}`}
      >
        <NumberInput
          value={`${adults}`}
          onChange={(value) => {
            const numberValue = Number(value);
            if (!isNaN(numberValue)) setAdults(numberValue);
          }}
          icon={"bx bx-body"}
          placeholder={"Adults"}
          valuePrefix={"Adults "}
        />
        <NumberInput
          value={`${children}`}
          onChange={(value) => {
            const numberValue = Number(value);
            if (!isNaN(numberValue)) setChildren(numberValue);
          }}
          icon={"bx bxs-baby-carriage"}
          placeholder={"Children"}
          valuePrefix={"Children"}
        />
      </div>
    </div>
  );
};

export default Guests;
