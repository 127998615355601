import React, { useCallback, useEffect, useState } from "react";
import FullPage from "../../../components/FullPage";
import { Contemporary, Minimalistic } from "../home/config/Rooms";
import CompleteReservation from "./pages/CompleteReservation";
import { Moment } from "moment";
import CustomerDetails from "./pages/CustomerDetails";
import { Customer } from "../../../typings/customer";
import SelectDates, { MAX_MONTHS_IN_ADVANCE } from "./pages/SelectDates";
import { Room, RoomUuidRecordByNightly } from "../../../typings/rooms";
import { get } from "../../../api/database";
import { AxiosError } from "axios";
import Guests from "./pages/Guests";
import { ExtendedGuests, Reservation } from "../../../typings/reservation";
import CancellationPolicy from "./pages/CancellationPolicy";
import HouseRules from "./pages/HouseRules";
import RoomSelection from "./pages/SelectRooms";
import { View } from "../home/Home";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import SendEnquiry from "./pages/SendEnquiry";
import { HowToPay, PayWith } from "../../../components/PaymentOption";
import ConfirmAndPay from "./pages/ConfirmAndPay";
import { renovate } from "../../../utils/memory";
import { getBlockedNights, getValidation } from "../../../utils/room";
import WhereYouWillStay from "./fragments/WhereYouWillStay";
import YourDetails from "./fragments/YourDetails";
import YourTrip from "./fragments/YourTrip";
import PriceDetails from "./fragments/PriceDetails";
import ChooseHowToPay from "./fragments/ChooseHowToPay";
import Payment from "./fragments/Payment";
import ReadCancellationPolicy, {
  cancellationPolicy,
} from "./fragments/ReadCancellationPolicy";
import ReadHouseRules, { houseRules } from "./fragments/ReadHouseRules";
import ProceedToPay from "./fragments/ProceedToPay";
import {
  buildCustomer,
  buildReservation,
  getNights,
  validCustomer,
} from "./helpers/reservation";
import { toastAxiosError } from "../../../utils/toast";

export type ModalView =
  | "property"
  | "customer data"
  | "select dates"
  | "guests"
  | "cancellation policy"
  | "ground rules"
  | "confirm and pay"
  | "complete reservation"
  | "send an enquiry";

export type Validation = {
  details?: string;
  dates?: string;
  valid?: boolean;
};

export type BookingDates = {
  checkIn?: Moment;
  checkOut?: Moment;
};

const defaultRoomUuid = "5579d88d-77d9-4b60-94ec-0ce912893da3";

export const DEPOSIT = 0.7;

const getRoomNightly = (rooms: Room[]): RoomUuidRecordByNightly =>
  rooms.reduce((roomNightly, room) => {
    roomNightly[room.uuid] = room.nightly;
    return roomNightly;
  }, {} as RoomUuidRecordByNightly);

const Reservations = () => {
  const location = useLocation();

  const [rooms, setRooms] = useState<Room[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);

  const [modal, setModal] = useState<ModalView | undefined>();
  const [payWith, setPayWith] = useState<PayWith>("bank transfer");
  const [howToPay, setHowToPay] = useState<HowToPay>("full payment");

  const [customer, setCustomer] = useState<Partial<Customer>>({
    ...renovate(),
  });
  const [dates, setDates] = useState<BookingDates>({});
  const [guests, setGuests] = useState<ExtendedGuests>({
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
  });

  const [view, setView] = useState<View>(
    new URLSearchParams(location.search).get("view") === "Minimalistic"
      ? "Minimalistic"
      : "Contemporary",
  );

  const [roomUuid, setRoomUuid] = useState(
    new URLSearchParams(location.search).get("roomUuid") || defaultRoomUuid,
  );

  const [bedrooms, setBedrooms] = useState(3);

  const [roomConfig, setRoomConfig] = useState(
    view === "Contemporary" ? Contemporary : Minimalistic,
  );
  const [room, setRoom] = useState<Room>();

  const [coupon, setCoupon] = useState(0);
  const [cleaningFee, setCleaningFee] = useState(0);

  const [couponCode, setCouponCode] = useState("");

  const [error, setError] = useState<AxiosError>();
  const [validation, setValidation] = useState<{
    details?: string;
    dates?: string;
    valid?: boolean;
  }>();

  const [amountPaid, setAmountPaid] = useState<number>();

  const [roomNightly, setRoomNightly] = useState<RoomUuidRecordByNightly>({});
  const [blockedNights, setBlockedNights] = useState<Moment[]>([]);

  const nights = getNights(dates);

  // Memoize onPaymentCompleted function using useCallback
  const handlePaymentCompleted = useCallback((amountPaid: number) => {
    setAmountPaid(amountPaid);
    setModal("complete reservation");
  }, []);

  const getModal = () => {
    switch (modal) {
      case "property": {
        return (
          <RoomSelection
            beds={bedrooms}
            view={view}
            onChange={(d) => {
              setBedrooms(d.bedrooms);
              setView(d.view);
              setRoomConfig(d.room);
              setRoomUuid(d.roomUuid);
            }}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "customer data": {
        return (
          <CustomerDetails
            customer={customer}
            onChange={setCustomer}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "select dates": {
        return (
          <SelectDates
            onChange={setDates}
            checkIn={dates.checkIn}
            checkOut={dates.checkOut}
            onClose={() => setModal(undefined)}
            reservations={reservations}
            roomUuid={roomUuid}
            blockedNights={blockedNights}
          />
        );
      }
      case "guests": {
        return (
          <Guests
            onChange={setGuests}
            guests={guests}
            max={room?.maxOccupancy || 8}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "cancellation policy": {
        return (
          <CancellationPolicy
            faq={cancellationPolicy!!}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "ground rules": {
        return (
          <HouseRules faq={houseRules!!} onClose={() => setModal(undefined)} />
        );
      }
      case "confirm and pay": {
        return (
          <ConfirmAndPay
            payWith={payWith}
            amountToPay={amountToPay}
            handlePaymentCompleted={handlePaymentCompleted}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "send an enquiry": {
        return (
          <SendEnquiry
            room={room!!}
            customer={buildCustomer(customer)}
            checkOut={dates.checkIn!!}
            checkIn={dates.checkOut!!}
            onClose={() => setModal(undefined)}
          />
        );
      }
      case "complete reservation": {
        if (room && amountPaid) {
          return (
            <CompleteReservation
              room={room}
              customer={buildCustomer(customer)}
              reservation={buildReservation(
                roomUuid,
                amountPaid,
                grandTotal,
                coupon,
                dates,
                guests,
              )}
            />
          );
        }
      }
    }
  };

  const isValid = (): boolean => {
    return !!(amountToPay > 0 && getValidation(dates, customer).valid && room);
  };

  const validate = useCallback((): Validation => {
    const result = getValidation(dates, customer);

    setValidation(result);

    return result;
  }, [dates, customer]);

  const validateCouponCode = useCallback(() => {
    if (room && couponCode.trim().toLowerCase() === "welcome20") {
      setCoupon(room.rate * nights * 0.2);
      return;
    }
    if (room && couponCode.trim().toLowerCase() === "ifemide-9999") {
      setCoupon(room.rate * nights * 0.9999);
      return;
    }
    setCoupon(0);
  }, [room, couponCode, nights, setCoupon]);

  const scrollTo = (view: ModalView) => {
    const element = document.getElementById(`reservation-${view}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    get<Room[]>("rooms").then(setRooms).catch(setError);
  }, []);

  useEffect(() => {
    setRoomNightly(getRoomNightly(rooms));
  }, [rooms]);

  useEffect(() => {
    if (roomUuid) {
      const find = rooms.find((r) => r.uuid === roomUuid);
      setRoom(find);
      if (!find) setRoomUuid(defaultRoomUuid);
    }
  }, [rooms, roomUuid]);

  useEffect(() => {
    get<Reservation[]>("reservations").then(setReservations).catch(setError);
  }, []);

  useEffect(() => {
    if (roomUuid) {
      setBlockedNights(
        getBlockedNights(
          moment(),
          moment().add(MAX_MONTHS_IN_ADVANCE, "months"),
          reservations,
          roomNightly,
          roomUuid,
        ),
      );
      // reset the dates
      setDates({});
    }
  }, [reservations, roomNightly, roomUuid]);

  useEffect(() => {
    if (dates.checkIn && !dates.checkOut) {
      dates.checkOut = dates.checkIn.clone().add(1, "day");
      setDates(dates);
    }
  }, [dates]);

  useEffect(() => {
    if (guests.pets > 0) setCleaningFee(10000);
    else setCleaningFee(0);
  }, [guests]);

  useEffect(() => {
    if (couponCode) validateCouponCode();
  }, [
    validateCouponCode,
    dates.checkIn,
    dates.checkOut,
    room?.rate,
    couponCode,
  ]);

  useEffect(() => {
    if (validation) {
      validate();
    }
  }, [dates, customer]);

  useEffect(() => {
    if (error) {
      toastAxiosError(error);
    }
  }, [error]);

  const total = room?.rate ? room.rate * nights : 0;
  const grandTotal = Math.max(0, total + cleaningFee - coupon);
  const amountToPay =
    howToPay === "full payment"
      ? grandTotal
      : howToPay === "half payment"
        ? DEPOSIT * grandTotal
        : grandTotal;

  const valid = isValid();
  const noOfGuests = guests.adults + guests.children;

  const customerPreview = validCustomer(customer)
    ? customer.firstName + " " + customer.lastName
    : "Incomplete details";

  return (
    <>
      <FullPage
        title={"MAKE A RESERVATION"}
        px={0}
        component={
          <div className={"space-y-6 relative m-6"}>
            <WhereYouWillStay
              onClick={() => setModal("property")}
              thumbnailUrl={roomConfig.photos[0].url}
              bedrooms={bedrooms}
              roomTitle={roomConfig.title}
            />
            <YourDetails
              onClick={() => setModal("customer data")}
              validation={validation?.details}
              customerPreview={customerPreview}
              emailAddress={customer?.emailAddress}
              id={"customer data"}
            />
            <YourTrip
              setModal={setModal}
              noOfGuests={noOfGuests}
              dates={dates}
              validation={validation?.dates}
              id={"select dates"}
            />
            <PriceDetails
              total={total}
              cleaningFee={cleaningFee}
              coupon={coupon}
              nights={nights}
              rate={room?.rate}
              grandTotal={grandTotal}
              setModal={setModal}
              setCouponCode={setCouponCode}
              validateCouponCode={validateCouponCode}
            />
            <ChooseHowToPay
              howToPay={howToPay}
              setHowToPay={setHowToPay}
              grandTotal={grandTotal}
            />
            <Payment setPayWith={setPayWith} />
            <ReadCancellationPolicy setModal={setModal} />
            <ReadHouseRules setModal={setModal} />
            <ProceedToPay
              setModal={setModal}
              validation={validation}
              validate={validate}
              payWith={payWith}
              valid={valid}
              scrollTo={scrollTo}
            />
          </div>
        }
      />
      {modal && getModal()}
    </>
  );
};

export default Reservations;
