import React from "react";
import { Heading, Section, Text } from "@react-email/components";
import {
  GoogleLocation,
  Instagram,
  Phone,
  WhatsApp,
} from "../pages/faq/FaqHome";
import MailSetup from "./shared/MailSetup";

const ReferAFriendEmail = ({
  customerName = "my previous customer",
}: {
  customerName?: string;
}) => {
  return (
    <MailSetup
      previewText={""}
      component={
        <>
          <Section>
            <Text className={"font-extrabold text-lg mt-9"}>Hi there!</Text>
            <Text>
              {`
                I hope this email finds you well! My name is `}
              <span className={"font-extrabold "}>Rita</span>
              {`, ${customerName}
                referred me to you, I have an amazing opportunity for you to
                enjoy a fantastic getaway at `}
              <span className={"text-pink-500 font-bold"}>
                Ifemide Estates, Alagbaka, Akure.
              </span>
            </Text>
            <Text>
              Whether you’re looking for a relaxing weekend photoshoot, get
              together, weddings or party, we have something for everyone!
            </Text>
          </Section>
          <Section>
            <Heading className={"font-extrabold mb-3 text-lg"}>
              Special Offer Just for You!
            </Heading>
            <Text>
              {`As my guest, you and ${customerName} can enjoy up to 25% off your stay
                on your next booking with me.`}
            </Text>
          </Section>
          <Section>
            <Heading className={"font-extrabold mb-3 text-lg"}>
              Why Stay at Ifemide Estates?
            </Heading>
            <Text className={"m-0 p-0 mb-2"}>
              <span className={"font-extrabold"}>
                Luxurious Accommodations:
              </span>{" "}
              Experience comfort in our beautifully designed villas, with
              tasteful finishing and luxury feel.
            </Text>
            <Text className={"m-0 p-0 mb-2"}>
              <span className={"font-extrabold"}>Entire House:</span> You have
              the entire house with own compound all to yourself, living room,
              fitted kitchen, washing machine and dryer!
            </Text>
            <Text className={"m-0 p-0 mb-2"}>
              <span className={"font-extrabold"}>Location:</span> Conveniently
              located at
              <span>
                {GoogleLocation({ title: " Alagbaka GRA Extension" })}
              </span>
              , about 5 minutes drive from Royal Birds Hotel and Towers.
            </Text>
          </Section>
          <Section>
            <div className={"bg-gray-100 p-6 rounded-xl"}>
              <Heading className={"font-extrabold mb-3 text-lg"}>
                How to Redeem
              </Heading>
              <Text className={"m-0 p-0 mb-2"}>
                1. You can reach me on <span>{WhatsApp()}</span>,{" "}
                <span>{Phone()}</span>, or <span>{Instagram()}</span>
              </Text>
              <Text className={"m-0 p-0 mb-2"}>
                2. Tell me your dates and I can close the dates for you
              </Text>
              <Text className={"m-0 p-0 mb-2"}>
                3. The discount will be automatically applied at checkout.
              </Text>
            </div>
          </Section>
          <Section>
            <Text>
              Feel free to share this with anyone else who might be interested!
              I know you and your friends will have a wonderful time staying
              with us.
            </Text>
            <Text>
              Just let me know if you have any plans to book or in the future;
              I’d love to hear all about it!
            </Text>
          </Section>
        </>
      }
    />
  );
};

export default ReferAFriendEmail;
