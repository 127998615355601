import React, { useEffect, useState } from "react";
import DoubleClickButton from "../../../../components/DoubleClickButton";
import SingleBooking, {
  DEFAULT_ADULTS,
  DEFAULT_CHECK_IN_HOURS_UTC,
  DEFAULT_CHECK_OUT_HOURS_UTC,
  DEFAULT_CHILDREN,
} from "./shared/SingleBooking";
import { Booking } from "../../../../typings/reservation";
import { getAvailableRooms } from "../../../../api/rooms";
import { Room } from "../../../../typings/rooms";
import { v4 } from "uuid";
import timezone from "moment-timezone";
import { DEFAULT_TIMEZONE } from "../../../../constants/links";
import Loader from "../../../../components/Loader";

const EMPTY_BOOKING: Partial<Booking> = {
  checkInDateTime: timezone
    .tz(DEFAULT_TIMEZONE)
    .set({
      hour: DEFAULT_CHECK_IN_HOURS_UTC,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISOString(),
  checkOutDateTime: timezone
    .tz(DEFAULT_TIMEZONE)
    .add(1, "days")
    .set({
      hour: DEFAULT_CHECK_OUT_HOURS_UTC,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
    .toISOString(),
  guests: { adults: DEFAULT_ADULTS, children: DEFAULT_CHILDREN },
  roomUuid: undefined,
};

interface Props {
  setBookings: (d: Partial<Booking>[]) => void;
  validate: boolean;
  bookings: Partial<Booking>[];
}

const BookingsEditor = (props: Props) => {
  const [bookings, setBookings] = useState<
    Partial<Booking> & { listUuid: string }[]
  >(props.bookings.map((booking) => ({ ...booking, listUuid: v4() })));

  const [rooms, setRooms] = useState<Room[]>([]);

  const roomsMap = new Map(
    rooms.map((room) => [room.uuid, { ...room, id: room.uuid }]),
  );

  useEffect(() => {
    getAvailableRooms()
      .then(setRooms)
      .catch(() => {
        window.alert("Failed to get available rooms.");
      });
  }, []);

  useEffect(() => {
    props.setBookings(bookings.map(omitListUuidFromBooking));
    // eslint-disable-next-line
  }, [bookings]);

  function omitListUuidFromBooking(booking: Booking & any) {
    const { listUuid, ...rest } = booking; // Omit listUuid
    return rest; // Return remaining properties
  }

  function removeBooking(index: number) {
    if (bookings.length > 1 && index >= 0 && index < bookings.length) {
      const updatedBookings = [...bookings]; // Create a copy of the array
      updatedBookings.splice(index, 1); // Remove the booking at the specified index
      setBookings(updatedBookings); // Update the state with the new array
    }
  }

  function addEmpty() {
    setBookings([{ ...EMPTY_BOOKING, listUuid: v4() }, ...bookings]);
  }

  function onBookingChanged(index: number, updatedBooking: Partial<Booking>) {
    if (index >= 0 && index < bookings.length) {
      const existingBooking = bookings[index];

      // Create a new booking object only if there is a difference
      const newBooking = { ...existingBooking, ...updatedBooking };

      // Check if the new booking is different from the existing one
      if (JSON.stringify(newBooking) !== JSON.stringify(existingBooking)) {
        // Create a new bookings array and update only the changed booking
        const updatedBookings = [...bookings];
        updatedBookings[index] = newBooking;
        setBookings(updatedBookings); // Update state only if there's a real change
      }
    }
  }

  if (rooms.length === 0) return <Loader />;

  return (
    <div className={"space-y-3"}>
      <div className={""}>
        <div className={"flex flex-row justify-between"}>
          <p className={"font-extrabold text-xl"}>Bookings</p>
          <button onClick={addEmpty}>
            <i className={"bx bx-plus text-xl font-extrabold"}></i>
          </button>
        </div>
        <p className={"text-gray-400"}>
          Add for each services with different dates
        </p>
      </div>

      <div className={"rounded-xl shadow bg-white space-y-3 p-6"}>
        {bookings.map((booking, index) => (
          <div key={booking.listUuid}>
            {bookings.length > 1 && (
              <div className={"flex flex-row justify-end"}>
                <DoubleClickButton
                  disabled={bookings.length <= 1}
                  onRelease={() => removeBooking(index)}
                  component={<i className={"bx bx-trash text-pink-500"}></i>}
                />
              </div>
            )}
            <SingleBooking
              key={booking.listUuid}
              roomsMap={roomsMap}
              validate={props.validate}
              booking={omitListUuidFromBooking(booking)}
              onChange={(d) => onBookingChanged(index, d)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingsEditor;
