import React from "react";

const Loader = ({ size = 6 }: { size?: number }) => {
  return (
    <div className={"w-full h-full justify-center items-center flex flex-row"}>
      <div
        className={`loader border-t-4 border-pink-500 rounded-full w-${size} h-${size} animate-spin`}
      ></div>
    </div>
  );
};

export default Loader;
