import React, { useState } from "react";
import ActivityModal from "../../../../components/ActivityModal";
import Button from "../../../../components/Button";

interface Interface {
  onClose: () => void;
  notes?: string;
  onSave: (notes: string) => void;
}

const NOTES_CHAR_LIMIT = 500;

const AddNotes = ({ onClose, notes, onSave }: Interface) => {
  const [currentNotes, setCurrentNote] = useState(notes || "");

  const handleSetNotes = (input: string) => {
    // Always trim the input to the character limit
    setCurrentNote(input.slice(0, NOTES_CHAR_LIMIT));
  };

  return (
    <ActivityModal
      title={"Add notes"}
      body={
        <div className={"space-y-3 p-6"}>
          <p className={"font-extrabold"}>Any special requests?</p>
          <p>You can add each on a separate line</p>
          <textarea
            value={currentNotes}
            onChange={(e) => handleSetNotes(e.target.value)}
            className={
              "border rounded-xl bg-slate-100 w-full h-60 p-3 resize-none focus:outline-pink-500"
            }
            rows={10}
          />

          <p
            className={"text-end"}
          >{`${currentNotes.length} / ${NOTES_CHAR_LIMIT}`}</p>
        </div>
      }
      footer={
        <Button
          text={"Save notes"}
          block={true}
          onClick={() => {
            onSave(currentNotes);
          }}
        />
      }
      onClose={onClose}
    />
  );
};

export default AddNotes;
