import React, { useState } from "react";
import { Customer } from "../../../typings/customer";
import { Reservation } from "../../../typings/reservation";
import Loader from "../../../components/Loader";
import { FaqLink, GoogleReviewLink } from "../../../constants/links";
import FormEntry from "../../../components/FormEntry";
import { getTimeOfDayImage, isValidName } from "../../../utils/validation";
import { patch } from "../../../api/database";
import FullPage from "../../../components/FullPage";

export type Modals = "None" | "Refer" | "Bookings" | "Menu";

const ListEntry = ({
  text,
  icon,
  href,
  disabled,
}: {
  text: string;
  icon: string;
  href?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`flex flex-row items-center ${disabled ? "opacity-50" : ""}`}
    >
      <span className={`text-pink-500 mr-3`}>
        <i className={`${icon} text-2xl`}></i>
      </span>
      <a
        href={href || "#"}
        className={`${disabled ? "pointer-events-none" : ""}`}
      >
        {text}
      </a>
    </div>
  );
};

const ImageIcon = ({
  title,
  src,
  href,
  target = "",
  rel = "",
  disabled,
}: {
  title: string;
  src: string;
  href: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`flex flex-row space-x-3 items-center ${disabled ? "opacity-50" : ""}`}
    >
      <div className={"h-8 w-8 overflow-hidden rounded-full"}>
        <img alt={""} className={"object-cover"} src={src} />
      </div>
      <p>
        <a
          target={target}
          rel={rel}
          href={href}
          className={`${disabled ? "pointer-events-none" : ""}`}
        >
          {title}
        </a>
      </p>
    </div>
  );
};

export const defaultImage =
  "https://i.pinimg.com/474x/4c/dc/c9/4cdcc9e8902048b9e0db0b0f6298eeb9.jpg";
const GuestLounge = ({
  reservation,
  customer,
  handleLogOut = () => {},
  setCustomer = () => {},
}: {
  reservation?: Reservation;
  customer?: Customer;
  handleLogOut?: () => void;
  setCustomer?: (customer: Customer) => void;
}) => {
  const [editMode, setEditMode] = useState(false);

  const [firstName, setFirstName] = useState(customer?.firstName || "");
  const [lastName, setLastName] = useState(customer?.lastName || "");
  const [thumbnailUrl, setThumbnailUrl] = useState(
    customer?.thumbnailUrl || defaultImage,
  );

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<any>();

  async function onSave() {
    if (!isValidName(firstName)) {
      setError({ message: "Invalid first name" });
      return;
    }
    if (!isValidName(lastName)) {
      setError({ message: "Invalid last name" });

      return;
    }

    setSaving(true);
    setError(undefined);

    await patch<Customer>("customers", {
      ...customer!,
      firstName,
      lastName,
      thumbnailUrl,
    })
      .then((d) => {
        setCustomer(d);
        setEditMode(false);
      })
      .catch(setError);

    setSaving(false);
  }

  function onCancelSave() {
    if (customer) {
      setFirstName(customer.firstName);
      setLastName(customer.lastName);
      setThumbnailUrl(customer.thumbnailUrl);
    }
    setEditMode(false);
    setError(undefined);
  }

  if (!reservation || !customer) return <Loader />;

  return (
    <FullPage
      title={"GUEST LOUNGE"}
      back={"/"}
      component={
        <div className={"space-y-3 pb-80"}>
          <div className={"bg-white rounded-xl overflow-hidden shadow"}>
            <div
              className={
                "flex flex-col items-center justify-center pt-24 pb-6 relative overflow-hidden"
              }
            >
              <div
                className={"absolute top-0 right-0 h-36 overflow-hidden z-0"}
              >
                <img
                  alt={"bg"}
                  className={"object-cover"}
                  src={getTimeOfDayImage()}
                />
              </div>
              <div className={"flex flex-col items-center justify-center z-10"}>
                <img
                  alt={"Guest"}
                  src={thumbnailUrl}
                  className={"h-24 w-24 rounded-full shadow object-contain"}
                />
                {editMode && (
                  <div>
                    <FormEntry
                      disabled={saving}
                      title={"Profile Photo"}
                      placeholder={"https://link-to-my-instagram-profile"}
                      value={thumbnailUrl}
                      type={"text"}
                      onChange={setThumbnailUrl}
                    />
                    <FormEntry
                      disabled={saving}
                      title={"First name"}
                      placeholder={""}
                      value={firstName}
                      type={"text"}
                      onChange={setFirstName}
                    />
                    <FormEntry
                      disabled={saving}
                      title={"Last name"}
                      placeholder={""}
                      value={lastName}
                      type={"text"}
                      onChange={setLastName}
                    />
                  </div>
                )}
                <div
                  className={
                    "space-y-2 flex flex-col items-center justify-center mt-6 w-full"
                  }
                >
                  <p
                    className={"font-bold text-xl"}
                  >{`${firstName} ${lastName}`}</p>
                  <p className={"mt-6 text-xs underline"}>
                    {customer.emailAddress}
                  </p>
                  <div
                    className={
                      "flex flex-row items-center justify-center space-x-3"
                    }
                  >
                    {editMode && (
                      <button
                        disabled={saving}
                        onClick={onCancelSave}
                        className={
                          "border rounded-2xl shadow-xl text-xs px-6 py-2"
                        }
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      onClick={() => {
                        editMode ? onSave() : setEditMode(!editMode);
                      }}
                      disabled={saving}
                      className={`text-xs px-6 py-2 border rounded-2xl shadow-xl w-fit ${editMode ? "bg-pink-400 text-white border-none" : ""}`}
                    >
                      {editMode ? "Save" : "Edit"}
                    </button>
                  </div>
                  {saving && <Loader />}
                  {error && (
                    <p
                      className={
                        "text-xs my-1 text-center text-pink-500 underline"
                      }
                    >
                      {error.message || "An error occurred."}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <ImageIcon
                href={"/guest/refer"}
                disabled={false}
                title={"Refer a friend"}
                src={"https://cdn-icons-png.flaticon.com/512/4689/4689222.png"}
              />
              <ImageIcon
                title={"Write us a review on Google"}
                target={"_blank"}
                rel={"noreferrer"}
                href={GoogleReviewLink}
                src={
                  "https://img.freepik.com/vecteurs-premium/quatre-icones-circulaires-fond-bleu_1272475-9220.jpg"
                }
              />
            </div>
            <p></p>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <ImageIcon
                href={"/kitchen"}
                disabled={false}
                title={"Order for food"}
                src={
                  "https://static-00.iconduck.com/assets.00/food-icon-1024x1024-l5hmjc3o.png"
                }
              />
            </div>
            <p></p>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <ListEntry
                disabled={true}
                text={"Manage bookings"}
                icon={"bx bx-hotel"}
              />
              <ListEntry
                href={"/guest/internet"}
                disabled={false}
                text={"Internet login details"}
                icon={"bx bxl-internet-explorer"}
              />
              <ListEntry
                disabled={false}
                href={"/guest/menu"}
                text={"Food, drinks and price list"}
                icon={"bx bx-bowl-rice"}
              />
              <ListEntry
                href={"/guest/invoice"}
                disabled={false}
                text={"Check your invoice"}
                icon={"bx bx-receipt"}
              />
              <ListEntry
                href={"/guest/payment"}
                disabled={false}
                text={"Make a payment"}
                icon={"bx bx-credit-card-front"}
              />
            </div>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <ListEntry
                href={"/guest/bank-details"}
                disabled={false}
                text={"Bank details"}
                icon={"bx bxs-credit-card-alt"}
              />
            </div>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <ListEntry
                disabled={true}
                text={"Make a special request"}
                icon={"bx bxs-popsicle"}
              />
              <ListEntry
                href={FaqLink}
                text={"Frequently asked questions"}
                icon={"bx bxs-help-circle"}
              />
            </div>
          </div>
          <div
            className={"bg-white rounded-xl overflow-hidden shadow text-sm p-6"}
          >
            <div className={"space-y-3"}>
              <div onClick={handleLogOut}>
                <ListEntry text={"Log out"} icon={"bx bx-log-out"} />
              </div>
            </div>
            <p></p>
          </div>
        </div>
      }
    />
  );
};

export default GuestLounge;
