import React, { useEffect, useRef, useState } from "react";
import { Room } from "../../../typings/rooms";
import { get } from "../../../api/database";
import {
  InstagramIconLink,
  InstagramLink,
  TwitterIconLink,
  TwitterLink,
  WhatsAppIconLink,
  WhatsAppLink,
} from "../../../constants/links";
import RoomView, { RoomConfig } from "./pages/RoomView";
import { Contemporary, Minimalistic } from "./config/Rooms";
import { useLocation, useNavigate } from "react-router-dom";

export type View = "Contemporary" | "Minimalistic";

export const views: { view: View; config: RoomConfig }[] = [
  { view: "Contemporary", config: Contemporary },
  { view: "Minimalistic", config: Minimalistic },
];

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [select, setSelect] = useState(false);
  const [rooms, setRooms] = useState<Room[]>([]);
  const [room, setRoom] = useState<Room | undefined>();
  const [currentView, setCurrentView] = useState<View>(
    new URLSearchParams(location.search).get("view") === "Minimalistic"
      ? "Minimalistic"
      : "Contemporary",
  );
  const [inView, setInView] = useState(false);

  const sectionRef = useRef(null); // Reference for section 2

  function checkAvailability() {
    navigate(`/reservations?roomUuid=${room?.uuid || ""}&view=${currentView}`);
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        // We want to set 'inView' to true when 90% of section 1 is out of view
        setInView(entry.intersectionRatio < 0.1); // If less than 10% is visible, trigger fade-in
      },
      { threshold: 0.1 }, // Trigger when 90% of section 1 is out of the viewport
    );

    const section1 = document.getElementById("1");
    if (section1) {
      observer.observe(section1);
    }

    return () => {
      if (section1) {
        observer.unobserve(section1);
      }
    };
  }, []);

  useEffect(() => {
    get<Room[]>("rooms").then(setRooms).catch(console.log);
  }, []);

  useEffect(() => {
    if (rooms.length) setRoom(rooms[0]);
  }, [rooms]);

  // Update the URL search parameter when currentView changes
  useEffect(() => {
    // Replace the URL search parameter without adding to history
    const url = new URL(window.location.href);
    url.searchParams.set("view", currentView); // Update the `view` query param
    window.history.replaceState({}, "", url.toString()); // Update the URL without reloading the page
  }, [currentView]);

  return (
    <div className={"h-auto overflow-auto bg-white"}>
      <div
        className={"text-white mt-0"}
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url(https://ifemide-web-images.s3.eu-north-1.amazonaws.com/Soltaire+Edited/IMG_2346.jpeg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <section
          id={"1"} // Section 1
          className={
            "h-screen flex flex-col justify-center items-center space-y-32 p-6 max-w-[468px] mx-auto px-12"
          }
        >
          <div className={"flex flex-col justify-center items-center "}>
            <p className={"font-semibold text-3xl text-center"}>
              IFEMIDE ESTATES
            </p>
            <p className={"font-thin text-lg text-center"}>
              Luxury Homes, Wellness Spa and Beauty Retreat
            </p>
          </div>
          <div className={"w-full space-y-1"}>
            <p className={"font-bold py-1"}>Our services</p>
            <button
              onClick={() => {
                setSelect(!select);
              }}
              className={
                "truncate w-full bg-opacity-40 h-12 bg-white rounded border-2 border-white px-3 items-center flex flex-row font-extrabold"
              }
            >
              {room?.title}
            </button>
            <div
              className={`w-full top-16 border-4 border-white rounded transition-all duration-500 ease-in-out ${
                select
                  ? "max-h-60 overflow-y-scroll bg-white bg-opacity-90 opacity-100"
                  : "max-h-0 overflow-hidden opacity-0"
              }`}
            >
              {rooms.map((room) => (
                <button
                  disabled={room.archived}
                  key={room.uuid} // Don't forget to add a unique key
                  className={`text-black p-3 font-bold truncate ${room.archived ? "opacity-50" : ""}`}
                  onClick={() => {
                    setRoom(room);
                    setSelect(false);
                  }}
                >
                  {room.title}{" "}
                  <span className="font-normal italic">
                    - {room.description}
                  </span>
                </button>
              ))}
            </div>
            <button
              onClick={() => {
                checkAvailability();
              }}
              className={"w-full bg-white h-12 rounded text-black font-bold"}
            >
              CHECK AVAILABILITY
            </button>
            <div
              className={
                "flex flex-row items-center space-x-3 justify-center pt-16"
              }
            >
              <a href={InstagramLink}>
                <img
                  alt={"x"}
                  className={"rounded-full  w-10 h-10"}
                  src={InstagramIconLink}
                />
              </a>
              <a href={WhatsAppLink}>
                <img
                  alt={"x"}
                  className={"rounded-full  w-10 h-10"}
                  src={WhatsAppIconLink}
                />
              </a>
              <a href={TwitterLink}>
                <img
                  alt={"x"}
                  className={"rounded-full w-10 h-10"}
                  src={TwitterIconLink}
                />
              </a>
            </div>
            <p className={"text-lg font-thin text-center pt-6"}>
              109/119 Sola Aladesuyi Street, Behind SIB, Alagbaka GRA Extension,
              Akure Ondo State
            </p>
          </div>
        </section>
      </div>

      {/* Room Section */}
      <section ref={sectionRef} id={"2"}>
        <RoomView
          onAction={checkAvailability}
          room={views.find((i) => i.view === currentView)!.config}
        />
      </section>

      {/* This is the element that will fade in */}
      <div
        className={`absolute top-20 z-40 flex flex-row items-center justify-center w-full opacity-0 transition-opacity duration-1000 ease-in-out ${inView ? "opacity-100" : ""}`}
      >
        <div
          className={
            "w-fit flex flex-row items-center justify-center rounded-full overflow-hidden mx-auto bg-white shadow-xl font-bold"
          }
        >
          {views.map((view) => (
            <button
              key={view.view}
              onClick={() => setCurrentView(view.view)}
              className={`py-3 px-6 ${currentView === view.view ? "bg-pink-500 text-white" : ""}`}
            >
              {view.view}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
