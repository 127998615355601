import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import { AxiosError } from "axios";

const FullPage = ({
  title,
  component,
  loading,
  error,
  bannerUrl,
  px = 6,
  header,
}: {
  title: string;
  component: any;
  back?: string;
  loading?: boolean;
  error?: AxiosError | any;
  bannerUrl?: string;
  px?: number;
  header?: ReactNode;
}) => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1); // This will navigate back to the previous page
  };

  return (
    <div className="h-screen flex flex-col overflow-hidden pt-6 font-candid text-gray-700">
      {/* Header Section */}
      {header ? (
        <div className={"block absolute top-0 w-screen z-50"}>{header}</div>
      ) : (
        <div className={"block absolute top-0 w-screen z-40 py-3 bg-slate-200"}>
          <div
            className="flex flex-row items-center justify-between px-6"
            id="header"
          >
            <button onClick={handleGoBack} className="">
              <i className="bx bx-arrow-back text-lg"></i>
            </button>
            <p className="my-3 mx-6 text-xs">{title}</p>
            <a href="/">
              <i className="bx bx-notification text-gray-200"></i>
            </a>
          </div>
        </div>
      )}

      {/* Body Section */}
      <div
        id="body"
        className={`overflow-y-scroll pb-80 space-y-6 px-${px} scrollbar-hidden relative`}
      >
        {bannerUrl && (
          <div
            className="relative rounded-lg bg-white my-6 h-28 overflow-hidden shadow-lg text-center"
            style={{
              backgroundImage: `url(${bannerUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-white text-4xl font-thin">{title}</p>
            </div>
          </div>
        )}

        {error && (
          <div className="w-full text-center bg-red-200 rounded-lg p-3 my-3 border-red-500 border">
            {error.message || "An error occurred"}
          </div>
        )}

        {loading ? <Loader /> : <>{component}</>}
      </div>
    </div>
  );
};

export default FullPage;
