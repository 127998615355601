import React, { useState } from "react";
import { Runs as RunsI, Sources } from "../../../typings/energy";
import DayRun from "./DayRun";
import moment from "moment/moment";

const MonthRun = ({
  runs,
  year,
  month,
}: {
  runs: RunsI;
  year: number;
  month: number;
}) => {
  const [days] = useState(() => {
    const daysInMonth = moment({ year, month: month - 1 }).daysInMonth();
    return Array.from({ length: daysInMonth }, (_, i) =>
      moment({ year, month: month - 1, day: i + 1 }),
    );
  });

  const [sourceId, setSourceId] = useState(1);

  return (
    <div className={""}>
      <div className={"space-x-6 flex flex-row overflow-x-scroll p-3"}>
        {Sources.map((source) => (
          <button
            onClick={() => setSourceId(source.id)}
            className={`${sourceId === source.id ? "text-pink-500 font-extrabold" : ""}`}
          >
            {source.name}
          </button>
        ))}
      </div>
      {days.map((day) => (
        <DayRun day={day} />
      ))}
    </div>
  );
};

export default MonthRun;
