export const banks: Record<string, { id: string; name: string } & any> = {
  "5493eb34-5514-4aeb-ae4a-c845ef6f0664": {
    id: "5493eb34-5514-4aeb-ae4a-c845ef6f0664",
    name: "9mobile 9Payment Service Bank",
    slug: "9mobile-9payment-service-bank-ng",
    code: "120001",
    longcode: "120001",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-31T06:50:27.000Z",
    updatedAt: "2022-06-23T09:33:55.000Z",
  },
  "fe5043c8-c485-4eb3-862d-baf7f141abfd": {
    id: "fe5043c8-c485-4eb3-862d-baf7f141abfd",
    name: "Abbey Mortgage Bank",
    slug: "abbey-mortgage-bank-ng",
    code: "404",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-12-07T16:19:09.000Z",
    updatedAt: "2023-09-14T13:02:38.000Z",
  },
  "cda82c9c-962d-43a1-9f81-34152d9a5f0d": {
    id: "cda82c9c-962d-43a1-9f81-34152d9a5f0d",
    name: "Above Only MFB",
    slug: "above-only-mfb",
    code: "51204",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-10-13T20:35:17.000Z",
    updatedAt: "2021-10-13T20:35:17.000Z",
  },
  "c6344ec6-90b9-4702-b6cb-4e6cd322529b": {
    id: "c6344ec6-90b9-4702-b6cb-4e6cd322529b",
    name: "Abulesoro MFB",
    slug: "abulesoro-mfb-ng",
    code: "51312",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-08-31T08:26:20.000Z",
    updatedAt: "2022-08-31T08:26:20.000Z",
  },
  "6653299e-6f3d-4745-a431-6b9c5c750f28": {
    id: "6653299e-6f3d-4745-a431-6b9c5c750f28",
    name: "Access Bank",
    slug: "access-bank",
    code: "044",
    longcode: "044150149",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T08:06:44.000Z",
  },
  "385d7287-ee26-4328-850f-25bc7a8a2677": {
    id: "385d7287-ee26-4328-850f-25bc7a8a2677",
    name: "Access Bank (Diamond)",
    slug: "access-bank-diamond",
    code: "063",
    longcode: "063150162",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T08:06:48.000Z",
  },
  "7e4337e0-4923-4454-8aba-fdd4c483d67d": {
    id: "7e4337e0-4923-4454-8aba-fdd4c483d67d",
    name: "Accion Microfinance Bank",
    slug: "accion-microfinance-bank-ng",
    code: "602",
    longcode: "",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-07-28T14:22:56.000Z",
    updatedAt: "2022-09-19T07:48:37.000Z",
  },
  "59b63135-03e5-4466-af76-3dcbfd089027": {
    id: "59b63135-03e5-4466-af76-3dcbfd089027",
    name: "Aella MFB",
    slug: "aella-mfb-ng",
    code: "50315",
    longcode: "50315",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-09T08:11:06.000Z",
    updatedAt: "2024-07-30T10:51:33.000Z",
  },
  "bbfeaaf4-ca78-40b0-b1d7-b4c37c99d600": {
    id: "bbfeaaf4-ca78-40b0-b1d7-b4c37c99d600",
    name: "AG Mortgage Bank",
    slug: "ag-mortgage-bank-ng",
    code: "90077",
    longcode: "100028",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-06-07T13:28:00.000Z",
    updatedAt: "2024-06-07T13:28:00.000Z",
  },
  "01cb7536-8cec-48ba-a300-24856492183b": {
    id: "01cb7536-8cec-48ba-a300-24856492183b",
    name: "Ahmadu Bello University Microfinance Bank",
    slug: "ahmadu-bello-university-microfinance-bank-ng",
    code: "50036",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-11-14T13:35:42.000Z",
    updatedAt: "2022-11-14T13:35:42.000Z",
  },
  "e3a8ef2a-b61f-471a-8626-b508a5a23efc": {
    id: "e3a8ef2a-b61f-471a-8626-b508a5a23efc",
    name: "Airtel Smartcash PSB",
    slug: "airtel-smartcash-psb-ng",
    code: "120004",
    longcode: "120004",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-30T14:03:00.000Z",
    updatedAt: "2022-05-31T06:58:22.000Z",
  },
  "42081bd5-902b-46c0-9628-782890af2db2": {
    id: "42081bd5-902b-46c0-9628-782890af2db2",
    name: "AKU Microfinance Bank",
    slug: "aku-mfb",
    code: "51336",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-05-04T15:12:34.000Z",
    updatedAt: "2023-05-04T15:12:34.000Z",
  },
  "a86d2e2e-80dc-4b18-a27c-c1695eca54cd": {
    id: "a86d2e2e-80dc-4b18-a27c-c1695eca54cd",
    name: "Akuchukwu Microfinance Bank Limited",
    slug: "akuchukwu-microfinance-bank-limited-ng",
    code: "090561",
    longcode: "090561",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-07-28T14:22:56.000Z",
    updatedAt: "2023-11-03T12:09:37.000Z",
  },
  "5a420101-7627-4f3d-96f4-2dc047c99d46": {
    id: "5a420101-7627-4f3d-96f4-2dc047c99d46",
    name: "ALAT by WEMA",
    slug: "alat-by-wema",
    code: "035A",
    longcode: "035150103",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2017-11-15T12:21:31.000Z",
    updatedAt: "2022-05-31T15:54:34.000Z",
  },
  "573a21f5-86a7-47d9-b5f3-cad9c30c7d67": {
    id: "573a21f5-86a7-47d9-b5f3-cad9c30c7d67",
    name: "Amegy Microfinance Bank",
    slug: "amegy-microfinance-bank-ng",
    code: "090629",
    longcode: "090629",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-07-20T09:52:18.000Z",
    updatedAt: "2023-07-20T09:52:18.000Z",
  },
  "be4b6f12-efa3-487b-bfa3-fbc4c225c922": {
    id: "be4b6f12-efa3-487b-bfa3-fbc4c225c922",
    name: "Amju Unique MFB",
    slug: "amju-unique-mfb",
    code: "50926",
    longcode: "511080896",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-07-07T13:45:57.000Z",
    updatedAt: "2021-07-07T13:45:57.000Z",
  },
  "2a588538-88a2-47fa-a9eb-81b958285a77": {
    id: "2a588538-88a2-47fa-a9eb-81b958285a77",
    name: "AMPERSAND MICROFINANCE BANK",
    slug: "ampersand-microfinance-bank-ng",
    code: "51341",
    longcode: "",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-23T11:11:40.000Z",
    updatedAt: "2023-03-23T11:11:40.000Z",
  },
  "3c02c9dd-7696-41d1-9460-701cb7afdb93": {
    id: "3c02c9dd-7696-41d1-9460-701cb7afdb93",
    name: "Amucha MFB",
    slug: "amucha-mfb-ng",
    code: "645",
    longcode: "090645",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-27T11:10:52.000Z",
    updatedAt: "2024-08-27T11:10:52.000Z",
  },
  "a0148206-8e92-4243-ba69-90f24312f731": {
    id: "a0148206-8e92-4243-ba69-90f24312f731",
    name: "Aramoko MFB",
    slug: "aramoko-mfb",
    code: "50083",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-08-10T09:48:24.000Z",
    updatedAt: "2022-08-10T09:48:24.000Z",
  },
  "a92d94bf-e27a-4234-bb84-e7849f5cafad": {
    id: "a92d94bf-e27a-4234-bb84-e7849f5cafad",
    name: "ASO Savings and Loans",
    slug: "asosavings",
    code: "401",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2018-09-23T05:52:38.000Z",
    updatedAt: "2019-01-30T09:38:57.000Z",
  },
  "f527ad77-c8cd-4f1a-b17e-65a52fa8fcee": {
    id: "f527ad77-c8cd-4f1a-b17e-65a52fa8fcee",
    name: "Assets Microfinance Bank",
    slug: "assets-microfinance-bank-ng",
    code: "50092",
    longcode: "50092",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-21T11:36:11.000Z",
    updatedAt: "2024-08-21T11:36:11.000Z",
  },
  "bda13f75-fc51-4f6e-a7e4-efd7b8b14dc9": {
    id: "bda13f75-fc51-4f6e-a7e4-efd7b8b14dc9",
    name: "Astrapolaris MFB LTD",
    slug: "astrapolaris-mfb",
    code: "MFB50094",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-25T10:46:17.000Z",
    updatedAt: "2022-05-25T10:46:17.000Z",
  },
  "364814e9-c9be-41cf-8be0-d1037493687a": {
    id: "364814e9-c9be-41cf-8be0-d1037493687a",
    name: "AVUENEGBE MICROFINANCE BANK",
    slug: "avuenegbe-microfinance-bank-ng",
    code: "090478",
    longcode: "090478",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:19:20.000Z",
    updatedAt: "2023-12-30T06:19:20.000Z",
  },
  "204f478e-043c-4a0b-8bac-c110d664f8b7": {
    id: "204f478e-043c-4a0b-8bac-c110d664f8b7",
    name: "AWACASH MICROFINANCE BANK",
    slug: "awacash-microfinance-bank-ng",
    code: "51351",
    longcode: "51351",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-05-20T10:27:56.000Z",
    updatedAt: "2024-05-20T10:27:56.000Z",
  },
  "815a2431-f5e4-4855-a6b4-2f3b7587b9ee": {
    id: "815a2431-f5e4-4855-a6b4-2f3b7587b9ee",
    name: "AZTEC MICROFINANCE BANK LIMITED",
    slug: "aztec-microfinance-bank-limited-ng",
    code: "51337",
    longcode: "090540",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-28T14:36:20.000Z",
    updatedAt: "2024-08-28T14:36:20.000Z",
  },
  "3a82fb40-5f4a-42be-88ce-6de3d64ef5d9": {
    id: "3a82fb40-5f4a-42be-88ce-6de3d64ef5d9",
    name: "Bainescredit MFB",
    slug: "bainescredit-mfb",
    code: "51229",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-07-12T14:41:18.000Z",
    updatedAt: "2021-07-12T14:41:18.000Z",
  },
  "586a6579-e88a-4709-a074-d0efd2be95b8": {
    id: "586a6579-e88a-4709-a074-d0efd2be95b8",
    name: "Banc Corp Microfinance Bank",
    slug: "banc-corp-microfinance-bank-ng",
    code: "50117",
    longcode: "50117",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-06T15:39:58.000Z",
    updatedAt: "2023-03-06T15:39:58.000Z",
  },
  "d2922abf-9779-47d7-9497-552a1cf27f1d": {
    id: "d2922abf-9779-47d7-9497-552a1cf27f1d",
    name: "Baobab Microfinance Bank",
    slug: "baobab-microfinance-bank-ng",
    code: "MFB50992",
    longcode: "MFB50992",
    gateway: "null",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-05-08T09:06:52.000Z",
    updatedAt: "2024-05-08T09:06:52.000Z",
  },
  "36e73a2d-7710-4f83-868d-b828f3abf32f": {
    id: "36e73a2d-7710-4f83-868d-b828f3abf32f",
    name: "BellBank Microfinance Bank",
    slug: "bellbank-microfinance-bank-ng",
    code: "51100",
    longcode: "51100",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-07-02T11:56:37.000Z",
    updatedAt: "2024-07-02T11:56:37.000Z",
  },
  "bd889459-2715-4213-951d-5b4e65c4e132": {
    id: "bd889459-2715-4213-951d-5b4e65c4e132",
    name: "Benysta Microfinance Bank Limited",
    slug: "benysta-microfinance-bank-limited",
    code: "51267",
    longcode: "51267",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-07-28T14:22:56.000Z",
    updatedAt: "2024-07-04T11:12:27.000Z",
  },
  "ae1d2d43-5d91-4b81-954d-4fd1a8da797c": {
    id: "ae1d2d43-5d91-4b81-954d-4fd1a8da797c",
    name: "Beststar Microfinance Bank",
    slug: "beststar-microfinance-bank-ng",
    code: "50123",
    longcode: "090615",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-01-26T08:55:14.000Z",
    updatedAt: "2024-01-26T08:55:14.000Z",
  },
  "f76751e9-7309-4962-a4c6-e4638066b2b7": {
    id: "f76751e9-7309-4962-a4c6-e4638066b2b7",
    name: "Bowen Microfinance Bank",
    slug: "bowen-microfinance-bank",
    code: "50931",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-02-11T15:38:57.000Z",
    updatedAt: "2020-02-11T15:38:57.000Z",
  },
  "f6a63c01-9b5f-4a45-ad49-b85d0e552ea5": {
    id: "f6a63c01-9b5f-4a45-ad49-b85d0e552ea5",
    name: "Branch International Financial Services Limited",
    slug: "branch",
    code: "FC40163",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-05-04T09:49:07.000Z",
    updatedAt: "2023-05-04T09:49:07.000Z",
  },
  "032708d4-8e14-4fda-a795-c7aedc5d514c": {
    id: "032708d4-8e14-4fda-a795-c7aedc5d514c",
    name: "Carbon",
    slug: "carbon",
    code: "565",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-06-16T08:15:31.000Z",
    updatedAt: "2021-08-05T15:25:01.000Z",
  },
  "fb47bdc8-9240-4b5c-8491-100da9783dff": {
    id: "fb47bdc8-9240-4b5c-8491-100da9783dff",
    name: "Cashbridge Microfinance Bank Limited",
    slug: "cashbridge-mfb-ng",
    code: "51353",
    longcode: "51353",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-06-27T13:01:40.000Z",
    updatedAt: "2024-06-27T13:01:40.000Z",
  },
  "dc28a07f-fd2b-45b0-bdd2-1db017eeb1d2": {
    id: "dc28a07f-fd2b-45b0-bdd2-1db017eeb1d2",
    name: "CASHCONNECT MFB",
    slug: "cashconnect-mfb-ng",
    code: "865",
    longcode: "865",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-04-05T14:29:19.000Z",
    updatedAt: "2023-04-05T14:29:19.000Z",
  },
  "a8a619a3-485a-4c2e-b935-e69f316bdae4": {
    id: "a8a619a3-485a-4c2e-b935-e69f316bdae4",
    name: "CEMCS Microfinance Bank",
    slug: "cemcs-microfinance-bank",
    code: "50823",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-03-23T15:06:13.000Z",
    updatedAt: "2020-03-23T15:06:28.000Z",
  },
  "ab853d87-8740-478b-8aac-07ce548e55f8": {
    id: "ab853d87-8740-478b-8aac-07ce548e55f8",
    name: "Chanelle Microfinance Bank Limited",
    slug: "chanelle-microfinance-bank-limited-ng",
    code: "50171",
    longcode: "50171",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-02-10T13:28:38.000Z",
    updatedAt: "2022-02-10T13:28:38.000Z",
  },
  "717a69d8-ef03-4252-9253-cad6fc115dea": {
    id: "717a69d8-ef03-4252-9253-cad6fc115dea",
    name: "Chikum Microfinance bank",
    slug: "chikum-microfinance-bank-ng",
    code: "312",
    longcode: "null",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-07-03T11:21:07.000Z",
    updatedAt: "2023-07-03T11:21:07.000Z",
  },
  "f7b65d3e-e238-493b-9e74-f6907ce0153f": {
    id: "f7b65d3e-e238-493b-9e74-f6907ce0153f",
    name: "Citibank Nigeria",
    slug: "citibank-nigeria",
    code: "023",
    longcode: "023150005",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:24:02.000Z",
  },
  "062cf83a-7799-4c53-be58-c0545adac758": {
    id: "062cf83a-7799-4c53-be58-c0545adac758",
    name: "CITYCODE MORTAGE BANK",
    slug: "citycode-mortage-bank-ng",
    code: "070027",
    longcode: "070027",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:16:39.000Z",
    updatedAt: "2023-12-30T06:16:39.000Z",
  },
  "a173d26b-cd79-4af0-a1f0-b46e7c411237": {
    id: "a173d26b-cd79-4af0-a1f0-b46e7c411237",
    name: "Consumer Microfinance Bank",
    slug: "consumer-microfinance-bank-ng",
    code: "50910",
    longcode: "50910",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-28T06:18:02.000Z",
    updatedAt: "2023-03-28T06:18:02.000Z",
  },
  "2e49dbe9-42b9-41f4-88f1-165f218cf0f6": {
    id: "2e49dbe9-42b9-41f4-88f1-165f218cf0f6",
    name: "Corestep MFB",
    slug: "corestep-mfb",
    code: "50204",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-02-09T14:33:06.000Z",
    updatedAt: "2022-02-09T14:33:06.000Z",
  },
  "d5bf9b06-de0e-46f2-b139-9b916287f35c": {
    id: "d5bf9b06-de0e-46f2-b139-9b916287f35c",
    name: "Coronation Merchant Bank",
    slug: "coronation-merchant-bank-ng",
    code: "559",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-11-24T10:25:07.000Z",
    updatedAt: "2023-05-04T06:49:08.000Z",
  },
  "3bce7a2b-6921-4a8c-ae3f-95ffdd825928": {
    id: "3bce7a2b-6921-4a8c-ae3f-95ffdd825928",
    name: "County Finance Limited",
    slug: "county-finance-limited",
    code: "FC40128",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-04-26T14:24:23.000Z",
    updatedAt: "2023-04-26T14:24:23.000Z",
  },
  "876fb65e-e268-42d4-b1d7-ec42435bdc98": {
    id: "876fb65e-e268-42d4-b1d7-ec42435bdc98",
    name: "Crescent MFB",
    slug: "crescent-mfb",
    code: "51297",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-07-18T12:39:03.000Z",
    updatedAt: "2022-07-18T12:39:03.000Z",
  },
  "7873c840-b532-40ad-a43b-a19676f06ab9": {
    id: "7873c840-b532-40ad-a43b-a19676f06ab9",
    name: "Crust Microfinance Bank",
    slug: "crust-microfinance-bank-ng",
    code: "090560",
    longcode: "",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-22T09:14:25.000Z",
    updatedAt: "2023-12-12T15:46:28.000Z",
  },
  "5376bc8b-17f2-474f-bafc-524eb85107f8": {
    id: "5376bc8b-17f2-474f-bafc-524eb85107f8",
    name: "Davenport MICROFINANCE BANK",
    slug: "davenport-microfinance-bank-ng",
    code: "51334",
    longcode: "51334",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-11-20T13:30:56.000Z",
    updatedAt: "2023-11-20T13:30:56.000Z",
  },
  "3d40f07c-7579-4c48-ad14-20539825be07": {
    id: "3d40f07c-7579-4c48-ad14-20539825be07",
    name: "Dot Microfinance Bank",
    slug: "dot-microfinance-bank-ng",
    code: "50162",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-11-03T14:39:09.000Z",
    updatedAt: "2022-11-03T14:39:09.000Z",
  },
  "58515d41-0f4f-4c64-accc-f3cbe855720a": {
    id: "58515d41-0f4f-4c64-accc-f3cbe855720a",
    name: "Ecobank Nigeria",
    slug: "ecobank-nigeria",
    code: "050",
    longcode: "050150010",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:23:53.000Z",
  },
  "96e7234f-a402-4388-99a6-69dc09c5a759": {
    id: "96e7234f-a402-4388-99a6-69dc09c5a759",
    name: "Ekimogun MFB",
    slug: "ekimogun-mfb-ng",
    code: "50263",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-08-31T08:26:39.000Z",
    updatedAt: "2022-08-31T08:26:39.000Z",
  },
  "3d937a24-6bcc-4bd6-b82e-5a084317aba5": {
    id: "3d937a24-6bcc-4bd6-b82e-5a084317aba5",
    name: "Ekondo Microfinance Bank",
    slug: "ekondo-microfinance-bank-ng",
    code: "098",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2018-09-23T05:55:06.000Z",
    updatedAt: "2022-09-21T15:09:51.000Z",
  },
  "3c93fa41-30a4-420c-a37f-a6fec5e74725": {
    id: "3c93fa41-30a4-420c-a37f-a6fec5e74725",
    name: "EXCEL FINANCE BANK",
    slug: "excel-finance-bank-ng",
    code: "090678",
    longcode: "090678",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:19:49.000Z",
    updatedAt: "2023-12-30T06:19:49.000Z",
  },
  "4ec519d1-4316-4608-883e-48c353aecc4d": {
    id: "4ec519d1-4316-4608-883e-48c353aecc4d",
    name: "Eyowo",
    slug: "eyowo",
    code: "50126",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-09-07T13:52:22.000Z",
    updatedAt: "2020-11-24T10:03:21.000Z",
  },
  "2d0da4c1-e9f2-4970-b126-055f816662a8": {
    id: "2d0da4c1-e9f2-4970-b126-055f816662a8",
    name: "Fairmoney Microfinance Bank",
    slug: "fairmoney-microfinance-bank-ng",
    code: "51318",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-11-15T12:33:47.000Z",
    updatedAt: "2022-11-15T12:37:44.000Z",
  },
  "e70be763-e1c0-475d-a59b-89ce6ed358c0": {
    id: "e70be763-e1c0-475d-a59b-89ce6ed358c0",
    name: "Fedeth MFB",
    slug: "fedeth-mfb-ng",
    code: "50298",
    longcode: "090482",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-01-04T14:09:56.000Z",
    updatedAt: "2024-01-04T14:09:56.000Z",
  },
  "a35aeb80-b746-4b3f-b51e-aba67aac87c7": {
    id: "a35aeb80-b746-4b3f-b51e-aba67aac87c7",
    name: "Fidelity Bank",
    slug: "fidelity-bank",
    code: "070",
    longcode: "070150003",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2021-08-27T09:15:29.000Z",
  },
  "c3a13dd9-ec1f-4347-80ee-e6ba215a9e71": {
    id: "c3a13dd9-ec1f-4347-80ee-e6ba215a9e71",
    name: "Firmus MFB",
    slug: "firmus-mfb",
    code: "51314",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-06-01T15:33:26.000Z",
    updatedAt: "2021-06-01T15:33:26.000Z",
  },
  "9eb21bed-c84c-45d1-84ac-86646a3bd468": {
    id: "9eb21bed-c84c-45d1-84ac-86646a3bd468",
    name: "First Bank of Nigeria",
    slug: "first-bank-of-nigeria",
    code: "011",
    longcode: "011151003",
    gateway: "ibank",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2021-03-25T14:22:52.000Z",
  },
  "60ed6d04-dcd9-4d2d-82bd-9785b0820865": {
    id: "60ed6d04-dcd9-4d2d-82bd-9785b0820865",
    name: "First City Monument Bank",
    slug: "first-city-monument-bank",
    code: "214",
    longcode: "214150018",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T08:06:46.000Z",
  },
  "22127ee6-1d56-4c82-b1be-11f90153e9a1": {
    id: "22127ee6-1d56-4c82-b1be-11f90153e9a1",
    name: "FIRST ROYAL MICROFINANCE BANK",
    slug: "first-royal-microfinance-bank-ng",
    code: "090164",
    longcode: "090164",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:18:48.000Z",
    updatedAt: "2023-12-30T06:18:48.000Z",
  },
  "440930ca-ef2e-447b-9e6a-d907ae0a634f": {
    id: "440930ca-ef2e-447b-9e6a-d907ae0a634f",
    name: "FirstTrust Mortgage Bank Nigeria",
    slug: "firsttrust-mortgage-bank-nigeria-ng",
    code: "413",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-02-17T12:12:37.000Z",
    updatedAt: "2023-06-15T16:21:12.000Z",
  },
  "ab9db581-40f8-41a9-8d2d-33a836d8a174": {
    id: "ab9db581-40f8-41a9-8d2d-33a836d8a174",
    name: "FSDH Merchant Bank Limited",
    slug: "fsdh-merchant-bank-limited",
    code: "501",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-08-20T09:37:04.000Z",
    updatedAt: "2020-11-24T10:03:22.000Z",
  },
  "69b581f5-50e9-40b2-8e75-0dfce31f2b50": {
    id: "69b581f5-50e9-40b2-8e75-0dfce31f2b50",
    name: "FUTMINNA MICROFINANCE BANK",
    slug: "futminna-microfinance-bank-ng",
    code: "832",
    longcode: "832",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-10-11T13:24:17.000Z",
    updatedAt: "2023-10-11T13:24:17.000Z",
  },
  "2f308225-5830-4f20-aa70-0d1502fbc240": {
    id: "2f308225-5830-4f20-aa70-0d1502fbc240",
    name: "Garun Mallam MFB",
    slug: "garun-mallam-mfb-ng",
    code: "MFB51093",
    longcode: "MFB51093",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-23T09:24:06.000Z",
    updatedAt: "2024-08-23T09:24:06.000Z",
  },
  "024b3635-70b4-46f1-a410-ae82673cb90a": {
    id: "024b3635-70b4-46f1-a410-ae82673cb90a",
    name: "Gateway Mortgage Bank LTD",
    slug: "gateway-mortgage-bank",
    code: "812",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-02-24T06:04:39.000Z",
    updatedAt: "2022-02-24T06:04:39.000Z",
  },
  "735d00d2-4250-4ac8-8d32-4b519753dbe5": {
    id: "735d00d2-4250-4ac8-8d32-4b519753dbe5",
    name: "Globus Bank",
    slug: "globus-bank",
    code: "00103",
    longcode: "103015001",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-02-11T15:38:57.000Z",
    updatedAt: "2020-02-11T15:38:57.000Z",
  },
  "bcb69d3a-5b52-4406-baf2-a22bd87681db": {
    id: "bcb69d3a-5b52-4406-baf2-a22bd87681db",
    name: "Goldman MFB",
    slug: "goldman-mfb-ng",
    code: "090574",
    longcode: "950356",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-01-12T10:05:20.000Z",
    updatedAt: "2024-01-12T10:05:20.000Z",
  },
  "12e2a11c-75f2-43f9-9e31-0279ec62dbf9": {
    id: "12e2a11c-75f2-43f9-9e31-0279ec62dbf9",
    name: "GoMoney",
    slug: "gomoney",
    code: "100022",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-08-04T11:49:46.000Z",
    updatedAt: "2021-11-12T13:32:14.000Z",
  },
  "3f6df74a-de4a-4b1a-91ae-6f680ac56d16": {
    id: "3f6df74a-de4a-4b1a-91ae-6f680ac56d16",
    name: "GOOD SHEPHERD MICROFINANCE BANK",
    slug: "good-shepherd-microfinance-bank-ng",
    code: "090664",
    longcode: "090664",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:16:56.000Z",
    updatedAt: "2023-12-30T06:16:56.000Z",
  },
  "0d77ddd6-aec6-4f6d-87f1-778fb587e026": {
    id: "0d77ddd6-aec6-4f6d-87f1-778fb587e026",
    name: "Goodnews Microfinance Bank",
    slug: "goodnews-microfinance-bank-ng",
    code: "50739",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-29T09:14:18.000Z",
    updatedAt: "2022-10-18T14:59:07.000Z",
  },
  "434b3940-656b-429c-96b8-6d04d0ceadc9": {
    id: "434b3940-656b-429c-96b8-6d04d0ceadc9",
    name: "Greenwich Merchant Bank",
    slug: "greenwich-merchant-bank-ng",
    code: "562",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-16T15:23:58.000Z",
    updatedAt: "2022-09-16T15:23:58.000Z",
  },
  "0da0fb52-f399-4141-8b83-6e27733d4c5d": {
    id: "0da0fb52-f399-4141-8b83-6e27733d4c5d",
    name: "GROOMING MICROFINANCE BANK",
    slug: "grooming-microfinance-bank-ng",
    code: "51276",
    longcode: "51276",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-06T10:52:30.000Z",
    updatedAt: "2024-08-06T10:52:30.000Z",
  },
  "00b2561a-cfd2-40de-acdd-d60f2344066e": {
    id: "00b2561a-cfd2-40de-acdd-d60f2344066e",
    name: "Guaranty Trust Bank",
    slug: "guaranty-trust-bank",
    code: "058",
    longcode: "058152036",
    gateway: "ibank",
    pay_with_bank: true,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2023-06-22T08:50:47.000Z",
  },
  "94fec6a6-e712-454b-9ed6-f4e81d20ddda": {
    id: "94fec6a6-e712-454b-9ed6-f4e81d20ddda",
    name: "Hackman Microfinance Bank",
    slug: "hackman-microfinance-bank",
    code: "51251",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-08-20T09:32:48.000Z",
    updatedAt: "2020-11-24T10:03:24.000Z",
  },
  "dd83481d-cdda-4c1b-839b-6a933aa23719": {
    id: "dd83481d-cdda-4c1b-839b-6a933aa23719",
    name: "Hasal Microfinance Bank",
    slug: "hasal-microfinance-bank",
    code: "50383",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-02-11T15:38:57.000Z",
    updatedAt: "2020-02-11T15:38:57.000Z",
  },
  "d4708886-8748-4f87-a3bc-f67381752c40": {
    id: "d4708886-8748-4f87-a3bc-f67381752c40",
    name: "HopePSB",
    slug: "hopepsb-ng",
    code: "120002",
    longcode: "120002",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-30T14:03:18.000Z",
    updatedAt: "2022-05-30T14:03:18.000Z",
  },
  "d046a318-27ab-4c95-8407-4ab8678c19dc": {
    id: "d046a318-27ab-4c95-8407-4ab8678c19dc",
    name: "IBANK Microfinance Bank",
    slug: "IBANK-mfb",
    code: "51211",
    longcode: "090115",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-04-03T09:34:35.000Z",
    updatedAt: "2024-07-22T11:59:33.000Z",
  },
  "28d2ccd4-2ee9-4d8a-ac16-2d54d9564171": {
    id: "28d2ccd4-2ee9-4d8a-ac16-2d54d9564171",
    name: "Ibile Microfinance Bank",
    slug: "ibile-mfb",
    code: "51244",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-10-21T10:54:20.000Z",
    updatedAt: "2020-10-21T10:54:33.000Z",
  },
  "cce49c12-3a41-47e1-8a05-21515ef8dbe2": {
    id: "cce49c12-3a41-47e1-8a05-21515ef8dbe2",
    name: "Ikoyi Osun MFB",
    slug: "ikoyi-osun-mfb",
    code: "50439",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-08-10T09:48:24.000Z",
    updatedAt: "2022-08-10T09:48:24.000Z",
  },
  "d8703195-7ca4-4e5e-a2c4-9fc16a4aa57d": {
    id: "d8703195-7ca4-4e5e-a2c4-9fc16a4aa57d",
    name: "Ilaro Poly Microfinance Bank",
    slug: "ilaro-poly-microfinance-bank-ng",
    code: "50442",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-10-12T09:15:26.000Z",
    updatedAt: "2022-10-12T09:15:26.000Z",
  },
  "8a1abd14-4437-4805-a8ab-de250bff384b": {
    id: "8a1abd14-4437-4805-a8ab-de250bff384b",
    name: "Imowo MFB",
    slug: "imowo-mfb-ng",
    code: "50453",
    longcode: "50453",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-06-26T13:50:15.000Z",
    updatedAt: "2023-06-26T13:50:15.000Z",
  },
  "56291c27-dd6e-4b24-b29d-716ad5bf607c": {
    id: "56291c27-dd6e-4b24-b29d-716ad5bf607c",
    name: "IMPERIAL HOMES MORTAGE BANK",
    slug: "imperial-homes-mortage-bank-ng",
    code: "415",
    longcode: "100024",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T05:53:07.000Z",
    updatedAt: "2024-02-12T15:49:56.000Z",
  },
  "011263d3-cb05-4115-a25f-aae06beed6e4": {
    id: "011263d3-cb05-4115-a25f-aae06beed6e4",
    name: "Infinity MFB",
    slug: "infinity-mfb",
    code: "50457",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-11-24T10:23:37.000Z",
    updatedAt: "2020-11-24T10:23:37.000Z",
  },
  "7a594e81-9174-4ffd-8ddc-11229ffd8c33": {
    id: "7a594e81-9174-4ffd-8ddc-11229ffd8c33",
    name: "ISUA MFB",
    slug: "isua-mfb-ng",
    code: "090701",
    longcode: "090701",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-07-19T12:38:16.000Z",
    updatedAt: "2024-07-19T12:38:16.000Z",
  },
  "326b3716-0b9c-4ec1-b9ba-d36e554546b9": {
    id: "326b3716-0b9c-4ec1-b9ba-d36e554546b9",
    name: "Jaiz Bank",
    slug: "jaiz-bank",
    code: "301",
    longcode: "301080020",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-10-10T17:26:29.000Z",
    updatedAt: "2016-10-10T17:26:29.000Z",
  },
  "ec2fb376-3781-4ae2-8ec9-2db519137ab2": {
    id: "ec2fb376-3781-4ae2-8ec9-2db519137ab2",
    name: "Kadpoly MFB",
    slug: "kadpoly-mfb",
    code: "50502",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-09-27T11:59:42.000Z",
    updatedAt: "2021-09-27T11:59:42.000Z",
  },
  "7d060ec9-8407-4dc5-8955-4c115cdd6c56": {
    id: "7d060ec9-8407-4dc5-8955-4c115cdd6c56",
    name: "KANOPOLY MFB",
    slug: "kanopoly-mfb-ng",
    code: "51308",
    longcode: "090592",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-01-04T14:20:38.000Z",
    updatedAt: "2024-01-04T14:20:38.000Z",
  },
  "3d6bf63a-b1c6-45e1-9380-99fbd8f24984": {
    id: "3d6bf63a-b1c6-45e1-9380-99fbd8f24984",
    name: "Keystone Bank",
    slug: "keystone-bank",
    code: "082",
    longcode: "082150017",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:23:45.000Z",
  },
  "404f3f0d-17d1-447f-9ef4-1373d95318cb": {
    id: "404f3f0d-17d1-447f-9ef4-1373d95318cb",
    name: "KONGAPAY (Kongapay Technologies Limited)(formerly Zinternet)",
    slug: "kongapay-tech-ltd",
    code: "100025",
    longcode: "100025",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-07-05T13:00:17.000Z",
    updatedAt: "2024-07-05T13:00:17.000Z",
  },
  "5eff37ff-8ae3-442e-8150-171d68ea4c89": {
    id: "5eff37ff-8ae3-442e-8150-171d68ea4c89",
    name: "Kredi Money MFB LTD",
    slug: "kredi-money-mfb",
    code: "50200",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-08-11T09:54:03.000Z",
    updatedAt: "2021-08-11T09:54:03.000Z",
  },
  "7755fef6-6144-4bf8-810a-843674100589": {
    id: "7755fef6-6144-4bf8-810a-843674100589",
    name: "Kuda Bank",
    slug: "kuda-bank",
    code: "50211",
    longcode: "",
    gateway: "digitalbankmandate",
    pay_with_bank: true,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2019-11-15T17:06:54.000Z",
    updatedAt: "2023-07-19T11:26:13.000Z",
  },
  "a3104de3-f500-43b8-847f-fb6e63f95992": {
    id: "a3104de3-f500-43b8-847f-fb6e63f95992",
    name: "Lagos Building Investment Company Plc.",
    slug: "lbic-plc",
    code: "90052",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-08-10T15:07:44.000Z",
    updatedAt: "2020-08-10T15:07:44.000Z",
  },
  "4205be72-81df-49e2-9edf-c3d97b2f38b3": {
    id: "4205be72-81df-49e2-9edf-c3d97b2f38b3",
    name: "Links MFB",
    slug: "links-mfb",
    code: "50549",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-07-12T14:41:18.000Z",
    updatedAt: "2021-07-12T14:41:18.000Z",
  },
  "24342dc8-46bb-4c4a-868b-d3c5842109c7": {
    id: "24342dc8-46bb-4c4a-868b-d3c5842109c7",
    name: "Living Trust Mortgage Bank",
    slug: "living-trust-mortgage-bank",
    code: "031",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-25T10:46:17.000Z",
    updatedAt: "2022-05-25T10:46:17.000Z",
  },
  "4bca2b16-322b-44c2-8022-f4ea5176980a": {
    id: "4bca2b16-322b-44c2-8022-f4ea5176980a",
    name: "LOMA MFB",
    slug: "loma-mfb-ng",
    code: "50491",
    longcode: "090620",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-11T05:43:27.000Z",
    updatedAt: "2023-12-11T05:43:27.000Z",
  },
  "720080fb-27a1-46bd-8b48-31340fa37e6b": {
    id: "720080fb-27a1-46bd-8b48-31340fa37e6b",
    name: "Lotus Bank",
    slug: "lotus-bank",
    code: "303",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-12-06T14:39:51.000Z",
    updatedAt: "2021-12-06T14:39:51.000Z",
  },
  "8d8f72e1-ed1b-4727-b3f1-e75197d2cdf5": {
    id: "8d8f72e1-ed1b-4727-b3f1-e75197d2cdf5",
    name: "MAINSTREET MICROFINANCE BANK",
    slug: "mainstreet-microfinance-bank-ng",
    code: "090171",
    longcode: "090171",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:20:20.000Z",
    updatedAt: "2023-12-30T06:20:20.000Z",
  },
  "dbd142b1-a963-4d88-a35b-d244fa9e1dea": {
    id: "dbd142b1-a963-4d88-a35b-d244fa9e1dea",
    name: "Mayfair MFB",
    slug: "mayfair-mfb",
    code: "50563",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-02-02T08:28:38.000Z",
    updatedAt: "2021-02-02T08:28:38.000Z",
  },
  "3b3bfe35-2e1d-4c9e-a050-172426367472": {
    id: "3b3bfe35-2e1d-4c9e-a050-172426367472",
    name: "Mint MFB",
    slug: "mint-mfb",
    code: "50304",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-06-01T16:07:29.000Z",
    updatedAt: "2021-06-01T16:07:29.000Z",
  },
  "21368057-d3f6-481f-b945-21f8870bfc10": {
    id: "21368057-d3f6-481f-b945-21f8870bfc10",
    name: "Money Master PSB",
    slug: "money-master-psb-ng",
    code: "946",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-09-08T14:47:32.000Z",
    updatedAt: "2023-09-14T12:56:51.000Z",
  },
  "e671d466-dbe2-4ff8-805d-b1eee89cb14e": {
    id: "e671d466-dbe2-4ff8-805d-b1eee89cb14e",
    name: "Moniepoint MFB",
    slug: "moniepoint-mfb-ng",
    code: "50515",
    longcode: "null",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-20T12:53:58.000Z",
    updatedAt: "2023-03-20T12:53:58.000Z",
  },
  "e84b37fe-9469-4868-95a9-577ac0411e86": {
    id: "e84b37fe-9469-4868-95a9-577ac0411e86",
    name: "MTN Momo PSB",
    slug: "mtn-momo-psb-ng",
    code: "120003",
    longcode: "120003",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-31T06:52:07.000Z",
    updatedAt: "2022-06-23T09:33:55.000Z",
  },
  "d28e48aa-1565-4070-a3ec-bae4b15f02f7": {
    id: "d28e48aa-1565-4070-a3ec-bae4b15f02f7",
    name: "MUTUAL BENEFITS MICROFINANCE BANK",
    slug: "mutual-benefits-microfinance-bank-ng",
    code: "090190",
    longcode: "090190",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-11-21T07:41:39.000Z",
    updatedAt: "2023-11-21T07:41:39.000Z",
  },
  "5c0389db-cb28-4037-8483-47aa7ea6b4df": {
    id: "5c0389db-cb28-4037-8483-47aa7ea6b4df",
    name: "NDCC MICROFINANCE BANK",
    slug: "ndcc-microfinance-bank-ng",
    code: "090679",
    longcode: "090679",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:19:06.000Z",
    updatedAt: "2023-12-30T06:19:06.000Z",
  },
  "e3e4ec82-c3e5-4aa4-8f36-eb63937664d3": {
    id: "e3e4ec82-c3e5-4aa4-8f36-eb63937664d3",
    name: "NET MICROFINANCE BANK",
    slug: "net-microfinance-bank-ng",
    code: "51361",
    longcode: "51361",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-04-12T09:27:39.000Z",
    updatedAt: "2024-04-12T09:27:39.000Z",
  },
  "1a87cf16-353c-4a60-87e7-f896088a1802": {
    id: "1a87cf16-353c-4a60-87e7-f896088a1802",
    name: "Nigerian Navy Microfinance Bank Limited",
    slug: "nigerian-navy-microfinance-bank-limited-ng",
    code: "51142",
    longcode: "090263",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-03-19T14:47:56.000Z",
    updatedAt: "2024-03-19T14:47:56.000Z",
  },
  "62aba527-95e9-49ad-b646-8097ee4fba8c": {
    id: "62aba527-95e9-49ad-b646-8097ee4fba8c",
    name: "NPF MICROFINANCE BANK",
    slug: "npf-microfinance-bank-ng",
    code: "50629",
    longcode: "50629",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-09-12T11:57:02.000Z",
    updatedAt: "2023-09-12T19:24:09.000Z",
  },
  "5bb90ad6-bd33-458d-9148-bc95439a9aa6": {
    id: "5bb90ad6-bd33-458d-9148-bc95439a9aa6",
    name: "OPay Digital Services Limited (OPay)",
    slug: "paycom",
    code: "999992",
    longcode: "",
    gateway: "ibank",
    pay_with_bank: true,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-11-24T10:20:45.000Z",
    updatedAt: "2023-10-06T09:16:49.000Z",
  },
  "05ebecaa-58d8-442d-87c6-28d32164a6a9": {
    id: "05ebecaa-58d8-442d-87c6-28d32164a6a9",
    name: "Optimus Bank Limited",
    slug: "optimus-bank-ltd",
    code: "107",
    longcode: "00107",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-05-08T08:03:03.000Z",
    updatedAt: "2023-06-15T16:21:12.000Z",
  },
  "b28d702a-e044-4b81-85ad-00a096e48be9": {
    id: "b28d702a-e044-4b81-85ad-00a096e48be9",
    name: "Paga",
    slug: "paga",
    code: "100002",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-08-31T08:10:00.000Z",
    updatedAt: "2021-08-31T08:10:00.000Z",
  },
  "75377a69-a6ab-446c-bc9d-5ab2af5a3311": {
    id: "75377a69-a6ab-446c-bc9d-5ab2af5a3311",
    name: "PalmPay",
    slug: "palmpay",
    code: "999991",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-11-24T09:58:37.000Z",
    updatedAt: "2020-11-24T10:03:19.000Z",
  },
  "267c61d1-2050-4dce-90d1-0acc4e3390ab": {
    id: "267c61d1-2050-4dce-90d1-0acc4e3390ab",
    name: "Parallex Bank",
    slug: "parallex-bank",
    code: "104",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2017-03-31T13:54:29.000Z",
    updatedAt: "2021-10-29T08:00:19.000Z",
  },
  "6b4d86c4-7d15-4279-88dd-e63febb7628b": {
    id: "6b4d86c4-7d15-4279-88dd-e63febb7628b",
    name: "Parkway - ReadyCash",
    slug: "parkway-ready-cash",
    code: "311",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-08-10T15:07:44.000Z",
    updatedAt: "2020-08-10T15:07:44.000Z",
  },
  "f157dde1-ec50-44e4-af1d-1c725c01497c": {
    id: "f157dde1-ec50-44e4-af1d-1c725c01497c",
    name: "PATHFINDER MICROFINANCE BANK LIMITED",
    slug: "pathfinder-microfinance-bank-limited-ng",
    code: "090680",
    longcode: "090680",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:20:10.000Z",
    updatedAt: "2023-12-30T06:20:10.000Z",
  },
  "ed4d6872-4c54-4e76-ba3f-a816146463eb": {
    id: "ed4d6872-4c54-4e76-ba3f-a816146463eb",
    name: "Paystack-Titan",
    slug: "titan-paystack",
    code: "100039",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-02T08:51:15.000Z",
    updatedAt: "2024-03-26T14:31:05.000Z",
  },
  "8272427c-50d5-4804-97de-b446ea62b2fc": {
    id: "8272427c-50d5-4804-97de-b446ea62b2fc",
    name: "Peace Microfinance Bank",
    slug: "peace-microfinance-bank-ng",
    code: "50743",
    longcode: "50743",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-04-12T16:51:04.000Z",
    updatedAt: "2023-04-12T16:51:04.000Z",
  },
  "e9cc7dba-9151-4a1d-ba76-8ac5614d14a2": {
    id: "e9cc7dba-9151-4a1d-ba76-8ac5614d14a2",
    name: "PECANTRUST MICROFINANCE BANK LIMITED",
    slug: "pecantrust-microfinance-bank-limited-ng",
    code: "51226",
    longcode: "51226",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-04-05T10:24:20.000Z",
    updatedAt: "2024-04-05T10:24:20.000Z",
  },
  "221e0eb8-d6ee-4718-b4c7-8caef7666a9e": {
    id: "221e0eb8-d6ee-4718-b4c7-8caef7666a9e",
    name: "Personal Trust MFB",
    slug: "personal-trust-mfb-ng",
    code: "51146",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-02-17T12:13:28.000Z",
    updatedAt: "2023-02-17T12:13:28.000Z",
  },
  "3fe739c4-01f6-443e-8d38-336da33a6da0": {
    id: "3fe739c4-01f6-443e-8d38-336da33a6da0",
    name: "Petra Mircofinance Bank Plc",
    slug: "petra-microfinance-bank-plc",
    code: "50746",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-11-24T10:03:06.000Z",
    updatedAt: "2020-11-24T10:03:06.000Z",
  },
  "cf72fade-5500-4f0f-aa46-ba755228b566": {
    id: "cf72fade-5500-4f0f-aa46-ba755228b566",
    name: "PFI FINANCE COMPANY LIMITED",
    slug: "pfi-finance-company-limited-ng",
    code: "050021",
    longcode: "050021",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:20:00.000Z",
    updatedAt: "2023-12-30T06:20:00.000Z",
  },
  "85910b83-4693-434d-aed4-b0a3fda05c67": {
    id: "85910b83-4693-434d-aed4-b0a3fda05c67",
    name: "Platinum Mortgage Bank",
    slug: "platinum-mortgage-bank-ng",
    code: "268",
    longcode: "null",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-07-03T11:21:22.000Z",
    updatedAt: "2023-07-03T11:21:22.000Z",
  },
  "b2651655-ac08-4218-b116-1f5e23f2f623": {
    id: "b2651655-ac08-4218-b116-1f5e23f2f623",
    name: "Pocket App",
    slug: "pocket",
    code: "00716",
    longcode: "00716",
    gateway: "digitalbankmandate",
    pay_with_bank: true,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-01-18T16:56:20.000Z",
    updatedAt: "2024-01-19T14:24:07.000Z",
  },
  "92792f0b-6acd-46d9-9a6f-9bda77d38d37": {
    id: "92792f0b-6acd-46d9-9a6f-9bda77d38d37",
    name: "Polaris Bank",
    slug: "polaris-bank",
    code: "076",
    longcode: "076151006",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2016-07-14T10:04:29.000Z",
  },
  "43cfb0de-e793-4993-bdcc-2759d711aa72": {
    id: "43cfb0de-e793-4993-bdcc-2759d711aa72",
    name: "Polyunwana MFB",
    slug: "polyunwana-mfb-ng",
    code: "50864",
    longcode: "null",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-08-17T17:27:23.000Z",
    updatedAt: "2022-08-17T17:27:23.000Z",
  },
  "3f58b824-b8b7-4c31-9e80-3bd79c45e494": {
    id: "3f58b824-b8b7-4c31-9e80-3bd79c45e494",
    name: "PremiumTrust Bank",
    slug: "premiumtrust-bank-ng",
    code: "105",
    longcode: "000031",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-06-01T14:16:02.000Z",
    updatedAt: "2022-08-17T08:13:08.000Z",
  },
  "18c7cc95-6a25-4f42-8148-61ff00c3b139": {
    id: "18c7cc95-6a25-4f42-8148-61ff00c3b139",
    name: "Prospa Capital Microfinance Bank",
    slug: "prospa-capital-microfinance-bank-ng",
    code: "50739",
    longcode: "50739",
    gateway: "null",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-13T08:14:03.000Z",
    updatedAt: "2024-08-13T08:14:03.000Z",
  },
  "c9fd4806-32fa-42a0-b906-70532bd6920c": {
    id: "c9fd4806-32fa-42a0-b906-70532bd6920c",
    name: "PROSPERIS FINANCE LIMITED",
    slug: "prosperis-finance-limited-ng",
    code: "050023",
    longcode: "050023",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:19:33.000Z",
    updatedAt: "2023-12-30T06:19:33.000Z",
  },
  "dae07ba6-d235-4c4f-a725-f2b0c7718c1d": {
    id: "dae07ba6-d235-4c4f-a725-f2b0c7718c1d",
    name: "Providus Bank",
    slug: "providus-bank",
    code: "101",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2017-03-27T16:09:29.000Z",
    updatedAt: "2021-02-09T17:50:06.000Z",
  },
  "4ef79065-d076-457a-8164-91d6cc0909d5": {
    id: "4ef79065-d076-457a-8164-91d6cc0909d5",
    name: "QuickFund MFB",
    slug: "quickfund-mfb",
    code: "51293",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-10-29T08:43:35.000Z",
    updatedAt: "2021-10-29T08:43:35.000Z",
  },
  "6314d982-4562-4ff5-ae05-c232f0f225b2": {
    id: "6314d982-4562-4ff5-ae05-c232f0f225b2",
    name: "Rand Merchant Bank",
    slug: "rand-merchant-bank",
    code: "502",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-02-11T17:33:20.000Z",
    updatedAt: "2021-02-11T17:33:20.000Z",
  },
  "e44b3ec9-7f0e-4f7b-ad71-1641e782661f": {
    id: "e44b3ec9-7f0e-4f7b-ad71-1641e782661f",
    name: "RANDALPHA MICROFINANCE BANK",
    slug: "randalpha-microfinance-bank-ng",
    code: "090496",
    longcode: "090496",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:20:40.000Z",
    updatedAt: "2023-12-30T06:20:40.000Z",
  },
  "56da5af5-456c-4c0c-9cf0-cd12a4ddace8": {
    id: "56da5af5-456c-4c0c-9cf0-cd12a4ddace8",
    name: "Refuge Mortgage Bank",
    slug: "refuge-mortgage-bank",
    code: "90067",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-05-25T10:46:17.000Z",
    updatedAt: "2022-05-25T10:46:17.000Z",
  },
  "0d59e340-82df-4a0e-b44c-524279b677d8": {
    id: "0d59e340-82df-4a0e-b44c-524279b677d8",
    name: "REHOBOTH MICROFINANCE BANK",
    slug: "rehoboth-microfinance-bank-ng",
    code: "50761",
    longcode: "090463",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-02-02T08:58:01.000Z",
    updatedAt: "2024-02-02T08:58:01.000Z",
  },
  "a9864cc5-feb7-437c-a646-7d54521797b5": {
    id: "a9864cc5-feb7-437c-a646-7d54521797b5",
    name: "Rephidim Microfinance Bank",
    slug: "rephidim",
    code: "50994",
    longcode: "221151615",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-08-21T08:22:46.000Z",
    updatedAt: "2023-08-21T08:22:46.000Z",
  },
  "9bf20619-e778-4240-8c52-14e5bbbf0470": {
    id: "9bf20619-e778-4240-8c52-14e5bbbf0470",
    name: "Rigo Microfinance Bank Limited",
    slug: "rigo-microfinance-bank-limited-ng",
    code: "51286",
    longcode: "51286",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-05-26T14:45:26.000Z",
    updatedAt: "2023-05-26T14:45:26.000Z",
  },
  "149793c8-c889-4752-981c-eeb728ebdbe8": {
    id: "149793c8-c889-4752-981c-eeb728ebdbe8",
    name: "ROCKSHIELD MICROFINANCE BANK",
    slug: "rockshield-microfinance-bank-ng",
    code: "50767",
    longcode: "",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-12-20T13:41:50.000Z",
    updatedAt: "2022-12-20T14:27:14.000Z",
  },
  "69239805-1daa-41b4-ac21-de31eb2d95ae": {
    id: "69239805-1daa-41b4-ac21-de31eb2d95ae",
    name: "Rubies MFB",
    slug: "rubies-mfb",
    code: "125",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-01-25T09:49:59.000Z",
    updatedAt: "2020-01-25T09:49:59.000Z",
  },
  "3c28b355-39ec-4b49-8c21-1bc3a5cf8541": {
    id: "3c28b355-39ec-4b49-8c21-1bc3a5cf8541",
    name: "Safe Haven MFB",
    slug: "safe-haven-mfb-ng",
    code: "51113",
    longcode: "51113",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-02-18T13:11:59.000Z",
    updatedAt: "2022-02-18T13:11:59.000Z",
  },
  "03ea5be2-bf27-4067-8255-b3e119b03875": {
    id: "03ea5be2-bf27-4067-8255-b3e119b03875",
    name: "Safe Haven Microfinance Bank Limited",
    slug: "safe-haven-microfinance-bank-limited-ng",
    code: "951113",
    longcode: "",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-07-28T14:22:56.000Z",
    updatedAt: "2022-12-02T10:51:53.000Z",
  },
  "b83560b4-18a5-4bb5-bd79-bbb77d76c9e3": {
    id: "b83560b4-18a5-4bb5-bd79-bbb77d76c9e3",
    name: "SAGE GREY FINANCE LIMITED",
    slug: "sage-grey-finance-limited-ng",
    code: "40165",
    longcode: "null",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-07-05T08:27:27.000Z",
    updatedAt: "2023-07-05T08:27:27.000Z",
  },
  "bf0ebeed-ccc1-49b2-a598-59cdd6a72978": {
    id: "bf0ebeed-ccc1-49b2-a598-59cdd6a72978",
    name: "Shield MFB",
    slug: "shield-mfb-ng",
    code: "50582",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-16T15:16:47.000Z",
    updatedAt: "2022-09-16T15:16:47.000Z",
  },
  "1fffa32c-71ce-45eb-ad47-47a473fce519": {
    id: "1fffa32c-71ce-45eb-ad47-47a473fce519",
    name: "Signature Bank Ltd",
    slug: "signature-bank-ltd-ng",
    code: "106",
    longcode: "000034",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-06T08:58:35.000Z",
    updatedAt: "2023-12-06T08:58:35.000Z",
  },
  "37bcc84a-b15f-4692-ad5d-29e1409fa986": {
    id: "37bcc84a-b15f-4692-ad5d-29e1409fa986",
    name: "Solid Allianze MFB",
    slug: "solid-allianze-mfb",
    code: "51062",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-04-26T15:02:23.000Z",
    updatedAt: "2023-04-26T15:02:23.000Z",
  },
  "f1cef6f9-b148-4809-80cd-735b38a4a23f": {
    id: "f1cef6f9-b148-4809-80cd-735b38a4a23f",
    name: "Solid Rock MFB",
    slug: "solid-rock-mfb",
    code: "50800",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-06-27T10:24:28.000Z",
    updatedAt: "2022-06-27T10:24:28.000Z",
  },
  "09fecd72-c467-40bb-9e8d-710e203af156": {
    id: "09fecd72-c467-40bb-9e8d-710e203af156",
    name: "Sparkle Microfinance Bank",
    slug: "sparkle-microfinance-bank",
    code: "51310",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-02-11T18:43:14.000Z",
    updatedAt: "2020-02-11T18:43:14.000Z",
  },
  "7d8875a6-eea4-443c-94e1-5fff3004a7f4": {
    id: "7d8875a6-eea4-443c-94e1-5fff3004a7f4",
    name: "Stanbic IBTC Bank",
    slug: "stanbic-ibtc-bank",
    code: "221",
    longcode: "221159522",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:24:17.000Z",
  },
  "1e376b37-aa8b-4a6d-98f8-90a8db582e34": {
    id: "1e376b37-aa8b-4a6d-98f8-90a8db582e34",
    name: "Standard Chartered Bank",
    slug: "standard-chartered-bank",
    code: "068",
    longcode: "068150015",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:23:40.000Z",
  },
  "8b34da1b-14a9-4908-bb27-2676830debde": {
    id: "8b34da1b-14a9-4908-bb27-2676830debde",
    name: "STANFORD MICROFINANCE BANK",
    slug: "stanford-microfinance-bank-ng",
    code: "090162",
    longcode: "090162",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-12-30T06:20:30.000Z",
    updatedAt: "2023-12-30T06:20:30.000Z",
  },
  "8d9dbc93-2430-4634-b324-cf56434d558b": {
    id: "8d9dbc93-2430-4634-b324-cf56434d558b",
    name: "STATESIDE MICROFINANCE BANK",
    slug: "stateside-microfinance-bank-ng",
    code: "50809",
    longcode: "50809",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-05-21T10:21:20.000Z",
    updatedAt: "2024-05-21T10:21:20.000Z",
  },
  "b9cda85a-0038-486b-9a68-6944927e6076": {
    id: "b9cda85a-0038-486b-9a68-6944927e6076",
    name: "Stellas MFB",
    slug: "stellas-mfb",
    code: "51253",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-02-17T14:54:01.000Z",
    updatedAt: "2022-02-17T14:54:01.000Z",
  },
  "1992eb3c-1e2d-4d61-b4fb-13bd85c68c12": {
    id: "1992eb3c-1e2d-4d61-b4fb-13bd85c68c12",
    name: "Sterling Bank",
    slug: "sterling-bank",
    code: "232",
    longcode: "232150016",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2022-05-27T08:56:01.000Z",
  },
  "07e95667-72c3-4bb1-9893-8a56f673861a": {
    id: "07e95667-72c3-4bb1-9893-8a56f673861a",
    name: "Suntrust Bank",
    slug: "suntrust-bank",
    code: "100",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-10-10T17:26:29.000Z",
    updatedAt: "2016-10-10T17:26:29.000Z",
  },
  "afaabb38-edd9-431c-a5b1-a34d1b7a5425": {
    id: "afaabb38-edd9-431c-a5b1-a34d1b7a5425",
    name: "Supreme MFB",
    slug: "supreme-mfb-ng",
    code: "50968",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-16T15:16:29.000Z",
    updatedAt: "2022-09-16T15:16:29.000Z",
  },
  "8afc7cba-b735-47f0-b63a-c5786684551c": {
    id: "8afc7cba-b735-47f0-b63a-c5786684551c",
    name: "TAJ Bank",
    slug: "taj-bank",
    code: "302",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-01-20T11:20:32.000Z",
    updatedAt: "2020-01-20T11:20:32.000Z",
  },
  "9d16dc55-4a4f-4575-aaad-a8768afb706c": {
    id: "9d16dc55-4a4f-4575-aaad-a8768afb706c",
    name: "Tangerine Money",
    slug: "tangerine-money",
    code: "51269",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2021-09-17T13:25:16.000Z",
    updatedAt: "2021-09-17T13:25:16.000Z",
  },
  "85b61292-de2f-4c01-9595-24d3afac373f": {
    id: "85b61292-de2f-4c01-9595-24d3afac373f",
    name: "The Alternative bank",
    slug: "the-alternative-bank-ng",
    code: "000304",
    longcode: "000304",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-08-06T12:31:06.000Z",
    updatedAt: "2024-08-06T12:31:06.000Z",
  },
  "616ad929-d842-4dd4-ae54-1e447c765761": {
    id: "616ad929-d842-4dd4-ae54-1e447c765761",
    name: "Titan Bank",
    slug: "titan-bank",
    code: "102",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-03-10T11:41:36.000Z",
    updatedAt: "2020-03-23T15:06:29.000Z",
  },
  "ca5d8bd2-2743-469e-86dd-c5424b33c1a8": {
    id: "ca5d8bd2-2743-469e-86dd-c5424b33c1a8",
    name: "TransPay MFB",
    slug: "transpay-mfb-ng",
    code: "090708",
    longcode: "090708",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-07-19T12:38:48.000Z",
    updatedAt: "2024-07-19T12:38:48.000Z",
  },
  "2c0504a7-ce64-4104-9f8c-ad9687553775": {
    id: "2c0504a7-ce64-4104-9f8c-ad9687553775",
    name: "U&C Microfinance Bank Ltd (U AND C MFB)",
    slug: "uc-microfinance-bank-ltd-u-and-c-mfb-ng",
    code: "50840",
    longcode: "50840",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-03-27T16:55:53.000Z",
    updatedAt: "2023-03-27T16:55:53.000Z",
  },
  "e5addfc4-6fc8-4052-8805-2fcdc69d9334": {
    id: "e5addfc4-6fc8-4052-8805-2fcdc69d9334",
    name: "UCEE MFB",
    slug: "ucee-mfb-ng",
    code: "090706",
    longcode: "090706",
    gateway: "",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2024-07-19T12:37:46.000Z",
    updatedAt: "2024-07-19T12:37:46.000Z",
  },
  "7dfd7bf8-be75-4ce7-b603-c568b93c07af": {
    id: "7dfd7bf8-be75-4ce7-b603-c568b93c07af",
    name: "Uhuru MFB",
    slug: "uhuru-mfb-ng",
    code: "51322",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-09-14T12:58:20.000Z",
    updatedAt: "2023-09-05T17:23:38.000Z",
  },
  "81d2ed99-af0d-462b-8278-632b693510c3": {
    id: "81d2ed99-af0d-462b-8278-632b693510c3",
    name: "Unaab Microfinance Bank Limited",
    slug: "unaab-microfinance-bank-limited-ng",
    code: "50870",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-11-24T13:47:10.000Z",
    updatedAt: "2022-11-24T13:49:16.000Z",
  },
  "ed7f1a55-6f6c-4616-a661-2acfb0d2f9ee": {
    id: "ed7f1a55-6f6c-4616-a661-2acfb0d2f9ee",
    name: "Unical MFB",
    slug: "unical-mfb",
    code: "50871",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-01-10T09:52:47.000Z",
    updatedAt: "2022-01-10T09:52:47.000Z",
  },
  "312b6e25-7234-496c-80c1-4573aeea355c": {
    id: "312b6e25-7234-496c-80c1-4573aeea355c",
    name: "Unilag Microfinance Bank",
    slug: "unilag-microfinance-bank-ng",
    code: "51316",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2022-11-07T07:41:50.000Z",
    updatedAt: "2022-11-07T07:41:50.000Z",
  },
  "7dc51df4-223c-4708-8a5e-fd5cf910db01": {
    id: "7dc51df4-223c-4708-8a5e-fd5cf910db01",
    name: "Union Bank of Nigeria",
    slug: "union-bank-of-nigeria",
    code: "032",
    longcode: "032080474",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2020-02-18T20:22:54.000Z",
  },
  "4dd0b1d3-3f31-4d2b-b626-d3137857491c": {
    id: "4dd0b1d3-3f31-4d2b-b626-d3137857491c",
    name: "United Bank For Africa",
    slug: "united-bank-for-africa",
    code: "033",
    longcode: "033153513",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2022-03-09T10:28:57.000Z",
  },
  "4d8e4ca5-0a2b-4230-918e-1c744184c2f1": {
    id: "4d8e4ca5-0a2b-4230-918e-1c744184c2f1",
    name: "Unity Bank",
    slug: "unity-bank",
    code: "215",
    longcode: "215154097",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2019-07-22T12:44:02.000Z",
  },
  "8ca20f11-0fbb-41ec-938b-e4aa211a006b": {
    id: "8ca20f11-0fbb-41ec-938b-e4aa211a006b",
    name: "Uzondu Microfinance Bank Awka Anambra State",
    slug: "uzondu-microfinance-bank-awka-anambra-state-ng",
    code: "50894",
    longcode: "50894",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-10-31T13:19:39.000Z",
    updatedAt: "2023-10-31T13:19:39.000Z",
  },
  "cd87ee7f-057b-4986-966c-1917f1b5b51b": {
    id: "cd87ee7f-057b-4986-966c-1917f1b5b51b",
    name: "Vale Finance Limited",
    slug: "vale-finance",
    code: "050020",
    longcode: "",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-10-26T14:23:46.000Z",
    updatedAt: "2023-10-26T14:23:46.000Z",
  },
  "6c9ba9d0-d41f-4cc6-92f5-1eebeaf6e7fd": {
    id: "6c9ba9d0-d41f-4cc6-92f5-1eebeaf6e7fd",
    name: "VFD Microfinance Bank Limited",
    slug: "vfd",
    code: "566",
    longcode: "",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2020-02-11T15:44:11.000Z",
    updatedAt: "2020-10-28T09:42:08.000Z",
  },
  "f623ebfe-bfd3-4a43-9baa-b70dc71a925e": {
    id: "f623ebfe-bfd3-4a43-9baa-b70dc71a925e",
    name: "Waya Microfinance Bank",
    slug: "waya-microfinance-bank-ng",
    code: "51355",
    longcode: "51355",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2023-05-31T15:50:49.000Z",
    updatedAt: "2023-05-31T15:50:49.000Z",
  },
  "a3ac0960-55a4-459f-a319-1b61cb9c593c": {
    id: "a3ac0960-55a4-459f-a319-1b61cb9c593c",
    name: "Wema Bank",
    slug: "wema-bank",
    code: "035",
    longcode: "035150103",
    gateway: null,
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2021-02-09T17:49:59.000Z",
  },
  "e4f40527-ad43-4364-b6f4-a43645220225": {
    id: "e4f40527-ad43-4364-b6f4-a43645220225",
    name: "Zenith Bank",
    slug: "zenith-bank",
    code: "057",
    longcode: "057150013",
    gateway: "emandate",
    pay_with_bank: false,
    supports_transfer: true,
    active: true,
    country: "Nigeria",
    currency: "NGN",
    type: "nuban",
    is_deleted: false,
    createdAt: "2016-07-14T10:04:29.000Z",
    updatedAt: "2023-09-26T17:09:43.000Z",
  },
};
