import React from "react";
import { formatPrice } from "../../../../utils/validation";
import { Cart } from "../Kitchen";

interface Interface {
  cart: Cart;
  onClick: () => void;
}

const ViewBasket = ({ cart, onClick }: Interface) => {
  const totalQty = Array.from(cart.values()).reduce(
    (sum, item) => sum + item.quantity,
    0,
  );

  const totalPrice = Array.from(cart.values()).reduce(
    (sum, item) => sum + item.quantity * item.food.rate,
    0,
  );

  return (
    <button
      onClick={onClick}
      className={
        "absolute mx-auto max-w-[600px] bottom-[env(safe-area-inset-bottom)] mb-9 right-0 left-0 px-6 z-10"
      }
    >
      <div
        className={
          "relative text-white p-4 text-center w-full bg-pink-500 rounded-full shadow-2xl flex flex-row justify-center items-center"
        }
      >
        <div className={"absolute left-3"}>
          <div className={"relative"}>
            <i className="bx bxs-cart-alt text-2xl"></i>
            <p
              className={
                "bg-white text-xs h-5 w-5 rounded-full text-black absolute -top-1 -right-3 text-center flex flex-row items-center justify-center"
              }
            >
              {totalQty}
            </p>
          </div>
        </div>
        <p className={"font-extrabold text-lg"}>View basket</p>
        {totalPrice > 0 && (
          <p className={"absolute right-3 text-xs font-extrabold"}>
            {formatPrice(totalPrice)}
          </p>
        )}
      </div>
    </button>
  );
};

export default ViewBasket;
