import React, { useEffect, useMemo, useState } from "react";
import FullPage from "../../../components/FullPage";
import Button from "../../../components/Button";
import PillSelector from "../../../components/PillSelector";
import {
  months,
  Reservation,
  ReservationStatus,
} from "../../../typings/reservation";
import { get } from "../../../api/database";
import { Customer } from "../../../typings/customer";
import { totTitleCase } from "../../../utils/validation";
import moment from "moment";
import { Room } from "../../../typings/rooms";
import { defaultImage } from "../../guest/lounge/GuestLounge";

const Reservations = () => {
  const [selectedMonths, setSelectedMonths] = useState<string[]>([
    String(new Date().getMonth() + 1),
  ]);

  const [rooms, setRooms] = useState<Room[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [reservationsFiltered, setReservationsFiltered] = useState<
    Reservation[]
  >([]);

  const [customers, setCustomers] = useState<Customer[]>([]);

  useEffect(() => {
    get<Room[]>("rooms").then(setRooms).catch(console.log);
  }, []);

  useEffect(() => {
    get<Customer[]>("customers").then(setCustomers).catch(console.log);
  }, []);

  useEffect(() => {
    get<Reservation[]>("reservations").then(setReservations).catch(console.log);
  }, []);

  // Filter reservations based on selected months
  useEffect(() => {
    if (selectedMonths.length === 0) {
      setReservationsFiltered(reservations);
      return;
    }

    const filtered = reservations
      .filter((reservation) => {
        return reservation.bookings.some((booking) => {
          const checkInMonth = moment(booking.checkInDateTime).month() + 1; // Extract month from checkInDateTime
          return selectedMonths.includes(String(checkInMonth));
        });
      })
      .sort((a, b) => {
        // Get timestamps for the earliest checkInDateTime in each reservation's bookings
        const aEarliestDate = Math.min(
          ...a.bookings.map((booking) =>
            moment(booking.checkInDateTime).valueOf(),
          ),
        );
        const bEarliestDate = Math.min(
          ...b.bookings.map((booking) =>
            moment(booking.checkInDateTime).valueOf(),
          ),
        );

        return bEarliestDate - aEarliestDate; // Descending order
      });

    setReservationsFiltered(filtered);
  }, [reservations, selectedMonths]); // Run effect when reservations or selectedMonths change

  const customersMapById = useMemo(() => {
    return new Map(customers.map((customer) => [customer.uuid, customer]));
  }, [customers]);

  const roomsMapById = useMemo(() => {
    return new Map(rooms.map((room) => [room.uuid, room]));
  }, [rooms]);

  const getStatusBg = (status: ReservationStatus) => {
    switch (status) {
      case "PENDING":
        return "text-yellow-700";
      case "CONFIRMED":
        return "text-pink-500";
      case "CANCELLED":
        return "text-red-700";
      case "CHECKED_IN":
        return "text-green-700";
      case "CHECKED_OUT":
        return "text-purple-500";
      default:
        return "text-black";
    }
  };
  return (
    <FullPage
      title={"MANAGE RESERVATIONS"}
      back={"/host"}
      bannerUrl={
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRczxbLMfKf36E83MA9xVZEJeFh4QEWO4O_IQ&s"
      }
      component={
        <div className={"text-sm"}>
          <div className={"flex flex-row justify-end my-9 space-x-3"}>
            <a href={"/host/calendar"}>
              <Button type={"secondary"} text={"View Calendar"} />
            </a>
            <a href={"/host/create-reservation"}>
              <Button type={"primary"} text={"Create New"} />
            </a>
          </div>
          <p className={"mb-2"}>Filter by month</p>
          <PillSelector
            selectedIds={selectedMonths}
            multiple={true}
            items={months}
            onChange={setSelectedMonths}
          />
          <div>
            <p className={"text-gray-700 text-xs mt-9 mb-3"}>RESERVATIONS</p>

            <div className={"rounded-xl shadow-xl overflow-hidden"}>
              {reservationsFiltered.map((reservation, index) => {
                const customer = customersMapById.get(reservation.customerUuid);
                return (
                  <a
                    href={"/host/reservations/" + reservation.id}
                    key={reservation.id}
                  >
                    <div
                      className={`flex flex-row p-3 space-x-3 py-6 ${index % 2 === 0 ? "bg-white" : "bg-gray-100"}`}
                    >
                      <div className={"w-10 h-10 flex-shrink-0"}>
                        <img
                          alt={"ProfilePhoto"}
                          src={customer?.thumbnailUrl || defaultImage}
                          className={
                            "object-cover rounded-full shadow-xl w-full h-full"
                          }
                        />
                      </div>
                      <div className={"space-y-2 w-full"}>
                        <div
                          className={
                            "flex flex-row justify-between items-center"
                          }
                        >
                          <p className={"font-extrabold"}>
                            {customer?.firstName} {customer?.lastName}
                          </p>
                          <p className={"font-extrabold"}>
                            <span className={""}>
                              <i className="bx bxs-purchase-tag-alt text-gray-500"></i>
                            </span>
                            {reservation.id}
                          </p>
                        </div>
                        {reservation.bookings.map((booking, i) => (
                          <div
                            className={"space-y-2"}
                            key={reservation.id + "-b-" + i}
                          >
                            <p className={"line-clamp-2 text-ellipsis"}>
                              {roomsMapById.get(booking.roomUuid)?.description}
                            </p>
                            <p className={"text-gray-500"}>
                              {`${moment(booking.checkInDateTime).format("ddd. MMM., Do")} -
                            ${moment(booking.checkOutDateTime).format("ddd. MMM., Do")}`}
                            </p>
                          </div>
                        ))}
                        <div className={"flex flex-row"}>
                          <p
                            className={`font-bold ${getStatusBg(reservation.status)}`}
                          >
                            <span>
                              <i className="bx bxs-report text-gray-400"></i>
                            </span>
                            {totTitleCase(reservation.status)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>

            {reservationsFiltered.length === 0 && (
              <p className={"bg-white p-3 rounded-xl shadow-xl"}>
                Nothing to show here
              </p>
            )}
          </div>
        </div>
      }
    />
  );
};

export default Reservations;
