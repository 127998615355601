import React from "react";
import {
  Booking,
  CreateDto,
  Reservation,
} from "../../../../../typings/reservation";
import { Customer } from "../../../../../typings/customer";
import { Room } from "../../../../../typings/rooms";
import {
  shortenPrice,
  validateReservation,
} from "../../../../../utils/validation";

interface Props {
  paid: number;
  total: number;
  customer?: Customer;
  reset: () => void;
  rooms: Map<string, Room>;
  onReserve: (d: CreateDto<Reservation>) => void;
  setValidate: (d: boolean) => void;
  bookings: Partial<Booking>[];
}

const Reserve = ({
  paid,
  total,
  customer,
  bookings,
  onReserve,
  rooms,
  setValidate,
}: Props) => {
  async function reserve(): Promise<void> {
    if (!customer) return;
    setValidate(true);

    const validation = validateReservation(
      bookings,
      rooms,
      total,
      paid,
      customer,
    );

    if (validation.error || !validation.isValid) {
      window.alert(validation.error);
      return;
    }

    const newReservation: CreateDto<Reservation> = {
      bookings: validation.bookings,
      customerUuid: customer.uuid,
      specialRequests: [],
      status: "CONFIRMED",
      tags: [
        `${customer.firstName} ${customer.lastName}`,
        ...validation.roomNames,
      ],
      paymentInfo: {
        paid,
        discount: Math.max(0, validation.value - total),
        deposit: 0,
        due: Math.max(0, total - paid),
        total,
        currency: "NGN",
      },
    };

    onReserve(newReservation);
  }

  return (
    <div className={"flex flex-col items-center justify-center w-full"}>
      <button
        onClick={reserve}
        className={
          "shadow-2xl bg-black text-white rounded-full p-4 px-12 font-extrabold"
        }
      >
        {`Reserve for ${shortenPrice(total)}`}
      </button>
    </div>
  );
};

export default Reserve;
