import React from "react";
import FullPage from "../../../../components/FullPage";
import Drinks from "../../../../components/Drinks";
import { GuestLink } from "../../../../constants/links";
import { Drink } from "../../../../typings/menu";

export const MenuItem = ({
  editor,
  action,
}: {
  editor: boolean;
  action: (drink: Drink, type: "patch" | "delete" | "create") => boolean;
}) => {
  return <Drinks editor={editor} action={action} />;
};

const Menu = ({
  back = GuestLink,
  editor = false,
}: {
  back?: string;
  editor?: boolean;
}) => {
  function action(
    drink: Drink,
    action: "delete" | "create" | "patch",
  ): boolean {
    return false;
  }

  return (
    <FullPage
      bannerUrl={
        "https://png.pngtree.com/thumb_back/fh260/back_our/20190625/ourmid/pngtree-icy-summer-fruit-drink-poster-banner-image_256693.jpg"
      }
      title={"FOOD AND DRINKS"}
      back={back}
      component={
        <div>
          {!editor && (
            <div
              className={
                "bg-white p-3 px-6 rounded-xl w-full mb-4 flex flex-row space-x-3"
              }
            >
              <div className={"h-8 w-8 overflow-hidden rounded-full"}>
                <img
                  alt={""}
                  className={"object-cover"}
                  src={
                    "https://static-00.iconduck.com/assets.00/food-icon-1024x1024-l5hmjc3o.png"
                  }
                />
              </div>
              <div>
                <p className={"font-extrabold"}>Need food or feeling hungry?</p>
                <p>
                  Our kitchen is opened, you can place your order{" "}
                  <span>
                    <a
                      href={"/kitchen"}
                      className={
                        "text-pink-500 underline underline-offset-4 decoration-dashed"
                      }
                    >
                      here
                    </a>
                  </span>
                </p>
              </div>
            </div>
          )}
          <MenuItem editor={editor} action={action} />
        </div>
      }
    />
  );
};

export default Menu;
