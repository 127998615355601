import React from "react";
import { Moment } from "moment";

interface Interface {
  checkIn?: Moment;
  checkOut?: Moment;
  monthMoment: Moment;
  onSelect: (d: Moment) => void;
  isDateDisabled: (d: Moment) => boolean;
}

const MonthCalendar = ({
  monthMoment,
  checkIn,
  checkOut,
  onSelect,
  isDateDisabled,
}: Interface) => {
  const startOfMonth = monthMoment.clone().startOf("month");
  const endOfMonth = monthMoment.clone().endOf("month");
  const daysInMonth = [];

  // Get leading empty cells for the first day offset
  const startDay = startOfMonth.day(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  for (let i = 0; i < startDay; i++) {
    daysInMonth.push(null); // Fill with null for empty cells before the start of the month
  }

  // Add days of the month to the grid
  for (let day = 1; day <= endOfMonth.date(); day++) {
    daysInMonth.push(day);
  }

  return (
    <div key={monthMoment.format("YYYY-MM")} className="w-full p-4">
      <p className="text-xl font-semibold mb-4 px-2">
        {monthMoment.format("MMMM YYYY")}
      </p>
      <div className="grid grid-cols-7 gap-1 text-center">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-gray-500 font-medium">
            {day}
          </div>
        ))}
        {daysInMonth.map((day, index) => {
          const date = day ? startOfMonth.clone().date(day) : null; // Clone startOfMonth before changing date
          const disabled = date ? isDateDisabled(date) : false;
          const isCheckIn = checkIn && date && checkIn.isSame(date, "day");
          const isCheckOut = checkOut && date && checkOut.isSame(date, "day");

          // Check if the date is within the selected range
          const isInRange =
            checkIn &&
            checkOut &&
            date &&
            date.isAfter(checkIn, "day") &&
            date.isBefore(checkOut, "day");

          return (
            <button
              key={index}
              disabled={disabled}
              onClick={() => date && onSelect(date)}
              className={`h-10 flex items-center justify-center ${
                disabled
                  ? "text-gray-400 line-through cursor-not-allowed"
                  : isCheckIn || isCheckOut
                    ? "bg-black text-white" // Style for checkIn and checkOut dates
                    : isInRange
                      ? "bg-gray-200 text-gray-800" // Style for dates within the range
                      : "text-gray-800" // Default style
              }`}
            >
              {day || ""}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default MonthCalendar;
