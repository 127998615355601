import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import FormEntry from "./FormEntry";
import Button from "./Button";
import Logo from "./Logo";
import { SpanLink } from "../pages/faq/FaqHome";

const JWT_KEY = "1003e1e9-efd3-4590-a206-8016ffefd518";
const JWT_SIGNATURE = "1003e1e9-efd3-4590-a206-8016ffefd518";

const allowedUsers = ["rita-0424", "olu-admin", "seun-admin"];

// Function to simulate JWT creation (without actual signing)
const createMockJWT = (payload: object) => {
  // Base64 encode the header
  const header = {
    alg: "HS256", // Mock algorithm
    typ: "JWT",
  };
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));

  return `${encodedHeader}.${encodedPayload}.${JWT_SIGNATURE}`;
};

// Example usage to create a JWT
const mockLoginRequest = async (username: string, password: string) => {
  if (
    allowedUsers.includes(
      `${username.trim().toLowerCase()}-${password.trim().toLowerCase()}`,
    )
  ) {
    // Simulate a payload with user details and an expiration time
    const payload = {
      sub: username.trim().toLowerCase(),
      name: username.trim().toLowerCase(),
      iat: Math.floor(Date.now() / 1000),
      exp: Math.floor(Date.now() / 1000) + 60 * 60, // Expires in 1 hour
    };

    // Return the mock JWT token
    return createMockJWT(payload);
  }
  throw new Error("Invalid username or password");
};
// HostAuthenticator HOC
const HostAuthenticator = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem(JWT_KEY);
    if (token) {
      try {
        // Optionally decode and verify token expiration
        const decodedToken: any = jwtDecode(token);
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem(JWT_KEY);
        }
      } catch (error) {
        localStorage.removeItem(JWT_KEY);
      }
    }
    setLoading(false);
  }, []);

  const handleLogin = async () => {
    try {
      const token = await mockLoginRequest(username, password);
      localStorage.setItem(JWT_KEY, token);
      setIsAuthenticated(true); // Set authenticated to true on success
    } catch (err) {
      setError("Invalid username or password");
    }
  };

  if (isAuthenticated) {
    return props.children;
  }

  if (loading) {
    return null;
  }

  return (
    <div className="flex h-screen justify-center flex-col w-full px-9">
      <div className={"bg-white p-6 px-9 py-12 rounded-xl shadow-2xl"}>
        <Logo />
        <p className={"font-extrabold text-pink-500 my-3"}>
          IFEMIDE{" "}
          <span
            className={"text-black font-extrabold underline underline-offset-2"}
          >
            HOST
          </span>
        </p>
        <FormEntry
          placeholder="Username"
          onChange={setUsername}
          title={"Username"}
          value={username}
          type={"text"}
        />
        <FormEntry
          placeholder="Password"
          onChange={setPassword}
          title={"Password"}
          value={password}
          type={"password"}
        />
        {error && <p className="text-pink-500 py-3">{error}</p>}
        <div className={"flex flex-row justify-end mt-6"}>
          <Button onClick={handleLogin} type="primary" text="Login" />
        </div>
        <p className={"my-6 text-black"}>
          Have a reservation id? Login{" "}
          <SpanLink title={"here"} href={"/guest"} target={""} />
        </p>
      </div>
    </div>
  );
};

export default HostAuthenticator;
