import React from "react";
import HostProfile from "../../components/HostProfile";
import {
  AirbnbLink,
  GoogleLocationLink,
  GuestLink,
  InstagramLink,
  TelLink,
  WhatsAppLink,
} from "../../constants/links";
import LogoRider from "../../emails/shared/LogoRider";

export const Sections = {
  DIRECTION: "directions-to-the-property",
  MODIFY_RESERVATION: "modifying-my-exiting-reservation",
  CLEANING_SERVICES: "cleaning-services-during-my-stay",
  INTERNET_LOGIN_DETAILS: "internet-login-details",
  FOOD_AND_DRINKS_PRICE_LIST: "food-drinks-price-list",
  HAVING_AN_EMERGENCY: "having-an-emergency",
  CHECK_IN_EARLIER: "checkin-in-earlier",
  CANCELLATION_POLICY: "cancellation-policy",
  GUEST_LOUNGE: "guest-lounge",
  POWER_24_7: "is-there-power-24-7",
  HOUSE_RULES: "house-rules",
};

export const SpanLink = ({
  title,
  href,
  target = "_blank",
}: {
  title: string;
  href: string;
  target?: string;
}) => (
  <>
    <span className={"underline text-pink-500"}>
      <a
        rel="noreferrer"
        target={target}
        href={href}
        className={"text-pink-500"}
      >
        <span>{title}</span>&#x2197;
      </a>
    </span>
  </>
);

export const GuestLounge = (query = "", host = "https://www.ifemide.com") => (
  <SpanLink title={"Guest Lounge"} href={host + GuestLink + query} />
);

export const GoogleLocation = ({ title = "Google" }: { title?: string }) => (
  <SpanLink title={title} href={GoogleLocationLink} />
);

export const WhatsApp = () => (
  <SpanLink title={"WhatsApp"} href={WhatsAppLink} />
);

export const Instagram = () => (
  <SpanLink title={"Instagram"} href={InstagramLink} />
);

export const Phone = () => <SpanLink title={"Phone"} href={TelLink} />;

// const BookingDotCom = () => (<SpanLink title={"Booking.com"} href={"tel:+2349067921124"} />);

const AirBnb = () => <SpanLink title={"AirBnb"} href={AirbnbLink} />;

export type FaqType = { title: string; id: string; paragraphs: any[] };

export const faqs: FaqType[] = [
  {
    id: Sections.DIRECTION,
    title: "How can I get directions to the property?",
    paragraphs: [
      <p>
        To get directions to the property, you can use any major map or
        navigation app, such as Google Maps or Apple Maps. Simply enter{" "}
        <span>
          <a
            rel="noreferrer"
            target="_blank"
            href={GoogleLocationLink}
            className={"underline text-pink-500"}
          >
            Ifemide Estates, Luxury Homes, Wellness Spa and Beauty Retreat
            &#x2197;
          </a>
        </span>
        &nbsp; into the app, and it will provide you with turn-by-turn
        directions from your current location.
      </p>,
      <p>
        We are directly at the back of the{" "}
        <SpanLink
          title={"Police Headquaters (SIB)"}
          href={"https://maps.app.goo.gl/xTL8jYJSMtbxHezLA"}
        />{" "}
        ,along the former State House of Assembly Speaker's street, about 10
        minutes drive from &nbsp;
        <SpanLink
          title={"Royal Birds, Hotel and Towers"}
          href={"https://maps.app.goo.gl/VUoHFUFUwo32kciUA"}
        />{" "}
        and 2 minutes walking distance from the{" "}
        <SpanLink
          title={"Ellanifdom hotel "}
          href={"https://maps.app.goo.gl/q1QURhocCLVAfQFd6"}
        />
      </p>,
      <p>
        I’d recommend checking traffic updates before your trip to keep things
        smooth and stress-free! If you're driving, no worries—parking is
        available right at the property.
      </p>,
      <p>
        Should in case you need personalized directions or help with navigating
        to the property, please contact me via {WhatsApp()}, I can provide you
        with specific instructions, help arrange transportation, or answer any
        questions about the best way to reach us based on your starting point.
      </p>,
    ],
  },
  {
    id: Sections.MODIFY_RESERVATION,
    title: "Can I modify my existing reservation?",
    paragraphs: [
      <p>
        Yes, you can absolutely make changes to your reservation, as long as it
        follows the booking terms. To tweak anything, just hop into our online{" "}
        {GuestLounge()} (you’ll need the booking ID from your confirmation
        email). From there, you can update things like your stay dates, room
        type, or guest count based on availability—I'll get a notification and
        need to approve it, but no worries, I'll be on it!
      </p>,

      <p>
        If you’d prefer, feel free to contact me directly by {Phone()}. I’m
        happy to help you make any adjustments to your booking! Just have your
        reservation number handy to keep things moving quickly, and let me know
        exactly what you’d like to change.
      </p>,

      <p>
        Keep in mind that changes to your reservation might affect the rates,
        and some bookings (like non-refundable ones) may have restrictions on
        what can be modified. I’d recommend checking the cancellation and
        modification policies for your booking before making any updates to
        avoid extra fees.
      </p>,

      <p>
        If you booked through a third-party platform like {AirBnb()}, you’ll
        need to make changes through their site or customer service. Their
        policies can differ from ours, so it’s a good idea to check with them
        directly for their specific rules.
      </p>,
    ],
  },
  {
    id: Sections.CHECK_IN_EARLIER,
    title: "Can I check-in earlier?",
    paragraphs: [
      <p>
        Yes, early check-in might be possible—if the stars align and the room's
        available!
      </p>,

      <p>
        If you’re hoping to settle in early, just shoot me a message on{" "}
        {WhatsApp()} ahead of time so I can check the room situation. I’ll do my
        best to shuffle things around and make it happen!
      </p>,

      "While I can’t guarantee early check-in, I’ll definitely try to work some magic for you! Just remember, we’ve got fancy homes and a limited supply, so if another guest is still enjoying their stay, we might need a bit of time to clean and make everything sparkle for you.",

      <p>
        If early check-in doesn’t work out (sometimes it just isn’t in the
        cards), we might need to tweak your check-out time a little. But don’t
        stress! Give me a {Phone()} call or message me on {Instagram()} or{" "}
        {WhatsApp()}, and I’ll fill you in on all the details—including any
        potential extra fees. We’ll figure it out together!
      </p>,
    ],
  },
  {
    id: Sections.POWER_24_7,
    title: "Is there power 24/7?",
    paragraphs: [
      "Oh, absolutely! 🌞⚡ At Ifemide, we’re on a mission to keep you comfy, well-lit, and completely plugged in—rain or shine, day or night!",
      "Yes, we’re fully aware that Nigeria’s power situation can be a bit of a “now-you-see-it, now-you-don’t” act, but don’t worry, we’ve got it covered.",
      "With high-quality backup systems, we’ve made it our job to beat the “light wahala” and keep you charged up at all times. So, whether you’re working, streaming, or just chilling, you can count on us for a 24/7 power supply. 💡",
      "High energy costs, you say? Well, yes, but we believe your comfort is priceless. So, go ahead—crank the AC (only when you are in the room, please! and that's a desperate plea, really!), charge all the gadgets, and enjoy your stay without a single worry.",
    ],
  },
  {
    id: Sections.CLEANING_SERVICES,
    title: "Can I get cleaning services during my stay?",
    paragraphs: [
      "Yes, cleaning services may be available during your stay! While housekeeping makes sure your room is all set and comfy before you arrive, let me know if you need anything extra during your stay.",

      "We generally run things Airbnb-style, meaning we ask that you take care of the place like it’s your own. But hey, I totally get if you’re busy with other things! Just give me a heads-up if that’s the case, and I’ll make arrangements. Depending on how long you’re staying and the number of guests, I might even be able to waive the cleaning fee!",

      "If you need any special cleaning, like deep cleaning or allergy-friendly services, just let me know in advance. I’m happy to customize the service to suit your needs and make sure you’re comfortable throughout your stay.",

      <p>
        Need anything extra during your stay? Whether it’s more toiletries,
        fresh towels, or extra bedding, just request them through the{" "}
        {GuestLounge()}, and I’ll get them delivered to your room right away!
      </p>,
    ],
  },
  {
    id: Sections.CANCELLATION_POLICY,
    title: "What is the cancellation policy?",
    paragraphs: [
      "The cancellation policy for your reservation depends on the specific terms you chose when booking, based on where you made the reservation. But don’t worry, we’ll sort it out together!",

      "If you booked directly with me—whether through Instagram, WhatsApp, or a quick phone call—you can cancel for free as long as it’s at least a week before your arrival. Easy peasy!",

      "If it’s less than a week, though, cancellations or changes might be a bit tricky, and there will be 50% charge on the total sum, since I would have held those dates just for you and might’ve turned down other bookings.",

      <p>
        If you booked through a third-party site like our page on {AirBnb()},
        their cancellation policies will apply, so be sure to check those. You
        might need to cancel through their platform or contact their customer
        service directly.
      </p>,

      <p>
        Got questions or need help canceling? Just drop me a message on{" "}
        {WhatsApp()} or {Instagram()}. I’m here to help and happy to clear
        things up or make any necessary arrangements.
      </p>,
    ],
  },
  {
    id: Sections.INTERNET_LOGIN_DETAILS,
    title: "How can I get the internet login details?",
    paragraphs: [
      <p>
        You can easily obtain the internet login details from the{" "}
        {GuestLounge()}. If you're unable to find the information or need
        assistance, feel free to call me so that can provide you with the Wi-Fi
        login details and ensure you're connected without any issues.
      </p>,
      <p>
        For your convenience, the Wi-Fi network is accessible throughout the
        property, including guest rooms, and common areas. If you experience any
        trouble connecting or need further help, I am always available to assist
        with troubleshooting and getting you online.
      </p>,
    ],
  },
  {
    id: Sections.FOOD_AND_DRINKS_PRICE_LIST,
    title: "Can I see the food and drinks price list?",
    paragraphs: [
      <p>
        Yes, you can check out the food and drinks price list in a few different
        ways! For your convenience, I have added it to the {GuestLounge()},
        where you can easily see all the room service options and prices for
        food and drinks.
      </p>,

      <p>
        If you’re having trouble accessing the {GuestLounge()}, no worries—just
        give me a call, and I’ll be happy to bring you a physical copy of the
        menu.
      </p>,

      <p>
        And if you have any questions or need a specific menu, don’t hesitate to
        reach out! I’m more than happy to help with any requests to make sure
        you enjoy your stay to the fullest.
      </p>,
    ],
  },
  {
    id: Sections.GUEST_LOUNGE,
    title: "How can I access the Online Guest Lounge?",
    paragraphs: [
      <p>
        The online {GuestLounge()} is available for confirmed reservations! You
        can easily access it using your reservation ID and your last name.
      </p>,

      <p>
        We're excited to let you know that the online lounge will be available
        to you starting a day before your check-in date and for two to three
        days after your check-out date. This gives you easy access to your
        invoice and a chance to share your feedback about your stay and my
        services—I would really appreciate it!.
      </p>,

      <p>
        In the online lounge, you can explore our current offerings, check your
        invoice, retrieve your internet login details, make special requests, or
        share your feedback with us directly. We’d love to hear from you!
      </p>,

      <p>
        And if you are having difficulties in accessing the online lounge, don’t
        hesitate to reach out to me! I’m more than happy to help you get that
        sorted out.
      </p>,
    ],
  },
  {
    title: "House Rules",
    id: Sections.HOUSE_RULES,
    paragraphs: [
      "We want you to have a comfortable stay, but we do have a few rules to ensure that the space is respected and enjoyed by everyone.",

      "Please be mindful of noise after 10:00 PM. We kindly ask that you keep the noise level low during nighttime hours, especially in shared spaces. Quiet hours are from 10:00 PM to 8:00 AM.",

      "Please adhere to the maximum occupancy of the property according to the booking details. The maximum number of guests allowed is clearly stated in the listing, and additional guests are not permitted without prior approval.",

      "We are committed to providing a peaceful and safe environment for all guests. Parties, events, or large gatherings are not allowed without prior consent from us. Please let us know if you're planning something special, and we'll work with you to make it happen in a way that aligns with our policies.",

      "Please be mindful of energy usage during your stay. Turn off lights, air conditioning, and heaters when not in use. If you are leaving the house for an extended period, ensure all electronics are switched off. This helps us maintain a sustainable environment for everyone.",

      "Our neighbors are an important part of our community. Please be considerate by keeping noise levels down, especially when entering and exiting the property. We ask that you do not disturb others by playing loud music or making excessive noise at any time.",

      "This property is non-smoking. Smoking inside the house or in any enclosed areas is strictly prohibited. If you wish to smoke, please do so in designated outdoor areas and dispose of cigarettes responsibly.",

      "Please dispose of trash and recyclables according to local guidelines. There are clearly labeled bins for trash and recycling, so please take a moment to separate your waste appropriately to help keep the space clean and sustainable.",

      "Please take care of the property and its contents. If any items are damaged or if something isn't working properly, please let us know immediately. We will do our best to resolve the issue, but we appreciate your honesty in case of any accidents.",

      "Check-in is after 3:00 PM, and check-out is by 12:00 PM. We ask that you respect these times to allow us to prepare the space for the next guest. If you need to arrange an early check-in or late check-out, please ask us in advance, and we will do our best to accommodate you.",

      "We encourage you to embrace the local culture and community during your stay. Whether it’s trying local food, learning about the history, or respecting cultural norms, we want your experience to be as enriching as possible.",

      "We welcome pets, but please confirm with us in advance before bringing your pet. There may be an additional cleaning fee for pets, and we ask that pets are not left unattended in the house. Please clean up after your pet and respect the space.",

      "In case of any emergencies, please do not hesitate to contact us immediately. We are available 24/7 for urgent matters or to assist with anything you might need during your stay.",

      "We hope you enjoy your stay! Feel free to reach out if you have any questions or concerns. We are happy to help make your visit as pleasant as possible!",
    ],
  },
];

const FaqHome = () => {
  return (
    <div className={"bg-white overflow-hidden w-full h-screen relative"}>
      <div className={"h-full p-12 overflow-y-scroll pb-40"}>
        <section id={"topics"}>
          <div className={"my-6"}>
            <LogoRider />
          </div>
          <HostProfile />
          <p className={"text-5xl font-thin my-9"}>
            Frequently Asked Questions
          </p>
        </section>
        <section id={"list"} className={"space-y-3 flex flex-col"}>
          <p className={"font-bold text-pink-500"}>Topics</p>
          {faqs.map((faq) => (
            <a href={`#${faq.id}`} className={"underline underline-offset-4"}>
              {faq.title}
              <span className={"ml-2 text-pink-500"}>&#x2197;</span>
            </a>
          ))}
        </section>
        {faqs.map((faq) => (
          <section id={faq.id}>
            <p className={"text-4xl font-thin my-6"} key={faq.id}>
              {faq.title}
            </p>
            {faq.paragraphs.map((paragraph) => (
              <p className={"my-3 text-md"}>{paragraph}</p>
            ))}
          </section>
        ))}
      </div>
      <div
        className={
          "flex flex-row items-center justify-center text-5xl absolute bottom-0 right-0 bg-pink-500 text-white shadow-2xl h-16 w-16 rounded-full m-12 mb-28"
        }
      >
        <a href={`#topics`}>
          <i className="bx bxs-up-arrow-alt"></i>
        </a>
      </div>
    </div>
  );
};

export default FaqHome;
